import { Component, OnInit, Injectable } from '@angular/core';
import { AuthService } from "../auth/auth.service";
import { Router, CanActivate } from '@angular/router';
import { UserService, User } from 'app/services/user.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './welcome.component.html'
})
export class WelcomeComponent implements OnInit {
  constructor(
    public auth: AuthService,
    public user: UserService
  ) { }

  ngOnInit() {
  }
}


@Injectable()
export class WelcomeGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private user: UserService, 
    private router: Router
  ) { }

  canActivate() {
    return this.user.haveUser.pipe(map(haveUser => {
      if ((!haveUser) || (!this.auth.authenticated)) {
        return true;
      } else {
        this.router.navigate(['/']);
        return false;
      }
    }));
  }
}
