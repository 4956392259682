import { Component, OnInit, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router, CanActivate } from '@angular/router';
import { Defaults } from 'app/util/defaults';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'app/auth/auth.service';
import { CompareService } from "../services/compare-service";
import { LocationService, BlikLocation, BroLocation } from "../services/location.service";
import * as moment from 'moment';
import * as introJs from 'intro.js/intro.js';
import { NotifierService } from 'angular-notifier';
import * as FileSaver from 'file-saver';
import { isKNMIPlaceholder, KNMIService, KNMIStationDataEntry, KNMIStationDropdownItem, KNMIStationDropdownPlaceholder } from 'app/services/knmi-service';
import { UserService } from 'app/services/user.service';
import { Observable, combineLatest, of, Subject } from 'rxjs';
import { KNMI_DATA, EXPORT_CSV_XLSX, DASHBOARD_LOCATIONS_COMPARISON, REFERENCE_LEVELS, MEASUREMENT_DETAILS, VIEW_PUBLIC_BRO_DATA } from 'app/services/permission-definitions';
import { LocalStorageService } from 'app/services/localstorage.service';
import { first, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { Position } from 'app/shared/position';
import { LocationID } from "app/services/location.service";
import { isNumber } from 'util';
import { MeasurementExportService } from 'app/components/measurement-export/measurement-export.service';
import { BlikLocationGroup, LocationGroupService } from 'app/services/location-group.service';

@Component({
  selector: 'app-location-compare',
  templateUrl: './location-compare.component.html',
  styleUrls: ['./location-compare.component.css']
})
export class LocationCompareComponent implements OnInit {
  public knmiPermission: Observable<boolean>;
  private viewPublicBroDataPermission: Observable<boolean>;
  public selectedKNMIStationIndex = 0;
  public knmiStations: (KNMIStationDropdownItem | KNMIStationDropdownPlaceholder)[] = [{id: 0, _desc: "Laden..."}];
  public loadingKNMIData = false;
  public noKNMIData = false;

  public knmiData: KNMIStationDataEntry[] = [];

  // Start and end date
  selectionDate = null;
  dateRange = {
    start: moment().subtract(365, 'day'),
    end: moment(),
  };

  public highlighted: LocationID | null = null;
  locations: LocationID[] = [];

  showLocationGroupSelect: boolean;
  locationGroups: BlikLocationGroup[];
  locationGroups$: Observable<BlikLocationGroup[]>;

  locationData: BlikLocation[] = [];
  broLocationData: BroLocation[] = [];
  loadingLocations = false;
  selectedLocationGroup: number | null = null;
  selectedLocation: BlikLocation | null = null;
  availableLocations: BlikLocation[] = [];

  locationsCache$: Observable<BlikLocation[]> | null = null;
  broLocationsCache$: Observable<BroLocation[]> | null = null;

  csvPermission: Observable<boolean>;

  public time_window_days: number = Defaults.TIME_WINDOW_DAYS;

  private introJS = new introJs();

  private ngUnsubscribe = new Subject();

  constructor(
    private http: HttpClient,
    public authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private compareService: CompareService,
    private locationService: LocationService,
    private locationGroupService: LocationGroupService,
    private location: Location,
    private localStorageService: LocalStorageService,
    private exportService: MeasurementExportService,
    private notifier: NotifierService,
    private knmiService: KNMIService,
    private userService: UserService
    )
  {
    // When the 'locations' route parameter changes, get all the ids from it
    this.route.params.subscribe(params => {
      // If locations are set on params, then use those...
      if (params['locations']) {
        const locationIds: LocationID[] = params['locations'].split(',').map(id => {
          const parsed = Number.parseInt(id, 10);
          return (parsed.toString() === id) ? parsed : id;
        });

        this.compareService.set(locationIds);
      }
      // ... otherwise check the compareService and use those values
      else {
        let ids = this.compareService.getComparingLocations().join(',');
        let url = this.router.createUrlTree(['vergelijk', ids], {}).toString();
        // Adjust the url to the values from storage
        this.location.replaceState(url);
      }
    });
    // When the compare service has a new value, use that value as the new locations
    this.compareService.watchStorage().subscribe(locations => {
      this.locations = locations;
      this.updateURL();
    });
    this.csvPermission = userService.userHasPermission(EXPORT_CSV_XLSX);
    this.knmiPermission = userService.userHasPermission(KNMI_DATA);
    this.viewPublicBroDataPermission = userService.userHasPermission(VIEW_PUBLIC_BRO_DATA);
  }

  ngOnInit() {
    this.fetchLocationGroups();
    // setTimeout() is a workaround to defer until after all elements needed for the tour have been rendered.
    // Otherwise, the elements that the tutorial wants to point at cannot be found
    setTimeout(() => this.initialStartTour());
  }

  onDateRangeChanged(event) {
    this.dateRange = event;
    this.getKNMIData();
  }

  onChangeKNMIStation(event) {
    this.getKNMIData();
  }

  initialStartTour() {
    this.localStorageService.observeUserValue("locationCompareTutorialCompleted").subscribe(completed => {
      if (!completed) {
        this.startTour();
      }
    });
  }

  startTour() {
    combineLatest([this.locationService.getLocations(), this.userService.permissions]).subscribe(([locations, permissions]) => {
      this.createIntroSteps(permissions);
      this.introJS.start();
      this.localStorageService.saveUserValue("locationCompareTutorialCompleted", "true");
    });
  }

  createIntroSteps(permissions: String[]) {
    let introSteps = [
      {
        intro: "Op deze pagina kunnen meerdere meetlocaties met elkaar worden vergeleken."
      },
      {
        element: '#location-add-controls',
        intro: "De locaties die al geselecteerd waren om te vergelijken in het overzicht worden direct getoond. Andere meetlocaties kunnen hier toegevoegd worden aan de vergelijking."
      }
    ];

    if(permissions.indexOf(EXPORT_CSV_XLSX) > -1) {
      introSteps.push(
        {
          element: '#export-csv-button',
          intro: "Met de 'Export CSVs'-knop worden alle metingen van de geselecteerde locaties over de geselecteerde tijdspanne als CSV geëxporteerd en samengevoegd in een zip-bestand gedownload."
        },
        {
          element: '#export-xlsx-button',
          intro: "Hetzelfde kan ook in Excel-formaat."
        }
      );
    }
    this.introJS.setOptions({
      nextLabel: "Volgende",
      prevLabel: "Vorige",
      skipLabel: "Afsluiten",
      doneLabel: "Klaar",
      showStepNumbers: false,
      overlayOpacity: 0.5,
      steps: introSteps
    });
  }

  updateURL() {
    let ids = this.compareService.getComparingLocations().join(',');
    let url = this.router.createUrlTree(['vergelijk', ids], {}).toString();
    this.location.replaceState(url);
  }

  /**
   * Event handler for adding a location ID to the array of location IDs and resetting the selector.
   */
  addLocation() {
    if (this.selectedLocation && this.locations.indexOf(this.selectedLocation.id) === -1) {
      this.compareService.add(this.selectedLocation.id);
    }

    this.selectedLocation = null;
  }

  /**
   * Removes the given location ID from the array of locations.
   * @param locationId
   */
  removeLocation(locationId: LocationID) {
    this.compareService.remove(locationId);
  }

  fetchLocationGroups() {
    this.locationGroups$ = this.locationGroupService.getAll()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        switchMap(d => of(this.sortByNameAscending(d.filter(group => group.locationIds.length))))
      );
    this.locationGroups$.subscribe((v) => {
      this.locationGroups = v;
      this.showLocationGroupSelect = v.length > 1;
      this.fetchLocations();
    });
  }

  /**
   * Fetches all available locations.
   */
  fetchLocations() {
    this.loadingLocations = true;

    // Cache the list of locations
    if (!this.locationsCache$) {
      this.locationsCache$ = this.locationService.getLocations();
    }

    this.locationsCache$.subscribe(
      data => {
        this.locationData = data;
        this.fetchBroLocations();

        if (!this.showLocationGroupSelect) {
          this.selectedLocationGroup = this.locationGroups[0].id;
          this.selectedLocationGroupChange();
        }
      },
      error => {
        this.notifier.notify('error', 'Ophalen van beschikbare meetlocaties mislukt.');
        console.log(error);
      }
    );
  }

  fetchBroLocations() {
    this.viewPublicBroDataPermission.pipe(take(1)).subscribe(canViewPublicBroData => {
      if (!this.broLocationsCache$) {
        if (canViewPublicBroData) {
          this.broLocationsCache$ = this.locationService.getBroLocations();
        }
        else {
          this.broLocationsCache$ = of([]);
        }
      }

      this.broLocationsCache$.subscribe(
        data => {
          this.broLocationData = data;
          this.loadingLocations = false;
          this.fetchKNMIStations();
         },
        error => {
          this.notifier.notify('error', 'Ophalen van beschikbare BRO-meetlocaties mislukt.');
          console.log(error);
        }
      );
    });
  }

  fetchKNMIStations() {
    this.knmiPermission.subscribe(havePermission => {
      if (havePermission) {
        const numericIds = this.locations.filter(l => typeof l === 'number');
        const broIds = this.locations.filter(l => typeof l === 'string');

        const locations = [
          ...this.locationData.filter(l => numericIds.includes(l.id)),
          ...this.broLocationData.filter(l => broIds.includes(l.id))
        ];
        const positions = locations.map(l => new Position(l.latitude, l.longitude));

        this.knmiService.getKNMIStationsSortedByDistanceTo(positions).pipe(take(1)).subscribe(
          (stations) => {
            const entries = stations.map(s => Object.assign({}, s, {
              _desc: s.name,
            }));

            this.knmiStations = [{ id: 0 as 0, _desc: "(geen)" }, ...entries];
          },
          error => {
            this.knmiStations = [];
            this.notifier.notify('error', "Kon KNMI-stations niet laden");
            console.error('Error retrieving KNMI Data: ', error);
          }
        );
      }
    });
  }

  selectedLocationGroupChange() {
    const locationGroup = this.locationGroups.find(lg => lg.id === this.selectedLocationGroup);

    if (locationGroup) {
      this.availableLocations = locationGroup.locationIds.map(id => this.locationData.find(ld => ld.id === id));
    }
    else {
      this.availableLocations = [];
    }

    this.selectedLocation = null;
  }

  isSelected(id) {
    return this.locations.includes(id);
  }

  export(format: string) {
    let ids = this.locations;

    let start = this.selectionDate ? moment(this.selectionDate.start) : null;
    let end = this.selectionDate ? moment(this.selectionDate.end) : null;

    this.userService.permissions.pipe(first()).subscribe({
      next: (permissions) => {
        let includeReferences = permissions.indexOf(REFERENCE_LEVELS) > -1;
        let includeAirWater = permissions.indexOf(MEASUREMENT_DETAILS) > -1;

        this.exportService.getZipExport(format, this.getBlikLocationIDs(), start, end, true, includeReferences, includeAirWater)
          .subscribe(
            data => {
              let startString = start.format('YYYY-MM-DD_HHmmss');
              let endString = end.format('YYYY-MM-DD_HHmmss');

              let idString = ids.join("_");
              const fileName = `blik_export_${startString}_tot_${endString}_${idString}_${format}.zip`;
              FileSaver.saveAs(new Blob([data], {type: 'application/zip'}), fileName);
            },
            error => {
              this.notifier.notify('error', 'Fout bij downloaden van export.');
              console.error('Error retrieving ZIP: ', error);
            }
          );
      }
    });
  }

  private getBlikLocationIDs(): number[] {
    return this.locations.filter((id): id is number => isNumber(id)) as number[];
  }

  exportCsv() {
    this.export('csv');
  }

  exportXlsx() {
    this.export('xlsx');
  }

  dateSelect(e: Highcharts.AxisSetExtremesEventObject) {
    if('min' in e && 'max' in e) {
      this.selectionDate = {
        start: moment.unix(e.min / 1000),
        end: moment.unix(e.max / 1000),
      };
    }
  }

  getKNMIData() {
    const station = this.knmiStations[this.selectedKNMIStationIndex];

    if (isKNMIPlaceholder(station)) {
      this.knmiData = [];
      this.loadingKNMIData = false;
      return;
    }

    this.loadingKNMIData = true;
    this.noKNMIData = false;
    this.knmiData = [];

    this.knmiService.getKNMIDataAtStation(station.id, this.dateRange.start, this.dateRange.end)
      .subscribe(
        data => {
          if (!data.length) {
            this.noKNMIData = true;
            this.notifier.notify('warning', "KNMI-station " + station.name + " heeft geen regendata.");
          }

          this.loadingKNMIData = false;
          this.knmiData = data;
        },
        error => {
          this.loadingKNMIData = false;
          this.notifier.notify('error', "Kon KNMI-data niet laden");
          console.error('Error retrieving KNMI Data: ', error);
        }
      );
  }

  /**
   * Highlights a series in the chart with the given location ID.
   */
  highlight(id: LocationID | null) {
    this.highlighted = id;
  }

  private sortByNameAscending<T extends { name?: string }>(items: T[]): T[] {
    return [...items].sort((a, b) => a.name.localeCompare(b.name, 'nl', { sensitivity: 'base', numeric: true, usage: 'sort' }));
  }
}

@Injectable()
export class LocationCompareGuard implements CanActivate {

  constructor(
    private user: UserService,
    private router: Router
  ) { }

  canActivate() {
    return this.user.userHasPermission(DASHBOARD_LOCATIONS_COMPARISON).pipe(map(p => {
      if (!p) {
        this.router.navigate(['/']);
      }
      return p;
    }));
  }
}
