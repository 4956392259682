<ng-template #updatebutton>
<button class="updatebutton" (click)="analyze()"></button>
</ng-template>

<ng-container *ngIf="statusItems.length > 0">
  <div *ngFor="let d of statusItems; let i = index" class="item {{d.class}}" title={{d.text}}><span></span></div>
</ng-container>
<ng-container *ngIf="progress$ | async; let progress; else updatebutton">
  <ng-container *ngIf="progress.progress < 1 || progress.queuePosition >= 0; else updatebutton">
    <div class="item analysisprogress" [ngStyle]="{'background': 'linear-gradient(to right, #3C78BD ' + progress.progress * 100 + '%, transparent 0)'}" (click)="analyze()">
      <div class="progresscounter" *ngIf="!progress.queuePosition">
        {{progress.progress * 100 | floor}}%
      </div>
      <div class="progresscounter" *ngIf="progress.queuePosition">
        Q{{progress.queuePosition}}
      </div>
    </div>
  </ng-container>
</ng-container>
