import {Directive, ElementRef, EventEmitter, Input, OnChanges, Output} from "@angular/core";

const KEYCODE_ENTER = 13;
const KEYCODE_ESC = 27;

@Directive({
  selector: '[appContenteditable]',
  host: {
    '(blur)': 'onBlur($event)',
    '(keydown)': 'onKeyDown($event)',
    '(click)': 'onClick($event)'
  }
})
export class ContenteditableDirective implements OnChanges {
  @Input('appContenteditable') model: any;
  @Output('onChange') update = new EventEmitter();

  editing = false;
  canceledEditing: boolean = false;

  constructor(private elRef: ElementRef) {
  }

  ngOnChanges(changes) {
    let model = changes.model;
    if (model && model.currentValue !== model.previousValue) {
      this.model = model.currentValue;
      this.elRef.nativeElement.innerText = this.escapeHtml(this.model);
    }
  }

  onKeyDown(e) {
    // Press enter to save, escape to cancel
    if (e.keyCode === KEYCODE_ENTER || e.keyCode === KEYCODE_ESC) {
      e.preventDefault();
      this.canceledEditing = e.keyCode === KEYCODE_ESC;
      this.elRef.nativeElement.blur();
    }
  }

  onBlur() {
    let newName = this.elRef.nativeElement.innerText;
    if (this.canceledEditing || newName === '') {
      // Revert to the previous value
      this.elRef.nativeElement.innerText = this.escapeHtml(this.model);
      this.canceledEditing = false;
    } else {
      this.model = newName;
      this.update.emit(newName);
    }
    this.toggleEditing();
  }

  onClick() {
    if (!this.editing) {
      this.toggleEditing();
    }
  }

  toggleEditing() {
    this.editing = !this.editing;
    this.elRef.nativeElement.classList.toggle('editing', this.editing);
  }

  // @see https://angular.io/guide/security
  // @see https://stackoverflow.com/a/38578854
  // @todo see if we can bind to [innerHtml] instead, which is automatically sanitized by Angular?
  escapeHtml(unsafe) {
    return unsafe.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;").replace(/'/g, "&#039;");
  }
}
