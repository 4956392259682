import {NgModule} from "@angular/core";

import { FlowmeterDisplayComponent } from './flowmeter-display.component';

@NgModule({
  declarations: [FlowmeterDisplayComponent],
  exports     : [FlowmeterDisplayComponent],
})

export class FlowmeterDisplayModule {}
