<div id="user-management">
  <div class="row">
    <div class="col-xs-6 text-right">
      <button class="btn btn-default m-sm m-l-none " (click)="startTour()" id="user-management-tour-start">?</button>
    </div>
  </div>
  <table class="table" id="userTable">
      <tr>
        <th>&nbsp;</th>
        <th>Naam</th>
        <th>E-mailadres</th>
        <th>Rollen</th>
        <th></th>
      </tr>
      <tr *ngFor="let user of users">
          <td><input id="checkUser{{user.id}}" type="checkbox" [(ngModel)]="checked[user.id]" [disabled]="user.id == me.id"/></td>
          <td><label for="checkUser{{user.id}}">{{user.name}}</label></td>
          <td>{{user.emailAddress}}</td>
          <td>
            <ul>
              <li *ngFor="let role of (rolesForUserId[user.id] | async)">{{role}}</li>
            </ul>
          </td>
          <td>
            <button *ngIf="impersonateUserPermission | async" class="btn btn-default m-sm m-l-none text-left" (click)="impersonate(user.id)">Impersoneer</button>
          </td>
      </tr>
  </table>
  <div class="row">
    <div class="col-lg-12">
      <button class="btn btn-default" id="btn-delete-user" (click)="removeUsers()">Verwijder geselecteerde gebruikers</button>
    </div>
  </div>
  
  <div class="row">
    <div class="col-lg-12" id="userInvites">
      <h1>Gebruiker uitnodigen</h1>
    </div>
    <div class="form-group col-md-4"><label for="newUserName">Naam</label><input class="form-control ng-valid" id="newUserName" type="text" [(ngModel)]="newUser.name"></div>
    <div class="form-group col-md-4"><label for="newUserEmail">E-mailadres</label><input class="form-control ng-valid" id="newUserEmail" type="text" [(ngModel)]="newUser.emailAddress"></div>
    <div class="form-group col-lg-12">
      <h2>Rollen</h2>
      <ul class="list-group">
        <li class="list-group-item checkbox" *ngFor="let role of roles">
          <label>
            <input type="checkbox" [(ngModel)]="checkedRoles[role]" />
            <strong>{{role}}</strong>: {{roleDescriptions[role]}}
          </label>
        </li>
        <li class="list-group-item checkbox">
          <label>
            <input type="checkbox" checked disabled />
            <strong>Grondwaterstand-monitor</strong>: inzien van actuele en historische grondwaterstanden
          </label>
        </li>
      </ul>
    <div class="col-lg-12">
      <button class="btn btn-default" id="btn-add-user" (click)="addUser()">Nodig gebruiker uit</button>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12" style="height: 20px;"></div>
  </div>
</div>
