import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import {map, repeatWhen, share, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable, ReplaySubject, Subject, timer, of} from "rxjs";
import { CUSTOMER_GROUP_USER_ADMIN, GLOBAL_USER_ADMIN, PROJECT_BLIK20KRIMP001, PROJECT_BLIK21BERGE001 } from './permission-definitions';
import {Cacheable} from "../shared/cacheable";

export class User {
  id: number;
  name: string;
  emailAddress: string;
  publicAccessDomain: string;
  primaryCustomerGroupId: number;
}

export enum Reference {
  NAP = "NAP",
  GROUND = "GROUND",
}

export class UserSettings {
  enableEmailWaterLevelNotifications?: boolean = true;
}

export class LocationUserSettings {
  lowNotificationThresholdMeters?: number = null;
  highNotificationThresholdMeters?: number = null;
  lowNotificationThresholdMinutes?: number = null
  highNotificationThresholdMinutes?: number = null
  notificationThresholdReference?: Reference = null;
  enableEmailWaterLevelNotifications?: boolean = false;
  includeInvalidatedMeasurements?: boolean = false;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _me: Observable<User>;
  private _permissions: Observable<string[]>;
  private _users: Observable<{[id: number]: User}>;
  private _myRoles: Observable<string[]>;
  private _allRoleDescriptions: Observable<{string: string}>;
  private _mySettings: Cacheable<UserSettings>;
  private _myLocationSettings: {[id: number]: Cacheable<LocationUserSettings>};

  constructor(private http: HttpClient) {
    this._me = this.http.get(environment.api_base_url + '/users/me').pipe(map(v => v as User), shareReplay(1));
    this._permissions = this.http.get(environment.api_base_url + '/users/permissions').pipe(map(v => v as string[]), shareReplay(1));
    this._users = this.http.get(environment.api_base_url + '/users').pipe(map((users: User[]) => {
      var userDict = {} as {[id: number]: User};
      for(let u of users) {
        userDict[u.id] = u;
      }
      return userDict;
    }), shareReplay(1));
    this._myRoles = this.http.get(environment.api_base_url + '/users/roles/me').pipe(map(v => v as string[]), shareReplay(1));
    this._allRoleDescriptions = this.http.get(environment.api_base_url + '/users/roles').pipe(map(v => v as {string: string}), shareReplay(1));
    this._myLocationSettings = {};
  }

  get me(): Observable<User> {
    return this._me;
  }

  getMySettings(): Observable<UserSettings> {
    if(this._mySettings) {
      this._mySettings.refresh()
    } else {
      this._mySettings = new Cacheable<UserSettings>(this.http.get(environment.api_base_url + '/users/settings') as Observable<UserSettings>)
    }
    return this._mySettings.get();
  }

  changeMySettings(settings: UserSettings) {
    let sub = this.http.patch(environment.api_base_url + '/users/settings', settings);
    sub.subscribe(() => this.getMySettings());
    return sub;
  }

  setMySettings(settings: UserSettings) {
    let sub = this.http.put(environment.api_base_url + '/users/settings', settings);
    sub.subscribe(() => this.getMySettings());
    return sub;
  }

  getMyLocationSettings(locationID: number): Observable<LocationUserSettings> {
    if(this._myLocationSettings[locationID]) {
      this._myLocationSettings[locationID].refresh()
    } else {
      this._myLocationSettings[locationID] = new Cacheable<LocationUserSettings>(this.http.get(environment.api_base_url + '/users/settings/locations/' + locationID) as Observable<LocationUserSettings>)
    }
    return this._myLocationSettings[locationID].get();
  }

  changeMyLocationSettings(locationID: number, settings: LocationUserSettings) {
    return this.http.patch(environment.api_base_url + '/users/settings/locations/' + locationID, settings).pipe(
      tap(() => this.getMyLocationSettings(locationID)),
    );
  }

  setMyLocationSettings(locationID: number, settings: LocationUserSettings) {
    return this.http.put(environment.api_base_url + '/users/settings/locations/' + locationID, settings).pipe(
      tap(() => this.getMyLocationSettings(locationID)),
    );
  }

  get permissions(): Observable<string[]> {
    return this._permissions;
  }

  get users(): Observable<{[id: number]: User}> {
    return this._users;
  }

  get myRoles(): Observable<string[]> {
    return this._myRoles;
  }

  get allRoleDescriptions(): Observable<{string: string}> {
    return this._allRoleDescriptions;
  }

  get haveUser(): Observable<boolean> {
    return this._me.pipe(map(me => 'id' in me));
  }

  get canManageUsers(): Observable<boolean> {
    return this.userHasAnyPermission(CUSTOMER_GROUP_USER_ADMIN, GLOBAL_USER_ADMIN);
  }

  deleteUser(id: number) {
    return this.http.delete(environment.api_base_url + '/users/' + id);
  }

  createUser(user: User) {
    return this.http.post(environment.api_base_url + '/users', user);
  }

  setUserRoles(userId: number, roles: string[]) {
    return this.http.put(environment.api_base_url + '/users/roles/' + userId, roles);
  }

  getUser(id): Observable<User> {
    return this._users.pipe(
      map(v => v[id])
    )
  }

  getUserRoles(id): Observable<string[]> {
    return this.http.get(environment.api_base_url + '/users/roles/' + id).pipe(map(v => v as string[]), shareReplay(1));
  }

  public userHasPermission(permission: string): Observable<boolean> {
    return this._permissions.pipe(map(p => p.indexOf(permission) > -1));
  }

  public getCompanyLogo(): Observable<string> {
    return this._permissions.pipe(map(userPermissions => {
      if (userPermissions.indexOf(PROJECT_BLIK20KRIMP001) > -1) return "logo_blik.20.krimp.001.png";
      if (userPermissions.indexOf(PROJECT_BLIK21BERGE001) > -1) return "logo_blik.21.berge.001.png";
      return null;
    }));
  }

  public userHasAnyPermission(...permissions: string[]): Observable<boolean> {
    return this._permissions.pipe(map(userPermissions => {
      for (let p of permissions) {
        if (userPermissions.indexOf(p) > -1) return true;
      }
      return false;
    }));
}
}
