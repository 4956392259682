import {NgModule} from "@angular/core";
import { WaterlevelDisplayComponent } from './waterlevel-display.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [WaterlevelDisplayComponent],
  exports     : [WaterlevelDisplayComponent],
  imports     : [CommonModule, FormsModule, ReactiveFormsModule],
})

export class WaterlevelDisplayModule {}
