import { Component, OnInit, OnDestroy, Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Observable, Subscription, combineLatest, Subject, BehaviorSubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import * as introJs from 'intro.js';
import { Step } from 'intro.js';
import * as moment from 'moment';

import { AuthService } from 'app/auth/auth.service';
import { MeasurementExportDialog } from 'app/components/measurement-export/measurement-export.component';
import { LocalStorageService } from 'app/services/localstorage.service';
import { LocationService, BlikLocation, LocationWithStatistics } from 'app/services/location.service';
import { BlikLocationGroup, LocationGroupService } from 'app/services/location-group.service';
import { EXPORT_CSV_XLSX, DASHBOARD_LOCATIONS_OVERVIEW, DASHBOARD_LOCATIONS_COMPARISON, DASHBOARD_LOCATION_GROUP_DETAILS } from 'app/services/permission-definitions';
import { UserService } from 'app/services/user.service';
import { Defaults } from 'app/util/defaults';

interface OverviewLocationGroup {
  id: number;
  name: string;
  description: string;
  locations: LocationWithStatistics[];
}

@Component({
  selector: 'app-location-overview',
  templateUrl: './location-overview.component.html',
  styleUrls: ['./location-overview.component.css'],
})
export class LocationOverviewComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();

  locationGroups$: Observable<OverviewLocationGroup[]>;
  locationMap: Map<number, LocationWithStatistics> = new Map<number, LocationWithStatistics>();

  introJS = introJs();

  public time_window_days: number = Defaults.TIME_WINDOW_DAYS;

  checked: Map<number, boolean> = new Map<number, boolean>();
  groupsExpanded: Map<number, boolean> = new Map<number, boolean>();
  pendingRequests: Subscription[] = [];

  public exportPermission: Observable<boolean>;
  public locationComparePermission: Observable<boolean>;
  public locationGroupDetailsPermission$: Observable<boolean>;

  public selectedCount: number = 0;

  public filterText$: BehaviorSubject<string>;
  public filterTerms$: Observable<string[]>;
  public filterClearable$: Observable<boolean>;
  public visibleLocationGroups$: Observable<OverviewLocationGroup[]>;

  constructor(
    public auth: AuthService,
    private router: Router,
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private locationService: LocationService,
    private locationGroupService: LocationGroupService,
    private exportDialog: MeasurementExportDialog,
  ) {
    this.exportPermission = userService.userHasPermission(EXPORT_CSV_XLSX);
    this.locationComparePermission = userService.userHasPermission(DASHBOARD_LOCATIONS_COMPARISON);
    this.locationGroupDetailsPermission$ = userService.userHasPermission(DASHBOARD_LOCATION_GROUP_DETAILS);

    this.locationGroups$ = combineLatest([this.locationGroupService.getAll(), this.locationService.getLocations()])
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(map(([groups, locations]) => this.processLocationList(groups, locations)));

    this.filterText$ = new BehaviorSubject('');

    this.filterTerms$ = this.filterText$
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(map(t => parseFilter(t)));

    this.filterClearable$ = this.filterText$
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(map(t => !t));

    this.visibleLocationGroups$ = combineLatest([this.locationGroups$, this.filterTerms$])
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(map(([groups, terms]) => this.filterLocationGroups(groups, terms)));
  }

  ngOnInit() {
    // setTimeout() is a workaround to defer until after all elements needed for the tour have been rendered.
    // Otherwise, the elements that the tutorial wants to point at cannot be found
    setTimeout(() => this.initialStartTour());
  }

  ngOnDestroy() {
    // Cancel all pending requests for statistics
    this.pendingRequests.forEach(r => {
      r.unsubscribe()
    });
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  initialStartTour() {
    this.localStorageService.observeUserValue("locationOverviewTutorialCompleted").subscribe(completed => {
      if (!completed) {
        this.startTour();
      }
    });
  }

  startTour() {
    combineLatest([this.locationGroups$, this.userService.permissions]).subscribe(([groups, permissions]) => {
      const firstNonEmpty = groups.find(g => g.locations.length);
      this.groupsExpanded.set(firstNonEmpty.id, true);

      // needed to ensure the .location-link is in the page.
      window.setTimeout(() => {
        this.createIntroSteps(permissions);
        this.introJS.start();
        this.localStorageService.saveUserValue("locationOverviewTutorialCompleted", "true");
      }, 0);
    });
  }

  createIntroSteps(permissions: String[]) {
    let introSteps: (Step & { permissions?: string[] })[] = [
      {
        intro: "Op deze pagina wordt een lijst getoond met alle beschikbare meetpunten en hun meest recente relevante informatie.",
      },
      {
        intro: "In deze tabel staan alle meetpunten.",
      },
      {
        element: "[data-nonempty-group-heading=true]",
        intro: "De meetpunten zijn gegroepeerd, klap een groep open om de meetpunten te zien.",
      },
      {
        element: ".location-link",
        intro: "Door op de naam van de locatie te klikken wordt het detailoverzicht geopend.",
      },
      {
        element: "[data-nonempty-group-details=true]",
        intro: "Hier zijn meer details te zien van een groep.",
        permissions: [DASHBOARD_LOCATION_GROUP_DETAILS],
      },
      {
        element: "[data-nonempty-group-checkbox=true]",
        intro: "Gebruik de selectievakjes voor een groep om alle locaties in de groep tegelijk te selecteren of deselecteren.",
      },
      {
        element: '#compare-button',
        intro: "Met de vergelijken-knop wordt de vergelijking van geselecteerde meetpunten gestart.",
      },
      {
        element: '#export-button',
        intro: "Met de 'Metingen exporteren…'-knop kunnen metingen van de geselecteerde locaties worden geëxporteerd.",
        permissions: [EXPORT_CSV_XLSX],
      },
      {
        element: '#filter-group',
        intro: 'Gebruik het filter om snel een locatie of groep te vinden.',
      },
      {
        element: '#location-overview-tour-start',
        intro: "De rondleiding kan herstart worden door op het vraagteken te klikken.",
      },
    ];

    this.introJS.setOptions({
      nextLabel: "Volgende",
      prevLabel: "Vorige",
      skipLabel: "Afsluiten",
      doneLabel: "Klaar",
      showStepNumbers: false,
      overlayOpacity: 0.5,
      steps: introSteps.filter(step => {
        if ('permissions' in step) {
          return step.permissions.every(p => permissions.includes(p));
        }

        return true;
      })
    });
  }

  processLocationList(locationGroupData: BlikLocationGroup[], locationData: LocationWithStatistics[]): OverviewLocationGroup[] {
    for (let loc of locationData) {
      this.locationMap.set(loc.id, loc);
    }

    const result = this.sortByNameAscending(locationGroupData).map(lg => {
      const locations = lg.locationIds.map(lid => this.locationMap.get(lid)).filter(l => l);

      return {
        id: lg.id,
        name: lg.name,
        description: lg.description,
        locations: this.sortByNameAscending(locations),
      };
    });

    if (result.length === 1) {
      this.groupsExpanded.set(result[0].id, true);
    }

    return result;
  }

  clearFilter() {
    this.filterText$.next('');
  }

  filterLocationGroups(groups: OverviewLocationGroup[], terms: string[]) {
    const filtered: OverviewLocationGroup[] = groups.map(group => this.filterLocations(group, terms));

    let result = filtered.filter(g => g.locations.length > 0);

    if (result.length === 1) {
      this.groupsExpanded.set(result[0].id, true);
    }

    return result;
  }

  filterLocations(group: OverviewLocationGroup, terms: string[]|null): OverviewLocationGroup {
    terms = terms || [];
    let groupMatches = this.getFilterMatches(group.name, terms);
    let fullGroupMatch = groupMatches.every(b => b);
    
    return {
      id: group.id,
      name: group.name,
      description: group.description,
      locations: group.locations.filter(l => {
        if (fullGroupMatch) return true;
        let locationMatches = this.getFilterMatches(l.name, terms);
        return locationMatches.every((b, i) => b || groupMatches[i]);
      })
    };
  }

  getFilterMatches(text: string, terms: string[]): boolean[] {
    return terms.map(term => text.toLowerCase().includes(term.toLowerCase()));
  }

  readableLevelFromMeters(level?: number): string {
    if (typeof level == 'number') {
      return level.toFixed(2);
    } else {
      return '-';
    }
  }

  readableBatteryDeadEstimate(estimate?: Date): string {
    if (estimate) {
      const m = moment(estimate);
      if(m.isAfter(moment.now())) {
        return m.fromNow(m.isAfter(moment.now()));
      } else {
        return "~8 years"
      }
    } else {
      return '-';
    }
  }

  readableTemperature(kelvin?: number): string {
    return kelvin ? (kelvin + 273.15).toFixed(2) : '-';
  }

  readableTimestamp(stamp?: Date): String {
    return stamp ? moment(stamp).format('YYYY-MM-DD HH:mm') : '-';
  }

  private sortByNameAscending<T extends { name?: string }>(items: T[]): T[] {
    return [...items].sort((a, b) => a.name.localeCompare(b.name, 'nl', { sensitivity: 'base', numeric: true, usage: 'sort' }));
  }

  private getSelectedLocationIds(): number[] {
    return Array.from(this.checked.entries())
      .filter(([key, value]) => value)
      .map(([key, value]) => key);
  }

  private getSelectedLocations(): BlikLocation[] {
    return this.getSelectedLocationIds()
      .map(id => this.locationMap.get(id));
  }

  public onFilterChange(value: string) {
    this.filterText$.next(value);
  }

  public onSelectionChange() {
    this.selectedCount = this.getSelectedLocationIds().length;
  }

  compare() {
    this.router.navigate(['vergelijk', this.getSelectedLocationIds().join(',')]);
  }

  openExportDialog() {
    this.exportDialog.openExportDialog(this.getSelectedLocations());
  }

  public isCheckedAll(group: OverviewLocationGroup | undefined): boolean {
    if (!group || !group.locations.length) {
      return false;
    }

    return group.locations.every(l => this.checked.get(l.id));
  }

  public isCheckedSome(group: OverviewLocationGroup | undefined): boolean {
    if (!group || !group.locations.length) {
      return false;
    }

    return group.locations.some(l => this.checked.get(l.id)) && group.locations.some(l => !this.checked.get(l.id));
  }

  public checkAll(group: OverviewLocationGroup | undefined, event: Event) {
    if (!group) {
      return;
    }

    for (const l of group.locations) {
      this.checked.set(l.id, (event.target as HTMLInputElement).checked);
    }

    this.onSelectionChange();
  }
}

@Injectable()
export class LocationOverviewGuard implements CanActivate {

  constructor(
    private user: UserService,
    private router: Router
  ) { }

  canActivate() {
    return this.user.userHasPermission(DASHBOARD_LOCATIONS_OVERVIEW).pipe(map(p => {
      if (!p) {
        this.router.navigate(['/']);
      }
      return p;
    }));
  }
}

/**
 * Splits a filter query string into separate filter terms, splits into individual words, but keeps terms surrounded by quotes intact.
 * <code>Hello world, "How are you?" → ["hello", "world", "How are you?"] </code>
 * @param input - The input to parse.
 * @returns An array of strings, containing individual filter terms.
 */
function parseFilter(input: string) {
  const result = input.match(/\w+|"[^"]+"/g) || [];
  return result.map(p => p[0] === `"` ? p.slice(1, -1) : p);
}
