<ng-template #nodeployments>
<div class="nodeployments">No deployments</div>
</ng-template>
<ng-template #noreport>
  <td></td>
  <td></td>
  <td></td>
</ng-template>

<h1>Deployments</h1>
<table class="table table-editable table-hover" *ngIf="deployments?.length>0; else nodeployments">
  <thead>
    <tr>
      <th *ngFor="let c of columns; let i = index">{{c.header}}</th>
      <th>Re'mitted (%)</th>
      <th>Received (%)</th>
      <th>Received While Oper. (%)</th>
      <th>Resets</th>
      <th>Status</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let d of deployments; let i = index">
    <tr *ngIf="d" (click)="onSelectDeployment(i)" [class.active]="i == deploymentIndex" [hidden]="!d.showInList">
      <ng-container *ngFor="let c of columns; let j = index">
        <td *ngIf="c.inputtype != null"><input [(ngModel)]="d[c.parameter]" [type]="c.inputtype"></td>
        <td *ngIf="c.inputtype == null">{{d[c.parameter]}}</td>
      </ng-container>
      <ng-container *ngIf="i < deploymentsStatus.length && deploymentsStatus[i] as status; else noreport">
        <td>{{100*status.messagesRetransmitted_ratio | number : 0}}%</td>
        <td>{{100*status.messagesReceived_ratio | number : 0}}%</td>
        <td>{{100*status.messagesReceivedWhileOperational_ratio | number : 0}}%</td>
      </ng-container>
      <td>{{d.report.resets | number : 0}}</td>

      <td class="nopadding"><deployment-status *ngIf="d.id" [deploymentID]="d.id"></deployment-status></td>
    </tr>
    </ng-container>
  </tbody>
</table>
<div>
<label class="deploymentsetting">
  <input type="checkbox" checked data-md-icheck (change)="setHideTemporaryDeployments($event.target.checked)"> Hide temporary deployments
</label>
</div>
