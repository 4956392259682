<!-- Wrapper-->
<div id="wrapper">

  <!-- Left navigation bar -->
  <navigation></navigation>

  <!-- Main page wrapper -->
  <div id="page-wrapper" class="gray-bg">

    <div *ngIf="messages.length" id="page-alerts">
      <div *ngFor="let message of messages" [ngClass]="'alert-' + message.type" class="alert">
        <span>
          <i class="fa" [ngClass]="'fa-' + message.icon"></i>
          {{ message.text }}
        </span>
        <span *ngIf="message.links.length" class="buttons">
          <a
            *ngFor="let link of message.links"
            target="_blank"
            [href]="link.url"
            class="btn btn-default"
          >
            {{link.caption || 'Meer informatie…'}}
          </a>
        </span>
      </div>
    </div>

    <!-- Top navigation -->
    <topnavbar></topnavbar>

    <!-- Main view/routes wrapper-->
    <router-outlet></router-outlet>

    <!-- Footer -->
    <!--<footer></footer>-->

  </div>
  <!-- End page wrapper-->

</div>
<!-- End wrapper-->
