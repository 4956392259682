import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Cacheable } from "../shared/cacheable";

type LocationAttachmentsResult = {
  attachments: AttachmentResponse[],
};

type AttachmentResponse = {
  id: string;
  filename: string;
  size: number;
  canDelete: boolean;
  extra?: {
    caption?: string;
  }
};

type AttachmentRequest = {
  id: string;
};

@Injectable({
  providedIn: 'root'
})
export class LocationAttachmentsService {

  constructor(
    private http: HttpClient
  ) {
  }

  private boreholeLogs: Record<number, Cacheable<AttachmentResponse | null>> = {};
  private photos: Record<number, Cacheable<AttachmentResponse[]>> = {};


  getBoreholeLog(id: number): Observable<AttachmentResponse | null> {
    if (!this.boreholeLogs[id]) {
      this.boreholeLogs[id] = new Cacheable<AttachmentResponse | null>(this.getAttachmentsByType(id, 'borehole-logs')
        .pipe(map(r => r.length > 0 ? r[0] : null))
      );
    }

    return this.boreholeLogs[id].get();
  }

  setBoreholeLog(id: number, attachments: AttachmentRequest | null) {
    return this.setAttachmentsByType(id, 'borehole-logs', [attachments].filter(a => a)).toPromise();
  }

  getPhotos(id: number): Observable<AttachmentResponse[]> {
    if (!this.photos[id]) {
      this.photos[id] = new Cacheable<AttachmentResponse[]>(this.getAttachmentsByType(id, 'photos'));
    }

    return this.photos[id].get();
  }

  setPhotos(id: number, attachments: AttachmentRequest[]) {
    return this.setAttachmentsByType(id, 'photos', attachments).toPromise();
  }

  private getAttachmentsByType(locationId: number, type: string) {
    const apiUrl = `${environment.api_base_url}/locations/${locationId}/attachments/${type}`;
    return this.http.get<LocationAttachmentsResult>(apiUrl)
      .pipe(map((response) => response.attachments));
  }

  private setAttachmentsByType(locationId: number, type: string, attachments: AttachmentRequest[]) {
    const apiUrl = `${environment.api_base_url}/locations/${locationId}/attachments/${type}`;
    return this.http.put(apiUrl, {
      attachments: attachments
    });
  }
}
