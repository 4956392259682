import { Component } from '@angular/core';
import { UserService } from '../../services/user.service'
import {AuthService} from "../../auth/auth.service";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html'
})
export class ProfileComponent {

  public success: boolean;
  public sent: boolean = false;

  constructor(
    public auth: AuthService,
    public user: UserService
  ) {

  }

  resetPassword() {
    this.sent = true;
    this.auth.resetPassword()
      .then(() => this.success = true)
      .catch(() => this.success = false);
  }

  isDone = () => typeof this.success === 'undefined';
}
