<h2>Foto's</h2>

<user-image-gallery #gallery [images]="photos" [uglyChangeTriggerHack]=uglyChangeTriggerHack
    [editable]="editable && !(formBusy$ | async)"></user-image-gallery>

<div style="padding-top: 1em" class="noprint" *ngIf="editable">
    <label class="btn btn-default" *ngIf="!(formBusy$ | async)">
        <i class="fa fa-upload"></i> Selecteer bestand(en)<input type="file" style="display: none" id="file"
            (change)="setPendingPhotos($event.target.files); addPendingPhotos()" multiple>
    </label>

    <button class="btn btn-default" disabled *ngIf="formBusy$ | async">
        <i class="fa fa-upload"></i> Selecteer bestand(en)
    </button>
</div>



<h2>Boorbeschrijving</h2>

<user-image *ngIf="boreholeLog" [image]=boreholeLog [uglyChangeTriggerHack]=uglyChangeTriggerHack
    (imageClick)=openBoreholeLog()></user-image>

<div style="padding-top: 1em" class="noprint" *ngIf="editable">
    <label class="btn btn-default" *ngIf="!(formBusy$ | async)">
        <i class="fa fa-upload"></i> Selecteer bestand<input class="btn btn-default" style="display: none" type="file" id="file"
            (change)="setPendingBoreholeLog($event.target.files.item(0)); replaceBoreholeLog()">
    </label>

    <button class="btn btn-default" disabled *ngIf="formBusy$ | async">
        <i class="fa fa-upload"></i> Selecteer bestand
    </button>
</div>

<div style="padding-top: 1em" class="noprint" *ngIf="editable">
    <button class="btn btn-primary noprint" type="submit" (click)=save() id="well-attachments-save-btn"
        [disabled]="formBusy$ | async" *ngIf="editable">
        <i class="fa fa-spinner fa-spin" *ngIf="formBusy$ | async"></i> Foto's en boorbeschrijving opslaan
    </button>
</div>
