import { Component } from '@angular/core';
import { Router } from '@angular/router';

/**
 * This component is used to handle the case where the user is redirected from an authentication service such as auth0
 * back to the application (e.g. https://dashboard.blik-sensing.nl/callback). This url is configured in the auth service.
 * The service will usually set a response message on the location hash, such as the user's access token.
 * This token can then be stored by the app (on the auth service instance or localstorage).
 * As a temporary fix, reading the response is done using a route guard (see TokenGuard service).
 */
@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallbackComponent {

  constructor(
    private router: Router
  ) {
    router.navigate(['/'])
  }
}
