<div id="flowmeterchart"></div>
<div>
  <h2>Flow-data</h2>
    <ul>
    <li>Oppervlakte per meetput: 10800 m²</li>
    <li>Totale flow sloot in: {{roundToTwoDecimals(highestFlowInM3)}} m³ / {{roundToTwoDecimals(highestFlowInMillimeter)}} mm </li>
    <li>Totale flow akker in: {{roundToTwoDecimals(highestFlowOutM3)}} m³ / {{roundToTwoDecimals(highestFlowOutMillimeter)}} mm </li>
  </ul>
</div>

