import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Component } from '@angular/core';
import { AuthService } from "../auth/auth.service";


/*
 * /signup is not really a page in itself, but should do the following:
 * - Check if we are already logged in as an auth0 user.
 *   -> If so, redirect to / which will redirect us further to the default logged in landing page
 *   -> If not, use the auth0 module to immediately request the user to sign up
 */

@Component({
  template: ''
})
export class SignupComponent {

  constructor(
    private auth: AuthService,
  ) { 
    this.auth.signup();
  }
}


@Injectable()
export class SignupGuard implements CanActivate {

  constructor(
    private auth: AuthService,
    private router: Router
  ) { }

  canActivate() {
    if (!this.auth.authenticated) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }
}
