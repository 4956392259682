import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { Observable } from "rxjs";

import { environment } from '../../environments/environment';

export type StatusResponse = {
  messages?: StatusResponseMessage[];
}

export type StatusResponseMessage = {
  text: string;
  type?: string;
  links?: (StatusResponseMessageLink | string)[];
};

export type StatusResponseMessageLink = {
  url: string;
  caption?: string;
};

export type StatusMessage = {
  text: string;
  type: 'info' | 'warning' | 'danger';
  icon: string;
  links: StatusMessageLink[];
};

export type StatusMessageLink = {
  url: string;
  caption: string;
};

const cleanMessages = (response: StatusResponse | null): StatusMessage[] => {
  if (!response) {
    return [];
  }

  if (!response.messages) {
    return [];
  }

  return response.messages
    .filter(m => m && m.text)
    .map(m => {
      return {
        text: m.text,
        type: m.type === 'warning' ? 'warning' : (m.type === 'danger' ? 'danger' : 'info'),
        icon: m.type === 'warning' ? 'exclamation-triangle' : (m.type === 'danger' ? 'exclamation-circle' : 'info-circle'),
        links: (m.links || [])
          .map(l => {
            if (typeof l === 'string') {
              return {
                url: l,
                caption: 'Meer informatie…',
              };
            }

            return {
              url: l.url,
              caption: l.caption || 'Meer informatie…',
            };
          }),
      };
    })
};

@Injectable({
  providedIn: 'root'
})
export class StatusService {
  messages: Observable<StatusMessage[]>;

  constructor(private http: HttpClient) {
    this.messages = this.http.get<StatusResponse | null>(environment.status_base_url + '/dashboard-v1.json')
      .pipe(map(v => cleanMessages(v)));
  }
}
