import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class TokenGuard implements CanActivate {

  constructor(private auth: AuthService) { }

  canActivate() {
    // Pass the location hash to the callback manually, because it gets stripped from the url by Angular router and
    // auth0 can't read it anymore.
    if (window.location.hash.includes("access_token")) {
      this.auth.handleLoginCallback(window.location.hash);
    }
    return true;
  }
}
