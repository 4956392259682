import { Component, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { NotifierService } from 'angular-notifier';
import { WellDetails, WellProperties, PhysicalLocationDetails } from '../../services/location.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'well-details',
  templateUrl: './well-details.component.html',
  styleUrls: ['./well-details.component.css']
})
export class WellDetailsComponent {
  @Input() locationId: number;
  @Input() editable: boolean = false;

  formBusy$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  formData: WellDetails = {
    physicalLocation: {} as PhysicalLocationDetails,
    wellProperties: {} as WellProperties,
  }

  @Input() set data(val: WellDetails) {
    if (!val) {
      return;
    }
    if (!val.physicalLocation) {
      val.physicalLocation = {} as PhysicalLocationDetails;
    }
    if (!val.wellProperties) {
      val.wellProperties = {} as WellProperties;
    }

    this.formData = val;

    if (this.formData.physicalLocation) {
      this.formData.physicalLocation.address = [
        this.formData.physicalLocation.address,
        this.formData.physicalLocation.addition,
        this.formData.physicalLocation.town,
      ].filter(p => p).join('\n');

      this.formData.physicalLocation.addition = null;
      this.formData.physicalLocation.town = null;
    }
  }

  save() {
    this.formBusy$.next(true);

    // Final step of saving: post the new description JSON document
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.http.put(`${environment.api_base_url}/locations/${this.locationId}/details`, this.formData, { "headers": headers }).subscribe(
      data => {
        this.notifier.notify('success', 'Locatiegegevens geüpdatet.');
        this.formBusy$.next(false);
      },
      error => {
        console.error(error);
        this.notifier.notify('error', "Updaten locatiegegevens mislukt.");
        this.formBusy$.next(false);
      }
    );
  }

  constructor(
    private http: HttpClient,
    private notifier: NotifierService,
  ) {
  }
}
