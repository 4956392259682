import { Component, OnInit, Injectable, OnDestroy } from '@angular/core';
import { User, UserService } from '../services/user.service';
import { CanActivate, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { NotifierService } from 'angular-notifier';
import * as introJs from 'intro.js/intro.js';
import { LocalStorageService } from 'app/services/localstorage.service';
import { ImpersonatorService } from 'app/services/impersonator.service';
import { takeUntil } from 'rxjs/operators';
import { IMPERSONATE_USER } from 'app/services/permission-definitions';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit, OnDestroy {
  checked: {number: boolean} = {} as {number: boolean};
  checkedRoles: {string: boolean} = {} as {string: boolean};
  users: User[] = [];
  roleDescriptions: {string: string} = {} as {string: string};
  roles: string[] = [];
  rolesForUserId: {number: Observable<string[]>} = {} as {number: Observable<string[]>};
  myPrimaryCustomerGroupId: number;
  impersonateUserPermission: Observable<boolean>;
  
  newUser: User = new User();
  me: User;

  private unsubscribeObservable = new Subject<boolean>();

  // Tutorial framework
  private introJS = introJs();

  constructor(
    protected service: UserService,
    private notifier: NotifierService,
    private localStorageService: LocalStorageService,
    private impersonator: ImpersonatorService,
  ) {
    this.service.me.pipe(takeUntil(this.unsubscribeObservable)).subscribe(me => {
      if (me) {
        this.myPrimaryCustomerGroupId = me.primaryCustomerGroupId;
        this.me = me;
      }
    });
    service.users.pipe(takeUntil(this.unsubscribeObservable)).subscribe((u: {[id: number]: User}) => {
      for (let k in u) {
        let user = u[k];
        this.rolesForUserId[user.id] = service.getUserRoles(user.id);
        this.users.push(user);
      }
    });
    this.impersonateUserPermission = service.userHasPermission(IMPERSONATE_USER);
  }

  ngOnInit() {
    this.service.allRoleDescriptions.pipe(takeUntil(this.unsubscribeObservable)).subscribe(v => {
      this.roleDescriptions = v;
      this.roles = Object.keys(v);
      // setTimeout() is a workaround to defer until after all elements needed for the tour have been rendered.
      // Otherwise, the elements that the tutorial wants to point at cannot be found
      setTimeout(() => this.initialStartTour());
    });
  }

  ngOnDestroy() {
    this.unsubscribeObservable.next(true);
  }

  initialStartTour() {
    this.localStorageService.observeUserValue("userManagementTutorialCompleted").subscribe(completed => {
      if (!completed) {
        this.startTour();
      }
    });
  }

  startTour() {
    this.createIntroSteps();
    this.introJS.start();
    this.localStorageService.saveUserValue("userManagementTutorialCompleted", "true");
  }

  createIntroSteps() {
    let introSteps = [
      {   
        intro: "Op deze pagina kunnen beheerders gebruikers beheren.",},
      {
        element: '#userTable',
        intro: "In deze tabel staan bekende gebruikers en hun rollen.",
        location: 'bottom'
      },
      {
        element: '#btn-delete-user',
        intro: "Met de verwijderen-knop worden de gebruikers (direct) verwijderd uit het portal."
      },
      {
        element: '#userInvites',
        intro: "Hier kunnen gebruikers worden toegevoegd aan het portal."
      },
      {
        element: '#btn-add-user',
        intro: "Met de uitnodigen-knop wordt de gebruiker uitgenodigd voor het portal. Hij/zij krijgt een e-mail met verdere instructies om zich aan te melden. De nieuwe gebruiker krijgt de rol(len) die zijn geselecteerd."
      },
    ];
    
    this.introJS.setOptions({
      nextLabel: "Volgende",
      prevLabel: "Vorige",
      skipLabel: "Afsluiten",
      doneLabel: "Klaar",
      showStepNumbers: false,
      overlayOpacity: 0.5,
      steps: introSteps
    });
  }

  removeUsers() {
    for (let user of this.getSelectedUsers()) {
      this.service.deleteUser(user.id).subscribe(
        success => {
          this.users = this.users.filter(u => u.id != user.id);
          this.notifier.notify("success", "Gebruiker " + user.name + " is verwijderd");
        },
        error => {
          console.log(error);
          this.notifier.notify("error", "Gebruiker verwijderen is mislukt; " + error.error['description']);
        }
      );
    }
    console.log(this.getSelectedUsers());
  }

  addUser() {
    this.newUser.primaryCustomerGroupId = this.myPrimaryCustomerGroupId;
    this.service.createUser(this.newUser).subscribe(
      data => {
        let createdUser = data as User;
        this.users.push(createdUser);
        this.notifier.notify("success", "Er is een uitnodiging gestuurd naar " + createdUser.emailAddress);
        this.service.setUserRoles(createdUser.id, this.roles.filter(r => this.checkedRoles[r])).subscribe(
          success => {
            this.rolesForUserId[createdUser.id] = this.service.getUserRoles(createdUser.id);
          },
          error => {
            this.notifier.notify("error", "Instellen van rollen is mislukt; " + error.error['description']);
          }
        );
        
        // clear form
        this.newUser = {} as User;
        this.checkedRoles = {} as {string: boolean};
      },
      error => {
        console.log(error);
        this.notifier.notify("error", "Uitnodiging is mislukt; " + error.error['description']);
      }
    );
    console.log(this.newUser.name, this.newUser.emailAddress);
  }

  private getSelectedUsers(): User[] {
    return this.users.filter(user => this.checked[user.id]);
  }

  impersonate(userId: number) {
    this.impersonator.startImpersonation(userId);
  }
}


@Injectable()
export class UserManagementGuard implements CanActivate {

  constructor(
    private user: UserService,
    private router: Router
  ) { }

  canActivate() {
    return this.user.canManageUsers;
  }
}
