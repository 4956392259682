
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from "environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Subject, AsyncSubject, Observable, BehaviorSubject } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NotifierService } from 'angular-notifier';

export interface TokenResponse {
  token: string,
  expires?: string
}

export interface IUserFileDescription {
  url: string | null;
  id: string | null;
  filename: string | null;
  length: number;
  canDelete: boolean;
};

interface UserFileCreateOptions {
  locationId?: number;
  locationReadPermission?: string;
  locationGroupId?: number;
}

export function formatBytes(bytes: number): string {
  if (bytes === 0) {
     return '0 bytes';
  }

  if (!bytes || bytes < 0) {
    return '—';
  }

  if (bytes === 1) {
    return '1 byte';
  }

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(0))} ${sizes[i]}`;
};

export class UserFile {
  private future: Subject<any> = null;
  private pendingDelete: boolean = false;

  public locationId: number | null = null;
  public locationGroupId: number | null = null;
  public locationReadPermission: string | null = null;

  constructor(
    public description: IUserFileDescription,
    private http: HttpClient,
    private domSanitizer: DomSanitizer,
    public data: Blob | null = null,
    options?: UserFileCreateOptions,
  ) {
    if (data != null) {
      this.future = new AsyncSubject<any>();
      this.future.next(data);
      this.future.complete();
    }

    if (options) {
      Object.assign(this, options);
    }
  }

  isOnServer() {
    return this.description.id != null;
  }

  private _getApiUrl(): string {
    if (this.description.url) {
      if (this.description.url.includes('://')) {
        return this.description.url;
      }

      return environment.api_base_url + this.description.url;
    }

    console.warn("No URL in JSON description of file " + this.description.id + "; assuming on backend in /userfiles/");
    return environment.api_base_url + '/userfiles/' + this.description.id;
  }

  pendDeletion(pendingDelete: boolean) {
    this.pendingDelete = pendingDelete;
  }

  isDeletionPending() {
    return this.pendingDelete;
  }

  get(): Observable<Blob> {
    if (this.future == null) {
      const apiUrl = this._getApiUrl();
      this.future = new AsyncSubject<any>();
      this.http.get(apiUrl, { responseType: 'blob' }).subscribe(
        data => {
          this.future.next(data);
          this.future.complete();
        },
        error => {
          this.future.error(error);
        }
      );
    }
    return this.future;
  }

  delete(): Observable<any> | undefined {
    if (this.isOnServer()) {
      const apiUrl = this._getApiUrl();
      return this.http.delete(apiUrl, { responseType: 'json' }).pipe(
        data => {
          this.description.id = null;
          return data;
        },
        error => {
          return error;
        }
      );
    }
  }

  getUrl(): Observable<string> {
    return this.get().pipe(map(data => URL.createObjectURL(data)));
  }

  getSafeUrl(): Observable<SafeUrl> {
    return this.getUrl().pipe(map(url => this.domSanitizer.bypassSecurityTrustUrl(url)));
  }

  get fileSize(): string {
    return formatBytes(this.description.length);
  }
}

@Injectable({
  providedIn: 'root'
})
export class UserFileService {

  constructor(
    private http: HttpClient,
    private domSanitizer: DomSanitizer,
    private notifier: NotifierService
  ) {

  }

  /**
   * Get a user file from the backend.
   */
  getUserFile(desc: IUserFileDescription) {
    return new UserFile(desc, this.http, this.domSanitizer);
  }

  createLocalFile(file: File, options?: UserFileCreateOptions): UserFile {
    const desc = { url: null, filename: file.name, id: null, length: file.size, canDelete: true };
    return new UserFile(desc, this.http, this.domSanitizer, file, options);
  }

  uploadUserFile(userFile: UserFile): Observable<UserFile> {
    if (userFile.description.id) {
      console.log("Tried to upload already uploaded file");
      return;
    }

    if (!(userFile.data instanceof File)) {
      console.log("Tried to upload file without data");
      return;
    }

    const file = userFile.data as File;

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    let params = new HttpParams();

    if (userFile.locationId !== null) {
      params = params.set("location_id", "" + userFile.locationId);
    }

    if (userFile.locationGroupId !== null) {
      params = params.set("location_group_id", "" + userFile.locationGroupId);
    }

    if (userFile.locationReadPermission) {
      params = params.set("location_read_permission", userFile.locationReadPermission);
    }

    return this.http.post<IUserFileDescription>(`${environment.api_base_url}/userfiles`, formData, { params: params })
      .pipe(map(data => {
        userFile.description = data;
        return userFile;
      }));
  }

  downloadFile(file: IUserFileDescription) {
    this.http.get(`${environment.api_base_url}/userfiles/${file.id}/token`).subscribe({
      next: (response: TokenResponse) => {
        const url = `${environment.api_base_url}/userfiles/${file.id}?token=${response.token}`;

        const link = document.createElement('a');
        link.download = file.filename;
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      error: e => {
        if (!file.filename) {
          this.notifier.notify('error', 'Kon bestand niet downloaden');
          return;
        }

        this.notifier.notify('error', `Kon bestand "${file.filename}" niet downloaden`);
      },
    });
  }

  deleteFile(desc: IUserFileDescription): Observable<any> {
    if (!desc.canDelete) {
      throw new Error(`File (${desc.filename}) is not deletable.`);
    }

    const url = `${environment.api_base_url}/userfiles/${desc.id}`;
    return this.http.delete(url, { responseType: 'json' });
  }
}
