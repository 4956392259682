<div *ngIf="_settings as userSettings" class="wrapper wrapper-content animated fadeIn">
  <div class="row">
    <div class="col-lg-4">
      <div class="ibox">
        <div class="ibox-title">
          <h5>Mijn instellingen</h5>
        </div>

        <div class="ibox-content">
          <div class="settingsgroup">
          <table>
            <tr>
              <td class="settingskey">Waterstandnotificaties</td>
              <td><input class="form-check-input" type="checkbox" [(ngModel)]="userSettings.enableEmailWaterLevelNotifications" /></td>
            </tr>
          </table>
          </div>
          <button class="btn btn-default" (click)="saveSettings()">
            <i class="fa fa-save"></i> Sla instellingen op
          </button>
        </div>
      </div>
    </div>
  </div>

</div>
