import {Observable, Subject} from "rxjs";
import {repeatWhen, shareReplay} from "rxjs/operators";

/**
 *
 * [subscriber] ------ [cached object] ------ [http request]
 * [subscriber] -----/
 */
export class Cacheable<T> {
  constructor(source$: Observable<T>) {
    this._source$ = source$;
    this._refresh$ = new Subject();
    this._cache$ = this._source$.pipe(
      repeatWhen(() => this._refresh$),
      shareReplay(1)
    )
  }

  get(): Observable<T> {
    return this._cache$;
  }

  refresh() {
    this._refresh$.next();
  }

  private _refresh$: Subject<void>
  private _source$: Observable<T>
  private _cache$: Observable<T>
}
