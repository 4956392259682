<div class="modal-header">
    <h4 class="modal-title pull-left">Metingen exporteren</h4>
    <button type="button" class="close pull-right" aria-label="Dialoog sluiten" title="Dialoog sluiten" (click)="modalRef.hide()">
       <span aria-hidden="true">×</span>
    </button>
 </div>

 <ng-container [ngSwitch]="step">
   <ng-container *ngSwitchCase="'start'">
      <div class="modal-body">
         <form class="row" #form="ngForm">
            <div class="form-group col-md-12">
               <label for="locations">Locaties</label>
               <select id="locations" multiple class="form-control" disabled>
                  <option *ngFor="let location of locations">{{ location.name || location.id }}</option>
               </select>
            </div>
            <div class="form-group col-md-12">
               <label id="export-period-group-label">Periode:</label>
               <div aria-labelledby="export-period-group-label" class="form-group col-md-12" role="group">
                  <label class="col-md-12 radio"><input [(ngModel)]=exportOptions.periodType type="radio" name="range-type" value="all">Alle data</label>
                  <label id="export-period-select-label" class="col-md-12 radio"><input [(ngModel)]=exportOptions.periodType type="radio" name="range-type" value="select">Periode selecteren</label>
                  <input *ngIf="exportOptions.periodType === 'select'" aria-labelledby="export-period-select-label" id="date-range-picker" type="text" class="col-md-12 form-control" name="daterangeInput" daterangepicker [disabled]="exportOptions.periodType !== 'select'" [options]="dateRangePickerOptions" (selected)="selectedDate($event)" />
               </div>
            </div>
            <div class="form-group col-md-12">
               <label>Type uitvoer:</label>
               <div class="form-control radio">
                  <label class="radio-inline"><input [(ngModel)]=exportOptions.exportType type="radio" name="export-type" value="csv">CSV</label>
                  <label class="radio-inline"><input [(ngModel)]=exportOptions.exportType type="radio" name="export-type" value="xlsx">XLSX</label>
               </div>
            </div>
            <div class="form-group col-md-12">
               <label>Doel:</label>
               <div class="form-control radio">
                  <label class="radio-inline"><input (ngModelChange)="onProtocolChange($event)" [(ngModel)]=uploadOptions.protocol type="radio" name="export-target" value="download">Download</label>
                  <ng-container *ngIf="ftpPermission | async">
                     <label class="radio-inline"><input (ngModelChange)="onProtocolChange($event)" [(ngModel)]=uploadOptions.protocol type="radio" name="export-target" value="FTP">FTP</label>
                     <label class="radio-inline"><input (ngModelChange)="onProtocolChange($event)" [(ngModel)]=uploadOptions.protocol type="radio" name="export-target" value="SFTP">SFTP (SSH)</label>
                     <label class="radio-inline"><input (ngModelChange)="onProtocolChange($event)" [(ngModel)]=uploadOptions.protocol type="radio" name="export-target" value="FTPS">FTPS (FTP + SSL/TLS)</label>
                  </ng-container>
               </div>
            </div>
         </form>
      </div>
   
      <div class="modal-footer">
         <button type="button" class="btn btn-default" (click)="modalRef.hide()">Annuleren</button>
         <button type="button" class="btn btn-primary" (click)="nextStep()" [disabled]="!form.valid">Volgende stap <i class="fa fa-arrow-right"></i></button>
      </div>
   </ng-container>

   <ng-container *ngSwitchCase="'upload'">
      <div class="modal-body">
         <form class="row" #form="ngForm">
            <div class="form-group row">
               <div class="col-md-10">
                  <label for="hostname">Host (domeinnaam / IP)</label>
                  <input id="hostname" name="hostname" type="text" class="form-control" [(ngModel)]=uploadOptions.host required />
               </div>
               <div class="col-md-2">
                  <label for="port">Poort</label>
                  <input id="port" name="port" type="text" class="form-control" [(ngModel)]=uploadOptions.port required pattern="[0-9]+" />
               </div>
            </div>
            <div class="form-group">
               <label for="remote-directory">Map (optioneel)</label>
               <input id="remote-directory" name="remote-directory" type="text" class="form-control" [(ngModel)]=uploadOptions.remoteDirectory />
            </div>
            <div class="form-group">
               <label for="username">Gebruikersnaam</label>
               <input id="username" name="username" type="text" class="form-control" [(ngModel)]=uploadOptions.username required />
            </div>
            <div class="form-group">
               <label for="password">Wachtwoord</label>
               <input id="password" name="password" type="password" class="form-control" [(ngModel)]=uploadOptions.password required />
            </div>
         </form>
      </div>

      <div class="modal-footer">
         <button type="button" class="btn btn-default" (click)="previousStep()"><i class="fa fa-arrow-left"></i> Vorige stap</button>
         <button type="button" class="btn btn-primary" (click)="nextStep()" [disabled]="!form.valid">Volgende stap <i class="fa fa-arrow-right"></i></button>
      </div>
   </ng-container>
   
   <ng-container *ngSwitchCase="'filename'">
      <div class="modal-body">
         <form class="row" #form="ngForm">
            <div class="form-group">
               <label for="filename">Bestandsnaam</label>
               <div class="input-group">
                  <input id="filename" name="filename" type="text" class="form-control" [(ngModel)]=filename required />
                  <span class="input-group-addon">.zip</span>
               </div>
            </div>
            <ng-container *ngIf="!isDownload">
               <div class="alert alert-warning">
                  Let op, als er al een bestand bestaat met de zelfde naam, kan het zijn dat dit bestand overschreven wordt.
               </div>
               <div class="form-group" *ngIf="(userService?.me | async)?.emailAddress as emailAddress">
                  <label class="checkbox-inline">
                     <input name="email-notification" type="checkbox" [(ngModel)]=uploadOptions.emailNotification />
                     Stuur een e-mail met een afleverrapport naar <strong>{{ emailAddress }}</strong>
                  </label>
               </div>
            </ng-container>
         </form>
      </div>

      <div class="modal-footer">
         <button type="button" class="btn btn-default" (click)="previousStep()"><i class="fa fa-arrow-left"></i> Vorige stap</button>
         <button *ngIf="isDownload" type="button" class="btn btn-primary" (click)="nextStep()" [disabled]="!form.valid"><i class="fa fa-download"></i> Download starten</button>
         <button *ngIf="!isDownload" type="button" class="btn btn-primary" (click)="nextStep()" [disabled]="!form.valid"><i class="fa fa-upload"></i> Exporteren</button>
      </div>
   </ng-container>

   <ng-container *ngSwitchCase="'progress'">
      <div class="modal-body">
         <div class="progress">
            <div 
               class="progress-bar progress-bar-info"
               [ngStyle]="{width: progress+'%'}"
               role="progressbar" 
               [attr.aria-valuenow]=progress
               aria-valuemin="0"
               aria-valuemax="100" 
            >
            </div>
         </div>

         <div class="alert alert-info">
            De export wordt voorbereid. Momentje...
         </div>
      </div>
   </ng-container>

   <ng-container *ngSwitchCase="'done'">
      <div class="modal-body">
         <div class="progress">
            <div 
               class="progress-bar progress-bar-success"
               [ngStyle]="{width: '100%'}"
               role="progressbar" 
               [attr.aria-valuenow]=100
               aria-valuemin="0"
               aria-valuemax="100" 
            >
            </div>
         </div>

         <ng-container *ngIf="isDownload">
            <div class="alert alert-success">
               De export wordt gedownload. U vindt hem in uw downloads-map.
               <br />U kunt deze dialoog sluiten.
            </div>
         </ng-container>

         <div *ngIf="!isDownload" class="alert alert-success">
            De export wordt uitgevoerd.<br />
            <span *ngIf=uploadOptions.emailNotification>U krijgt een e-mailnotificatie bij voltooiing.<br /></span>
            U kunt deze dialoog sluiten.
         </div>
      </div>

      <div class="modal-footer">
         <button type="button" class="btn btn-default" (click)="modalRef.hide()">Sluiten</button>
      </div>
   </ng-container>

   <ng-container *ngSwitchCase="'error'">
      <div class="modal-body">
         <div *ngIf="!isDownload" class="progress">
            <div 
               class="progress-bar progress-bar-danger"
               [ngStyle]="{width: '100%'}"
               role="progressbar" 
               [attr.aria-valuenow]=100
               aria-valuemin="0"
               aria-valuemax="100" 
            >
            </div>
         </div>

         <div class="alert alert-danger">
            Er is iets fout gegaan:<br />
            {{ error || 'Bestand kon niet gedownload worden.' }}
         </div>
      </div>

      <div class="modal-footer">
         <button type="button" class="btn btn-primary" (click)="nextStep()"><i class="fa fa-repeat"></i> Opnieuw proberen</button>
         <button type="button" class="btn btn-default" (click)="modalRef.hide()">Sluiten</button>
      </div>
   </ng-container>

</ng-container>
 