import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'blik-loader-small',
  templateUrl: './blik-loader-small.component.html',
  styleUrls: ['./blik-loader-small.component.css']
})
export class BlikLoaderSmallComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
