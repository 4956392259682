import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "environments/environment";

export enum JobState {
    CREATED = "CREATED",
    QUEUED = "QUEUED",
    RUNNING = "RUNNING",
    FINISHED = "FINISHED",
    FAILED = "FAILED",
    DISMISSED = "DISMISSED",
}

export interface Job {
    jobId: number,
    ownerId: number,
    progress: number,
    progressDescription?: string,
    state: JobState,
    queuePosition?: number,
    errorMessage?: string,
    result?: any
    dependencies?: Job[];
}

@Injectable()
export class JobService {
    constructor(private http:HttpClient) { }

    getJob(jobId: number): Observable<Job> {
        return this.http.get<Job>(environment.api_base_url + "/jobs/" + jobId);
    }

    refreshJob(job: Job): Observable<Job> {
        return this.getJob(job.jobId);
    }

    isDone(job: Job) {
        return ["FINISHED", "FAILED", "DISMISSED"].indexOf(job.state) > -1;
    }

    totalProgress(job: Job): number {
        if (job.dependencies && job.dependencies.length) {
            var nJobs = 1 + job.dependencies.length;
            var dependencyProgress = job.dependencies.map(j => this.totalProgress(j)).reduce((a, b) => a + b, 0);
            return (job.progress + dependencyProgress) / nJobs;
        } else {
            return job.progress;
        }
    }
}
