<h1>Locatie-details</h1>

<fieldset [disabled]="formBusy$ | async">
    <legend class="h2">Basis-informatie</legend>
    <div class="row">
        <div class="form-row">
            <div class="form-group col-md-4"><label for="NITGnumber">NITG-nummer</label><input class="form-control ng-valid" id="NITGnumber" type="text" [(ngModel)]="formData.NITGnumber" [readonly]="!editable"></div>
            <div class="form-group col-md-4"><label for="BROid">BRO-ID</label><input class="form-control ng-valid" id="BROid" type="text" [(ngModel)]="formData.BROid" [readonly]="!editable"></div>
            <div class="form-group col-md-4"><label for="tubeNumber">Buisnummer</label><input class="form-control ng-valid" id="pipeID" type="text" [(ngModel)]="formData.wellProperties.tubeNumber" [readonly]="!editable"></div>
            <div class="form-group col-md-4"><label for="wellID">Putcode</label><input class="form-control ng-valid" id="wellID" type="text" [(ngModel)]="formData.wellID" [readonly]="!editable"></div>
            <div class="form-group col-md-4"><label for="internalName">Interne naam</label><input class="form-control ng-valid" id="internalName" type="text" [(ngModel)]="formData.internalName" [readonly]="!editable"></div>
            <div class="form-group col-md-4"><label for="initialFunction">Initiële functie</label><input class="form-control ng-valid" id="initialFunction" type="text" [(ngModel)]="formData.initialFunction" [readonly]="!editable"></div>
            <div class="form-group col-md-6"><label for="inUseSince">Datum ingebruikneming</label><input class="form-control ng-valid" id="inUseSince" type="text" [(ngModel)]="formData.inUseSince" [readonly]="!editable"></div>
            <div class="form-group col-md-6"><label for="inUseUntil">Datum buiten gebruik</label><input class="form-control ng-valid" id="inUseUntil" type="text" [(ngModel)]="formData.inUseUntil" [readonly]="!editable"></div>
            <div class="form-group col-md-4"><label for="deliveryContext">Kader Aanlevering</label><input class="form-control ng-valid" id="deliveryContext" type="text" [(ngModel)]="formData.deliveryContext" [readonly]="!editable"></div>
            <div class="form-group col-md-4"><label for="placementNorm">Normering Plaatsing</label><input class="form-control ng-valid" id="placementNorm" type="text" [(ngModel)]="formData.placementNorm" [readonly]="!editable"></div>
            <div class="form-group col-md-4"><label for="maintenanceCompanyKVKID">KVK Onderhoudspartij</label><input class="form-control ng-valid" id="maintenanceCompanyKVKID" type="text" [(ngModel)]="formData.maintenanceCompanyKVKID" [readonly]="!editable"></div>
        </div>
    </div>
</fieldset>

<fieldset [disabled]="formBusy$ | async">
    <legend class="h2">Locatie-informatie</legend>
    <div class="row">
        <div class="form-group col-md-6"><label for="address">Adres</label><textarea class="form-control ng-valid" id="address" style="resize: none" rows="4" [(ngModel)]="formData.physicalLocation.address" [readonly]="!editable"></textarea></div>
        <div class="form-group col-md-3"><label for="rijksdriehoekX">Rijksdriehoek X</label><input class="form-control ng-valid" id="rijksdriehoekX" type="text" [(ngModel)]="formData.physicalLocation.rijksdriehoekX" [readonly]="!editable"></div>
        <div class="form-group col-md-3"><label for="rijksdriehoekY">Rijksdriehoek Y</label><input class="form-control ng-valid" id="rijksdriehoekY" type="text" [(ngModel)]="formData.physicalLocation.rijksdriehoekY" [readonly]="!editable"></div>
    </div>
    <div class="row">
        <div class="form-group col-md-4"><label for="groundLevelMetersAboveReference">Maaiveldniveau (m boven referentie)</label><input class="form-control ng-valid" id="groundLevelMetersAboveReference" type="text" [(ngModel)]="formData.physicalLocation.groundLevelMetersAboveReference" [readonly]="!editable"></div>
        <div class="form-group col-md-6"><label for="mvMethod">Methode Positiebepaling Maaiveld</label><input class="form-control ng-valid" id="mvMethod" type="text" [(ngModel)]="formData.physicalLocation.mvMethod" [readonly]="!editable"></div>
        <div class="form-group col-md-2"><label for="groundLevelStability">Maaiveld stabiel</label><input class="form-control ng-valid" id="groundLevelStability" type="checkbox" [(ngModel)]="formData.physicalLocation.groundLevelStability" [disabled]="!editable"></div>
        <div class="form-group col-md-3"><label for="heightReference">Verticaal referentievlak</label><input class="form-control ng-valid" id="heightReference" type="text" [(ngModel)]="formData.physicalLocation.heightReference" [readonly]="!editable"></div>
        <div class="form-group col-md-3"><label for="heightMeasureDate">Datum hoogtemeting</label><input class="form-control ng-valid" id="heightMeasureDate" type="text" [(ngModel)]="formData.physicalLocation.heightMeasureDate" [readonly]="!editable"></div>
        <div class="form-group col-md-6"><label for="groundWaterFlowJudgment">Oordeel toestroom grondwater</label><input class="form-control ng-valid" id="groundWaterFlowJudgment" type="text" [(ngModel)]="formData.physicalLocation.groundWaterFlowJudgment" [readonly]="!editable"></div>
    </div>
    <div class="row">
        <div class="form-group col-md-4"><label for="groundOwner">Eigenaar grond</label><input class="form-control ng-valid" id="groundOwner" type="text" [(ngModel)]="formData.physicalLocation.groundOwner" [readonly]="!editable"></div>
        <div class="form-group col-md-8"><label for="groundOwnerDeals">Afspraken eigenaar grond</label><input class="form-control ng-valid" id="groundOwnerDeals" type="text" [(ngModel)]="formData.physicalLocation.groundOwnerDeals" [readonly]="!editable"></div>
    </div>
</fieldset>

<fieldset [disabled]="formBusy$ | async">
    <legend class="h2">Peilbuis-informatie</legend>
    <div class="row">
        <div class="form-row">
            <div class="form-group col-md-4"><label for="pipeType">Buistype</label><input class="form-control ng-valid" id="pipeType" type="text" [(ngModel)]="formData.wellProperties.pipeType" [readonly]="!editable"></div>
            <div class="form-group col-md-4"><label for="pipeStatus">Buisstatus</label><input class="form-control ng-valid" id="pipeStatus" type="text" [(ngModel)]="formData.wellProperties.pipeStatus" [readonly]="!editable"></div>
            <div class="form-group col-md-2"><label for="hasLDDop">Heeft LD dop</label><input class="form-control ng-valid" id="hasLDDop" type="checkbox" [(ngModel)]="formData.wellProperties.hasLDDop" [disabled]="!editable"></div>
            <div class="form-group col-md-2"><label for="hasSandTrap">Heeft zandvang</label><input class="form-control ng-valid" id="hasSandTrap" type="checkbox" [(ngModel)]="formData.wellProperties.hasSandTrap" [disabled]="!editable"></div>
            <div class="form-group col-md-4"><label for="placeDate">Datum plaatsing</label><input class="form-control ng-valid" id="placeDate" type="text" [(ngModel)]="formData.wellProperties.placeDate" [readonly]="!editable"></div>
            <div class="form-group col-md-4"><label for="depthMeasureDate">Datum eerste inmeting</label><input class="form-control ng-valid" id="depthMeasureDate" type="text" [(ngModel)]="formData.wellProperties.depthMeasureDate" [readonly]="!editable"></div>
            <div class="form-group col-md-4"><label for="lastDepthMeasureDate">Datum laatste inmeting</label><input class="form-control ng-valid" id="lastDepthMeasureDate" type="text" [(ngModel)]="formData.wellProperties.lastDepthMeasureDate" [readonly]="!editable"></div>
        </div>
        <div class="form-row">
            <!-- <div class="form-group col-md-4"><label for="xyMethod">Methode Locatiebepaling</label><input class="form-control ng-valid" id="xyMethod" type="text" [(ngModel)]="formData.wellProperties.xyMethod" [readonly]="!editable"></div> -->
            <div class="form-group col-md-4"><label for="kopMethod">Methode Positiebepaling BovenkantBuis</label><input class="form-control ng-valid" id="kopMethod" type="text" [(ngModel)]="formData.wellProperties.kopMethod" [readonly]="!editable"></div>
            <div class="form-group col-md-2"><label for="wellStability">Putstabiliteit</label><input class="form-control ng-valid" id="wellStability" type="text" [(ngModel)]="formData.wellProperties.wellStability" [readonly]="!editable"></div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6"><label for="wellOwner">Eigenaar peilbuis</label><input class="form-control ng-valid" id="wellOwner" type="text" [(ngModel)]="formData.wellProperties.wellOwner" [readonly]="!editable"></div>
        </div>
    </div>
    <h3>Maten en hoogtes</h3>
    <div class="row">
        <div class="form-row">
            <div class="form-group col-md-6"><label for="topOfFilterMetersAboveReference">Bovenkant filter (m boven referentie)</label><input class="form-control ng-valid" id="topOfFilterMetersAboveReference" type="text" [(ngModel)]="formData.wellProperties.topOfFilterMetersAboveReference" [readonly]="!editable"></div>
            <div class="form-group col-md-6"><label for="bottomOfFilterMetersAboveReference">Onderkant filter (m boven referentie)</label><input class="form-control ng-valid" id="bottomOfFilterMetersAboveReference" type="text" [(ngModel)]="formData.wellProperties.bottomOfFilterMetersAboveReference" [readonly]="!editable"></div>
            <div class="form-group col-md-6"><label for="topOfWellMetersAboveReference">Bovenkant peilbuis (m boven referentie)</label><input class="form-control ng-valid" id="topOfWellMetersAboveReference" type="text" [(ngModel)]="formData.wellProperties.topOfWellMetersAboveReference"
                [readonly]="!editable"></div>
            <div class="form-group col-md-6"><label for="depthFromTopOfWellMeters">Diepte vanaf bovenkant (m)</label><input class="form-control ng-valid" id="depthFromTopOfWellMeters" type="text" [(ngModel)]="formData.wellProperties.depthFromTopOfWellMeters" [readonly]="!editable"></div>
            <div class="form-group col-md-3"><label for="draadLengte">Draadlengte (m)</label><input class="form-control ng-valid" id="cableLengthMeters" type="text" [(ngModel)]="formData.wellProperties.cableLengthMeters" [readonly]="!editable"></div>
            <div class="form-group col-md-3"><label for="insideDiameterMm">Binnendiameter (mm)</label><input class="form-control ng-valid" id="insideDiameterMm" type="text" [(ngModel)]="formData.wellProperties.insideDiameterMm" [readonly]="!editable"></div>
            <div class="form-group col-md-3"><label for="outsideDiameterMm">Buitendiameter (mm)</label><input class="form-control ng-valid" id="outsideDiameterMm" type="text" [(ngModel)]="formData.wellProperties.outsideDiameterMm" [readonly]="!editable"></div>
            <div class="form-group col-md-3"><label for="variableDiameter">Variabele diameter</label><input class="form-control ng-valid" id="variableDiameter" type="checkbox" [(ngModel)]="formData.wellProperties.variableDiameter" [disabled]="!editable"></div>
        </div>
    </div>
    <h3>Materialen en afwerking</h3>
    <div class="row">
        <div class="form-row">
            <div class="form-group col-md-3"><label for="pipeMaterial">Buismateriaal</label><input class="form-control ng-valid" id="pipeMaterial" type="text" [(ngModel)]="formData.wellProperties.pipeMaterial" [readonly]="!editable"></div>
            <div class="form-group col-md-3"><label for="fillingMaterial">Aanvulmateriaal</label><input class="form-control ng-valid" id="fillingMaterial" type="text" [(ngModel)]="formData.wellProperties.fillingMaterial" [readonly]="!editable"></div>
            <div class="form-group col-md-3"><label for="glue">Lijm</label><input class="form-control ng-valid" id="glue" type="text" [(ngModel)]="formData.wellProperties.glue" [readonly]="!editable"></div>
            <div class="form-group col-md-3"><label for="sockMaterial">Kousmateriaal</label><input class="form-control ng-valid" id="sockMaterial" type="text" [(ngModel)]="formData.wellProperties.sockMaterial" [readonly]="!editable"></div>
            <div class="form-group col-md-4"><label for="onlay">Beschermconstructie</label><input class="form-control ng-valid" id="onlay" type="text" [(ngModel)]="formData.wellProperties.onlay" [readonly]="!editable"></div>
            <div class="form-group col-md-4"><label for="lock">Slot</label><input class="form-control ng-valid" id="lock" type="text" [(ngModel)]="formData.wellProperties.lock" [readonly]="!editable"></div>
            <div class="form-group col-md-4"><label for="material">Overig materiaal</label><input class="form-control ng-valid" id="material" type="text" [(ngModel)]="formData.wellProperties.material" [readonly]="!editable"></div>
            <div class="form-group col-md-6"><label for="trajectFiltergrindEnZwelklei">Traject filtergrind en zwelklei</label><input class="form-control ng-valid" id="trajectFiltergrindEnZwelklei" type="text" [(ngModel)]="formData.wellProperties.trajectFiltergrindEnZwelklei" [readonly]="!editable"></div>
        </div>
    </div>
</fieldset>

<div class="row row-flex">
    <div class="col-auto">
        <button class="btn btn-primary noprint" type="submit" (click)=save() id="well-details-save-btn" [disabled]="formBusy$ | async" *ngIf="editable">
            <i class="fa fa-spinner fa-spin" *ngIf="formBusy$ | async"></i> Detail-data opslaan
        </button>
    </div>

    <div class="col-xs-12">
        <div class="alert alert-info alert-thin">
            Om wijzigingen in foto's of boorbeschrijvingen op te slaan: gebruik de knop onder de boorbeschrijving.
        </div>
    </div>
</div>
