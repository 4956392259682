<div id="location-overview">
  <ng-container *ngIf="locationGroups$ | async as locationGroups; else loadingLocationGroups">
    <ng-container *ngIf="locationGroups == null || locationGroups.length > 0; else noLocationGroups">
        <div class="row">
            <div class="form-inline col-xs-12">
                <span class="form-inline">
                    <span class="input-group" id="filter-group">
                        <span class="input-group-addon" title="Locaties en groepen filteren:">
                            <i class="fa fa-filter"></i>
                            <label class="sr-only" for="filter-input">Locaties en groepen filteren:</label>
                        </span>
                        <input class="form-control" id="filter-input" [ngModel]="filterText$ | async" (input)="onFilterChange($event.target.value)" type="text">
                        <span class="input-group-btn">
                            <button aria-label="Filter wissen" [ngClass]="{'btn-primary' : !(filterClearable$ | async), 'btn-default': (filterClearable$ | async) }" class="btn" (click)="clearFilter()" [disabled]="filterClearable$ | async" tooltip="Filter wissen">
                                <i class="fa fa-times"></i>
                            </button>
                        </span>
                    </span>
                </span>
                <span class="separator"></span>
                <button *ngIf="this.locationComparePermission | async" class="btn btn-default m-sm m-l-none text-left" id="compare-button" (click)="compare()" [disabled]="selectedCount === 0">
                    Locaties vergelijken
                </button>
                <ng-container *ngIf="this.exportPermission | async">
                    <button class="btn btn-default m-sm m-l-none text-left" id="export-button" (click)="openExportDialog()" [disabled]="selectedCount === 0">Metingen exporteren…</button>
                </ng-container>
                <div class="pull-right text-right">
                    <button class="btn btn-default m-sm m-l-none" (click)="startTour()" id="location-overview-tour-start">?</button>
                </div>
            </div>
        </div>

        <table class="table table--sticky">
            <colgroup>
                <col style="width: 0;" />
                <col/>
                <col/>
                <col/>
                <col/>
                <col/>
                <col/>
                <col/>
            </colgroup>
            <thead>
                <tr>
                    <th style="padding-left: 0; padding-right: 0; text-align: center;">
                        <span class="sr-only">Selectie</span>
                        <div aria-hidden="true" class="badge badge-info" style="min-width: 3em">{{ selectedCount }}</div>
                    </th>
                    <th>Naam</th>
                    <th>Laatste bericht ontvangen</th>
                    <th>Nieuwste meetgegevens</th>
                    <th>Actueel peil (m maaiveld)</th>
                    <th>Min. peil {{time_window_days}}d (m maaiveld)</th>
                    <th>Gem. peil {{time_window_days}}d (m maaiveld)</th>
                    <th>Max. peil {{time_window_days}}d (m maaiveld)</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody *ngFor="let group of visibleLocationGroups$ | async">
                <tr class="location-group-header-row">
                    <td>
                        <label class="select-all" [attr.for]="'group-checkbox-' + group.id">
                            <input type="checkbox" [checked]="isCheckedAll(group)" [indeterminate]="isCheckedSome(group)" [disabled]="group.locations.length === 0" (change)="checkAll(group, $event)" value="{{group.id}}" [attr.id]="'group-checkbox-' + group.id" [attr.data-nonempty-group-checkbox]="group.locations.length > 0" />
                            <span class="sr-only" >{{group.name}} toevoegen aan vergelijking</span>
                        </label>
                    </td>
                    <th colspan="8" scope="colgroup">
                        <div class="location-group-header">
                            <div class="location-group-header__name">
                                <input class="location-group-header__expand" type="checkbox" [ngModel]="groupsExpanded.get(group.id)" (ngModelChange)="groupsExpanded.set(group.id, $event)" value="{{group.id}}" [attr.id]="'group-expanded-checkbox-' + group.id" />
                                <label class="location-group-header__heading h2" [attr.for]="'group-expanded-checkbox-' + group.id" [attr.data-nonempty-group-heading]="group.locations.length > 0">
                                    <i _ngcontent-lwv-c1="" class="fa fa-caret-right"></i>
                                    {{group.name}}
                                </label>
                                <div class="location-group-header__badge badge" [ngClass]="group.locations.length == 0 ? 'badge-disable' : 'badge-success'">{{ group.locations.length }}</div>
                            </div>
                            <div class="location-group-header__summary" *ngIf="(locationGroupDetailsPermission$ | async) && groupsExpanded.get(group.id)">
                                <span class="location-group-header__description">{{ group.description || '—' }}
                                    <br /><a [routerLink]="['/location-group-detail/', group.id]" class="location-group-header__link" [attr.data-nonempty-group-details]="group.locations.length > 0">Details…</a>
                                </span>
                            </div>
                        </div>
                    </th>
                </tr>
                <ng-container *ngIf="groupsExpanded.get(group.id)">
                    <tr *ngFor="let location of group.locations" class="location-row">
                        <td>
                            <label class="location-row__checkbox" [attr.for]="'location-checkbox-' + location.id">
                                <input type="checkbox" [ngModel]="checked.get(location.id)" (ngModelChange)="checked.set(location.id, $event)" (change)="onSelectionChange()" value="{{location.id}}" [attr.id]="'location-checkbox-' + location.id" />
                                <span class="sr-only">{{location.name}} toevoegen aan vergelijking</span>
                            </label>
                        </td>
                        <th scope="row"><a [routerLink]="['/location-detail/', location.id]" class="location-link">{{location.name}}</a></th>
                        <td>{{location.lastMessageReceivedAt ? readableTimestamp(location.lastMessageReceivedAt) : ''}}</td>
                        <td>{{location.lastMeasurementAt ? readableTimestamp(location.lastMeasurementAt) : ''}}</td>
                        <td>{{location.currentGroundWaterLevel ? readableLevelFromMeters(location.currentGroundWaterLevel) : ''}}</td>
                        <td>{{location.minGroundWaterLevel ? readableLevelFromMeters(location.minGroundWaterLevel) : ''}}</td>
                        <td>{{location.averageGroundWaterLevel ? readableLevelFromMeters(location.averageGroundWaterLevel) : ''}}</td>
                        <td>{{location.maxGroundWaterLevel ? readableLevelFromMeters(location.maxGroundWaterLevel) : ''}}</td>
                        <td class="nopadding"><location-status [location]="location"></location-status></td>
                    </tr>
                    <tr *ngIf="!group.locations.length">
                        <td colspan="9">
                            <div class="alert alert-info">
                                {{ (filterTerms$ | async).length ? "Deze groep bevat geen locaties die overeen komen met de het huidige filter." : "Deze groep bevat geen locaties." }}
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
            <tbody *ngIf="(visibleLocationGroups$ | async).length === 0">
                <tr>
                    <td colspan="9">
                        <div class="alert alert-info">
                            Er zijn geen locaties of locatie-groepen die overeen komen met het huidige filter.
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </ng-container>
  </ng-container>
</div>

<ng-template #noLocationGroups>
    <h2>Overzicht</h2>
    <p>Het lijkt erop dat u op dit moment geen toegang heeft tot enige meetlocaties.</p>
    <p>Neem contact op met de beheerder van het meetnet of <a href="mailto:info@blik-sensing.nl">info@blik-sensing.nl</a> als u denk dat niet klopt.</p>
</ng-template>

<ng-template #loadingLocationGroups>
  <p>Locaties laden...</p>
</ng-template>
