import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './../../../auth/auth.service';
import { CompareService } from "../../../services/compare-service";
import 'jquery-slimscroll';
import { UserService } from 'app/services/user.service';
import { Observable } from 'rxjs';
import { DASHBOARD_LOCATIONS_OVERVIEW, DASHBOARD_LOCATIONS_COMPARISON } from 'app/services/permission-definitions';
import { ImpersonatorService } from 'app/services/impersonator.service';
import { LocationID } from "app/services/location.service";

declare var jQuery:any;

@Component({
  selector: 'navigation',
  templateUrl: 'navigation.template.html',
  styleUrls: ['navigation.component.css'],
})

export class NavigationComponent implements OnInit {

  public comparedLocations: LocationID[];
  public companyLogo: Observable<string>;

  public locationOverviewPermission: Observable<boolean>;
  public compareLocationsPermission: Observable<boolean>;

  constructor(
    private router: Router,
    public auth: AuthService,
    private compareService: CompareService,
    public user: UserService,
    public impersonator: ImpersonatorService,
  ) {
    this.companyLogo = user.getCompanyLogo();
    this.locationOverviewPermission = user.userHasPermission(DASHBOARD_LOCATIONS_OVERVIEW);
    this.compareLocationsPermission = user.userHasPermission(DASHBOARD_LOCATIONS_COMPARISON);
  }

  ngOnInit() {
    // Listen for changes on compared locations
    this.compareService.watchStorage().subscribe((locations:[LocationID]) => {
      this.comparedLocations = locations;
    });
  }


  ngAfterViewInit() {
    jQuery('#side-menu').metisMenu();

    if (jQuery("body").hasClass('fixed-sidebar')) {
      jQuery('.sidebar-collapse').slimscroll({
        height: '100%'
      })
    }
  }

  activeRoute(routename: string): boolean{
    return this.router.url.indexOf(routename) > -1;
  }

  logOut() {
    this.compareService.clear();
    this.auth.logout();
  }
}
