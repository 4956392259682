<ng-template #readOnly>
    <h1>{{ locationName }}</h1>
</ng-template>

<ng-template #readOnlyDescription>
    {{ description || ""}}
</ng-template>

<div>
    <div class="row" id="location-description">
        <div class="col-xs-12 col-md-9">
            <div class="titleContainer">
              <div class="statusContainer">
                  <location-status [location]="locationData"></location-status>
              </div>
              <h1 *ngIf="(editLocationDetailsPermission | async); else readOnly" class="editable" spellcheck="false" contenteditable [appContenteditable]="locationName" [innerHtml]="locationName" (onChange)="updateLocationName($event)"></h1>
            </div>

            <p *ngIf="!editingDescription" [ngClass]="description ? 'description' : 'text-muted'" (click)="editDescription()" class="editable">
                <ng-container *ngIf="(editLocationDetailsPermission | async); else readOnlyDescription">
                    {{ description || "Klik hier om een beschrijving toe te voegen."}}
                </ng-container>
            </p>
            <textarea *ngIf="(editLocationDetailsPermission | async) && editingDescription" [(ngModel)]="description" appAutofocus class="form-control description-input" (blur)="updateDescription()"></textarea>
        </div>
        <div class="col-xs-12 col-md-3 text-right noprint">
            <button class="btn btn-default m-sm m-l-none" (click)="print()" id="location-detail-print">Print</button>
            <button class="btn btn-default m-sm m-l-none" (click)="startTour()" id="location-detail-tour-start">?</button>
        </div>
    </div>
    <div class="row noprint container-fluid">
        <div class="col-xs-12 col-md-8 form-group location-action-buttons" id="location-action-buttons">
            <button *ngIf="locationComparePermission | async" type="button" class="btn btn-default" (click)="compare()">
                <i class="fa fa-fw fa-{{ compareLocation ? 'check-square' : 'square'}}-o"></i> Vergelijken
            </button>
            <button *ngIf="csvPermission | async" type="button" class="btn btn-default" (click)="downloadCsv()">Download CSV</button>
            <button *ngIf="csvPermission | async" type="button" class="btn btn-default" (click)="downloadXlsx()">Download XLSX</button>
            <button *ngIf="measurementDetailsPermission | async" aria-pressed="showDetails" type="button" class="btn btn-default" (click)="toggleShowDetails()">
                <i class="fa fa-fw fa-{{ showDetails ? 'check-square' : 'square'}}-o"></i> Detailgrafieken
            </button>
        </div>
        <div *ngIf="knmiPermission | async" class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 pull-right text-right">
            <div class="knmiIndicatorContainer">
                <blik-loader-small *ngIf="loadingKNMIData"></blik-loader-small>
                <span *ngIf="noKNMIData" class="glyphicon glyphicon-remove noDataIcon"></span>
            </div>
            <div class="knmiSelectorContainer" id="knmi-selector">
                <label for="knmiStationSelector">KNMI-station</label>
                <select id="knmiStationSelector" class="form-control" [(ngModel)]="selectedKNMIStationIndex" (change)="onChangeKNMIStation($event)">
                    <option *ngFor="let k of knmiStations; let i = index" [value]="i">{{k._desc}}</option>
                </select>
            </div>
        </div>
    </div>

    <div class="row" id="waterlevel-display">
        <div class="col-xs-12">
        <app-waterlevel-display
          [locationData]=locationData
          [basicMeasurementData]=basicMeasurementData
          [detailMeasurementData]=detailMeasurementData
          [showDetailMeasurementData]=showDetails
          [knmiData]=knmiData
          (dateSelection)='dateSelect($event)'
          (measurementsRefreshRequester) = 'refreshMeasurements()'>
        </app-waterlevel-display>
        </div>
    </div>

    <div *ngIf="(blik20krimp001Permission | async) || (blik21berge001Permission | async)" class="row">
        <div class="alert alert-info col-centered col-xs-12 col-lg-7">
            <h3>Uitleg</h3>
            <p>
                De getoonde grondwaterstand geldt alleen op het meetpunt en is <em>niet</em> per se representatief voor nabijgelegen percelen.
            </p>
            <p>
                Deze metingen worden automatisch verzameld en beoordeeld op geldigheid.<br/>
                Elke zes maanden worden de metingen gevalideerd met een handmatige meting, te zien als een rode punt.<br/>
                Goedgekeurde metingen worden donkerblauw gekleurd.<br/>
                Afgekeurde metingen worden niet weergegeven. In plaats daarvan wordt een rood vlak getoond.
            </p>
            <p *ngIf="blik20krimp001Permission | async">
                Bij vragen kunt u <strong><a target=_blank href="https://www.krimpenerwaard.nl/contact">contact opnemen met de gemeente Krimpenerwaard</a></strong>.
            </p>
            <p *ngIf="blik21berge001Permission | async">
                Bij vragen kunt u <strong><a target=_blank href="https://www.bergendal.nl/openingstijden-contact-en-afspraak">contact opnemen met de gemeente Berg en Dal</a></strong>.
            </p>
        </div>
    </div>

    <div *ngIf="(blik22wpnoo001Permission | async)" class="row">
        <div class="alert alert-info col-centered col-xs-12 col-lg-7">
            <h3>Uitleg</h3>
            <p>
                De getoonde grondwaterstand geld alleen op het meetpunt en is <em>niet</em> per se representatief voor nabijgelegen percelen.
            </p>
            <p>
                De metingen worden automatisch uitgevoerd en automatisch getoetst.<br/>
                Elk kwartaal worden de metingen handmatig gecontroleerd.<br/>
                De handmatig gecontroleerde metingen zijn te herkennen aan de donkerblauwe achtergrond onder de grafiek.
            </p>
            <p>
                Bij vragen kunt u contact opnemen kunt u contact opnemen met de gemeente waarin het meetpunt zich bevindt.
            </p>
        </div>
    </div>

    <div class="row noprint">

    </div>

    <div class="row">
        <div class="col-xs-12">
      <app-flowmeter-display
        *ngIf="hasFlowmeter"
        [selectionDate]=selectionDate
        [measurementData]="measurementData ? measurementData['measurements'] : null">
            </app-flowmeter-display>
        </div>
    </div>

    <div *ngIf="wellDetailsPermission | async" class="row">
        <div class="col-xs-12">
            <div class="row">
                <div class="col-lg-8" id="well-details">
                    <well-details [locationId]=id [data]=details [editable]="editWellDetailsPermission | async"></well-details>
                </div>
                <div class="col-lg-4" id="well-attachments">
                    <well-attachments [locationId]=id [editable]="editWellDetailsPermission | async"></well-attachments>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="configPermission | async" class="row noprint">
        <div class="col-xs-12">
            <measurement-interval-manager [location]=locationData></measurement-interval-manager>
        </div>
    </div>
    <div *ngIf="waterLevelEmailNotificationPermission | async" class="row noprint" id="user-settings">
      <div class="col-xs-12">
        <location-user-settings [locationID]=id></location-user-settings>
      </div>
    </div>
    <div class="row">
        <div class="col-xs-12">
            <location-log *ngIf="logPermission | async" [locationID]=id></location-log>
        </div>
    </div>

    <div class="row noprint">
        <div *ngIf="analysisPermission | async" class="col-xs-12">
            <h1>Location details</h1>
            <table class="table" *ngIf="locationDetails">
                <colgroup>
                    <col>
                    <col>
                </colgroup>
                <tr *ngFor="let p of locationDebugDetails">
                    <th>{{p[0]}}</th>
                    <td>{{p[1]}}</td>
                </tr>
            </table>
        </div>
    </div>
    <div class="row noprint">
        <div *ngIf="analysisPermission | async" class="col-xs-12">
            <deployment-list [deploymentIDs]="deploymentIDs" (deploymentIndexSet)="onSelectDeployment($event)"></deployment-list>
        </div>
    </div>

    <div class="row noprint">
        <div *ngIf="analysisPermission | async" class="col-xs-12">
            <deployment-detail *ngIf="deploymentID" [deploymentID]="deploymentID"></deployment-detail>
        </div>
    </div>
</div> <!-- End of content div-->
