export const ANNOTATIONS = "ANNOTATIONS"; // View annotations in the water level graph
export const CUSTOMER_GROUP_USER_ADMIN = "CUSTOMER_GROUP_USER_ADMIN"; // Administrate users permissions and add users within the customer group
export const DEPLOY_NODE = "DEPLOY_NODE"; // Deploy a node to a location
export const EDIT_LOCATION_DETAILS = "EDIT_LOCATION_DETAILS"; // Edit measurement location details
export const EDIT_REFERENCE_LEVELS = "EDIT_REFERENCE_LEVELS"; // Edit reference lines in water level graphs
export const EDIT_WELL_DETAILS = "EDIT_WELL_DETAILS"; // Edit well details (address, soil description, etc)
export const EXPORT_CSV_XLSX = "EXPORT_CSV_XLSX"; // Download exports in CSV/XLSX format
export const GLOBAL_LOCATION_ADMIN = "GLOBAL_LOCATION_ADMIN"; // Administrate all nodes and locations
export const GLOBAL_USER_ADMIN = "GLOBAL_USER_ADMIN"; // Administrate all users permissions and memberships
export const KNMI_DATA = "KNMI_DATA"; // Add KNMI data to water level graphs
export const LOCATION_LOGBOOK = "LOCATION_LOGBOOK"; // View location logbook entries
export const MANAGE_JOBS = "MANAGE_JOBS"; // Manage long-running backend processing jobs
export const MEASUREMENT_DETAILS = "MEASUREMENT_DETAILS"; // View detailed measurements (air/water pressure)
export const MONITOR_JOBS = "MONITOR_JOBS"; // See status of long-running backend processing jobs
export const NODE_HEALTH_ANALYSIS = "NODE_HEALTH_ANALYSIS"; // Detailed analysis of node performance/health statistics
export const REFERENCE_LEVELS = "REFERENCE_LEVELS"; // View reference levels in water level graphs
export const SET_NODE_MEASUREMENT_CONFIGURATION = "SET_NODE_MEASUREMENT_CONFIGURATION"; // Change measurement configuration of the node
export const UPLOAD_FILES = "UPLOAD_FILES"; // Upload files; e.g. attach photos to well details
export const WELL_DETAILS = "WELL_DETAILS"; // View well details (address, soil description, photos, etc)
export const WRITE_ANNOTATIONS = "WRITE_ANNOTATIONS"; // Add annotations to the water level graph
export const WRITE_LOCATION_LOGBOOK = "WRITE_LOCATION_LOGBOOK"; // Add entries to the location logbook
export const SEARCH_LOCATION_BY_NAME = "SEARCH_LOCATION_BY_NAME"; // Search for locations by name
export const INVALIDATED_MEASUREMENT_VALUES = "INVALIDATED_MEASUREMENT_VALUES"; // View invalidated measurements
export const DASHBOARD_LOCATIONS_OVERVIEW = "DASHBOARD_LOCATIONS_OVERVIEW"; // Dashboard feature: View the locations in a list with statistics
export const DASHBOARD_LOCATIONS_COMPARISON = "DASHBOARD_LOCATIONS_COMPARISON"; // Dashboard feature: Compare multiple locations in one graph
export const DASHBOARD_MAP_MULTI_SELECT = "DASHBOARD_MAP_MULTI_SELECT"; // Allows users to select multiple locations on the map. Only really useful with the DASHBOARD_LOCATIONS_COMPARISON permission at the moment.
export const WATER_LEVEL_EMAIL_NOTIFICATIONS = "WATER_LEVEL_EMAIL_NOTIFICATIONS"; // Allows users to receive notifications when certain water levels have been reached
export const IMPERSONATE_USER = "IMPERSONATE_USER"; // Allows a user admin to impersonate other users (with read-only access)
export const EDIT_MANUAL_VALIDATION = "EDIT_MANUAL_VALIDATION"; // Allows a user to mark measurements as valid, invailid or unvalidated
export const VIEW_PUBLIC_BRO_DATA = "VIEW_PUBLIC_BRO_DATA"; // View publicly available BRO data (GMW/GLDs)
export const PUSH_FILES_TO_REMOTE = "PUSH_FILES_TO_REMOTE"; // Push user files to a remote location, e.g. over FTP
export const DASHBOARD_SATELLITE_BASE_LAYER = "DASHBOARD_SATELLITE_BASE_LAYER"; // Switch to the sattelite base layer
export const EDIT_LOCATION_GROUP_DETAILS = "EDIT_LOCATION_GROUP_DETAILS"; // Edit location group details.
export const MANAGE_LOCATION_GROUPS = "MANAGE_LOCATION_GROUPS"; // Manage location groups: add/remove, change membership, edit name/description
export const DASHBOARD_LOCATION_GROUP_DETAILS = "DASHBOARD_LOCATION_GROUP_DETAILS"; // Dashboard feature: View details for a location group.

// Project specific permissions:
export const PROJECT_BLIK20KRIMP001 = "PROJECT_BLIK20KRIMP001"; // Project BLIK.20.KRIMP.001
export const PROJECT_BLIK21BERGE001 = "PROJECT_BLIK21BERGE001"; // Project BLIK.21.BERGE.001
export const PROJECT_BLIK22WPNOO001 = "PROJECT_BLIK22WPNOO001"; // Project BLIK.22.WPNOO.001
