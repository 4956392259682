import {Component, Input, SimpleChange} from '@angular/core';

import * as Highcharts from 'highcharts';
import * as moment from 'moment';


import {Defaults} from "../util/defaults";

Highcharts.setOptions({
  time: {
    // Show dates, axis labels, etc. in local time
    useUTC: false
  }
});

const MIN_ZOOM_RANGE_MS = 1000 * 60 * 60 * 3;

@Component({
  selector: 'app-flowmeter-display',
  templateUrl: './flowmeter-display.component.html',
  styleUrls: ['./flowmeter-display.component.css']
})
export class FlowmeterDisplayComponent {

  @Input() measurementData: JSON;
  @Input() selectionDate;

  highestFlowInM3 = 0;
  highestFlowInMillimeter = 0;
  highestFlowOutM3 = 0;
  highestFlowOutMillimeter = 0;

  chartOptions = {
    chart: {
      backgroundColor: 'transparent',
      type: 'area',
      zoomType: 'x'
    },
    // colors: seriesColours,
    title: {
      text: ""
    },
    tooltip: {
      shared: true,
    },
    xAxis: {
      type: 'datetime',
      // Change formats for the zoomlevels that we want to display differently than the default
      dateTimeLabelFormats: {
        day: Defaults.FORMAT_HIGHCHARTS_MONTHDAY,
        month: Defaults.FORMAT_HIGHCHARTS_MONTHYEAR
      },
      minRange: MIN_ZOOM_RANGE_MS,
      title: {
        text: 'Datum'
      }
    },
    yAxis: {
      labels: {
        format: Defaults.FORMAT_HIGHCHARTS_VALUE
      },
      title: {
        text: ' '
      }
    },
    series: []
  } as Highcharts.Options;

  public chart;

  ngOnInit() {
    this.chart = Highcharts.chart('flowmeterchart', this.chartOptions);
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    if (changes.measurementData || changes.selectionDate) {
      this.displayData();
    }
  }

  displayData() {

    var data = this.measurementData;

    this.clearChart();
    if (!data || Object.keys(data).length === 0) {
      return;
    }

    if (this.chart) {
      const flowDeltasM3 = [];
      const flowDeltasMillimeter = [];
      let previousFlowM3: number;
      let previousFlowMillimeter: number;
      let previousTime: any;

      Object.keys(data).map(key => data[key]).forEach(entry => {
        const time = moment(entry['time'], 'X');
        let flowDeltaM3;
        let flowDeltaMillimeter;
        if (entry['flow_out_m3']) {
          const currentFlowOutM3 = +entry['flow_out_m3'];
          const currentFlowInM3 = +entry['flow_in_m3'];
          const currentFlowOutMillimeter = +entry['flow_out_mm'];
          const currentFlowInMillimeter = +entry['flow_in_mm'];

          const currentTotalFlowM3 = currentFlowInM3 - currentFlowOutM3;
          const currentTotalFlowMillimeter = currentFlowInMillimeter - currentFlowOutMillimeter;
          if (previousFlowM3 === undefined) {
            flowDeltaM3 = 0;
            flowDeltaMillimeter = 0;
          } else {
            const timeDelta = moment.duration(time.diff(previousTime)).asHours();
            flowDeltaM3 = (currentTotalFlowM3 - previousFlowM3) / timeDelta;
            flowDeltaMillimeter = (currentTotalFlowMillimeter - previousFlowMillimeter) / timeDelta;
          }
          previousFlowM3 = currentTotalFlowM3;
          previousFlowMillimeter = currentTotalFlowMillimeter;
          previousTime = time;

          this.highestFlowInM3 = Math.max(currentFlowInM3, this.highestFlowInM3);
          this.highestFlowOutM3 = Math.max(currentFlowOutM3, this.highestFlowOutM3);
          this.highestFlowInMillimeter = Math.max(currentFlowInMillimeter, this.highestFlowInMillimeter);
          this.highestFlowOutMillimeter = Math.max(currentFlowOutMillimeter, this.highestFlowOutMillimeter);
        } else {
          flowDeltaM3 = NaN;
          flowDeltaMillimeter = NaN;
        }

        flowDeltasM3.push([time.valueOf(), flowDeltaM3]);
        flowDeltasMillimeter.push([time.valueOf(), flowDeltaMillimeter]);
      });

      // Pass false as redraw parameter, because we call another update
      this.chart.addSeries({
        type: "line",
        name: 'Flow in/uit (m³/uur)',
        data: flowDeltasM3
      }, false, false);

      this.chart.addSeries({
        type: "line",
        name: 'Flow in/uit (mm/uur)',
        data: flowDeltasMillimeter
      }, false, false);

      this.chart.update({
        xAxis: {
          // Restrict the xAxis to the selected start and endDate,
          // if this is not set it will automatically limit based on available data
          min: moment(this.selectionDate.start).valueOf(),
          max: moment(this.selectionDate.end).valueOf()
        }
      }, true); // finally redraw the chart here

    }
  }

  clearChart() {
    if (this.chart) {
      let series = this.chart.series;
      // Remove series one by one and only redraw if there is one left
      while(series.length > 0) {
        series[0].remove(series.length === 1);
      }
    }
  }

  roundToTwoDecimals(input: number) {
    return (Math.round(input * 100) / 100);
  }
}
