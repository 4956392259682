import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { UserFileService, UserFile, IUserFileDescription } from '../../services/user-file.service';
import { SafeUrl } from '@angular/platform-browser';
import { Lightbox, IAlbum } from 'ngx-lightbox';
import { Observable } from 'rxjs';

const LIGHTBOX_OPTIONS = {
  wrapAround: true,
  showImageNumberLabel: true,
  showZoom: false,
  showRotate: false
};

export interface IUserImageDescription extends IUserFileDescription {
  caption: string;
}

export class UserImage {
  userFile: UserFile;

  constructor(
    public description: IUserImageDescription,
    private userFileService: UserFileService,
    userFile = null
  ) {
    if (userFile != null) {
      this.userFile = userFile;
    } else {
      this.userFile = this.userFileService.getUserFile(description);
    }
  }

  getData(): Observable<Blob> {
    return this.userFile.get();
  }

  getSafeUrl(): Observable<SafeUrl> {
    return this.userFile.getSafeUrl();
  }

  getUrl(): Observable<string> {
    return this.userFile.getUrl();
  }
}

@Component({
  selector: 'user-image',
  styleUrls: ['./user-image-gallery.component.css'],
  templateUrl: './user-image.component.html',
})

export class UserImageComponent {
  _image: UserImage;
  @Input() uglyChangeTriggerHack: any;
  @Input() editable: boolean;
  @Input() notYetUploaded: boolean;
  @Output() imageClick: EventEmitter<any> = new EventEmitter();
  url: any;
  // FIXME this property is not yet updated when the image is uploaded, so 'not yet uploaded' will keep showing until the page is reloaded.
  // Its intent is so that an image that has not yet been uploaded can be marked as 'not uploaded' in the UI (in the template above))
  // Right now, when you add a photo, it is not clear that you have to click the 'save' button on the well details form before it is actually uploaded and added!
  pendingUpload = false;
  pendingDelete = false;

  constructor(
    private lightbox: Lightbox
  ) { }

  ngOnChanges() {
    this.pendingUpload = !this._image.userFile.isOnServer();
  }

  @Input() set image(image: UserImage) {
    this._image = image;
    this.pendingUpload = !this._image.userFile.isOnServer();
    this._image.getSafeUrl().subscribe(
      url => {
        this.url = url;
      },
      error => {
        console.error('Error retrieving user image: ', error);
      }
    )
  }

  _deleteClick() {
    var del = !this._image.userFile.isDeletionPending();
    this._image.userFile.pendDeletion(del);
    this.pendingDelete = del;
  }

  _imageClick() {
    if (this.imageClick.observers.length > 0) {
      this.imageClick.emit();
    } else {
      this.lightbox.open([{
        src: this.url,
        thumb: this.url,
        caption: this._image.description.caption || this._image.description.filename,
        downloadUrl: this.url
      }], 0, LIGHTBOX_OPTIONS);
    }
  }
}

@Component({
  selector: 'user-image-gallery',
  styleUrls: ['./user-image-gallery.component.css'],
  templateUrl: './user-image-gallery.component.html',
})
export class UserImageGalleryComponent implements OnChanges {

  @Input() images: Array<UserImage> = [];
  @Input() editable: boolean = false;
  @Input() uglyChangeTriggerHack: number;
  _album: Array<IAlbum> = [];

  ngOnChanges() {
    if (!this.images) {
      return;
    }
    this._album = this.images.map(img => ({src: null, thumb: null, caption: img.description.caption} as IAlbum));
    this.images.forEach((image, index) => {
        image.getSafeUrl().subscribe(url => {
          this._album[index].src = url as string;
          this._album[index].thumb = url as string;
          this._album[index].downloadUrl = url as string;
      })
    });

  }

  open(i: number) {
    this._lightbox.open(this._album, i, LIGHTBOX_OPTIONS);
  }

  constructor(
    private _lightbox: Lightbox
  ) { }
}
