import { Component, Input, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'app/auth/auth.service';
import { environment } from '../../environments/environment';
import { NotifierService } from 'angular-notifier';
import { LocationService, LocationWithStatistics } from 'app/services/location.service';

@Component({
  selector: 'measurement-interval-manager',
  templateUrl: './measurement-interval-manager.component.html',
  styleUrls: ['./measurement-interval-manager.component.css']
})
export class MeasurementIntervalManagerComponent implements OnInit {

  _location: LocationWithStatistics;
  haveActiveDeployment: boolean = false;

  measurementInterval = 60;

  constructor(
    private auth: AuthService, 
    private http: HttpClient, 
    private notifier: NotifierService,
    private locationService: LocationService,
  ) { }
  
  ngOnInit(): void {
    
  }

  @Input()
  set location(newLocation: LocationWithStatistics) {
    this._location = newLocation;
    this.haveActiveDeployment = this._location && this.locationService.hasActiveDeployment(newLocation);
  }


  /**
   * Set new measurement interval using the config REST API
   */
  onSubmit() {
    const apiUrl = environment.api_base_url + '/locations/' + this._location.id + "/config";

    const newConfig = {
      measurementIntervalMinutes: this.measurementInterval
    };

    this.http.put(apiUrl, newConfig).subscribe(
      data => {
        this.notifier.notify('success', 'Meetinterval gewijzigd.');
      },
      error => {
        if (error.status == 424) {
          this.notifier.notify('error', 'Instellen van meetinterval niet mogelijk; geen node op locatie.');
        } else {
          this.notifier.notify('error', 'Instellen van meetinterval is mislukt.');
        }
        console.log('Error setting measurement interval on URL: ', error);
      }
    );
  }

  

  measurementIntervalOutOfBounds() {
    return (this.measurementInterval < 1 || this.measurementInterval > 1440); // one day
  }
}
