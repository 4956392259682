<div>
    <div class="row" id="location-description">
        <div class="col-xs-12 col-md-9">
            <div class="titleContainer">
              <div class="statusContainer">
                  <location-status [location]="locationData"></location-status>
              </div>
              <h1>{{ locationName }}</h1>
            </div>

            <p [ngClass]="description ? 'description' : 'text-muted'" class="editable">
              {{ description || ""}}
            </p>
        </div>
        <div class="col-xs-12 col-md-3 text-right noprint">
            <button class="btn btn-default m-sm m-l-none" (click)="print()" id="location-detail-print">Print</button>
            <button class="btn btn-default m-sm m-l-none" (click)="startTour()" id="location-detail-tour-start">?</button>
        </div>
    </div>
    <div class="row noprint container-fluid">
        <div class="col-xs-12 col-md-8 form-group location-action-buttons" id="location-action-buttons">
            <a *ngIf="locationComparePermission | async" type="button" class="btn btn-default" (click)="compare()">
                <i class="fa fa-fw fa-{{ compareLocation ? 'check-square' : 'square'}}-o"></i> Vergelijken
            </a>
        </div>
        <div *ngIf="knmiPermission | async" class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 pull-right text-right">
            <div class="knmiIndicatorContainer">
                <blik-loader-small *ngIf="loadingKNMIData"></blik-loader-small>
                <span *ngIf="noKNMIData" class="glyphicon glyphicon-remove noDataIcon"></span>
            </div>
            <div class="knmiSelectorContainer" id="knmi-selector">
                <label for="knmiStationSelector">KNMI-station</label>
                <select id="knmiStationSelector" class="form-control" [(ngModel)]="selectedKNMIStationIndex" (change)="onChangeKNMIStation($event)">
                    <option *ngFor="let k of knmiStations; let i = index" [value]="i">{{k._desc}}</option>
                </select>
            </div>
        </div>
    </div>

    <div class="row" id="waterlevel-display">
        <div class="col-xs-12">
            <app-waterlevel-display
                [locationData]=locationData
                [basicMeasurementData]=basicMeasurementData
                [detailMeasurementData]=null
                [showDetailMeasurementData]=false
                [allowValidation]=false
                [knmiData]=knmiData
                (dateSelection)='dateSelect($event)'
                (measurementsRefreshRequester) = 'refreshMeasurements()'
                [isReadOnly]=true
            >
            </app-waterlevel-display>
        </div>
    </div>

    <div class="row noprint">

    </div>

</div> <!-- End of content div-->
