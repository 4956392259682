import { Component, Input, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import * as moment from 'moment';

import { Defaults } from 'app/util/defaults';
import { CompareService } from "../services/compare-service"
import { LocationService, BasicMeasurementData, LocationWithStatistics } from "../services/location.service";
import { isKNMIPlaceholder, KNMIService, KNMIStationDataEntry, KNMIStationDropdownItem, KNMIStationDropdownPlaceholder } from "../services/knmi-service";
import { WellDetails } from '../services/location.service';
import { NotifierService } from 'angular-notifier';
import { Observable, Subject } from 'rxjs';
import * as introJs from 'intro.js/intro.js';
import { UserService } from 'app/services/user.service';
import { LocalStorageService } from 'app/services/localstorage.service';
import { first, take, takeUntil } from 'rxjs/operators';
import { Position } from "../shared/position";
import { KNMI_DATA } from "../services/permission-definitions";

@Component({
  selector: 'app-bro-location-detail',
  templateUrl: './bro-location-detail.component.html',
  styleUrls: ['bro-location-detail.component.css'],
})

export class BroLocationDetailComponent implements OnDestroy {
  @Input() id: string;

  private ngUnsubscribe = new Subject();

  public locationData: LocationWithStatistics = null;
  public locationName: string;
  public description: string;
  public details: WellDetails;

  public selectionDate = {
    start: moment().subtract(Defaults.TIME_WINDOW_DAYS, 'day'),
    end: moment(),
  };

  // Is user driven
  compareLocation = false;

  // Default permissions - the actual status is requested from the backend.
  public knmiPermission: Observable<boolean>;
  public locationComparePermission: Observable<boolean>;

  public selectedKNMIStationIndex = 0;
  public knmiStations: (KNMIStationDropdownItem | KNMIStationDropdownPlaceholder)[] = [{id: 0, _desc: "Laden..."}];
  public loadingKNMIData = false;
  public noKNMIData = false;

  public basicMeasurementData: BasicMeasurementData | null = null;
  public knmiData: KNMIStationDataEntry[] = [];
  public locationDetails = {};

  // Tutorial framework
  private introJS = introJs();

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private compareService: CompareService,
    private locationService: LocationService,
    private userService: UserService,
    private knmiService: KNMIService,
    private notifier: NotifierService,
    private localStorageService: LocalStorageService
  ) {

    this.knmiPermission = userService.userHasPermission(KNMI_DATA);

    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
      this.id = params['id'];
      //this.deploymentIDs = [];
      this.update();
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  initialStartTour() {
    this.localStorageService.observeUserValue("locationDetailTutorialCompleted").subscribe(completed => {
      if (!completed) {
        this.startTour();
      }
    });
  }

  startTour() {
    this.userService.permissions.pipe(first()).subscribe((permissions) => {
      this.createIntroSteps(permissions);
      this.introJS.start();
      this.localStorageService.saveUserValue("locationDetailTutorialCompleted", "true");
    });
  }

  createIntroSteps(permissions: String[]) {
    let introSteps = [
      {
        intro: "Op deze pagina worden alle gegevens van de meetlocatie getoond."
      },
      {
        element: '#location-description',
        intro: "De naam en algemene beschrijving van de meetlocatie wordt hier weergegeven.",
        location: 'bottom'
      },
      {
        element: '#waterlevel-display',
        intro: "In de grafiek wordt de grondwaterdata getoond."
      },
      {
        element: document.querySelector(".highcharts-button"),
        intro: "Met behulp van het menu in de grafiek kan de grafiek geprint en gedownload worden."
      },
    ]

    introSteps.push(
      {
        element: document.querySelector(".highcharts-navigator"),
        intro: "Hier kan de tijdspanne geselecteerd worden."
      },
      {
        element: '#toggleReferenceLevelButton',
        intro: "Met deze knop kan tussen meetwaarden ten opzichte van NAP of maaiveld geschakeld worden."
      }
    );

    introSteps.push(
      {
        element: '#location-detail-print',
        intro: "Met deze knop kan een overzicht van de meetlocatie met de geselecteerde grafiek geprint worden."
      },
      {
        element: '#location-detail-tour-start',
        intro: "De rondleiding kan op elk moment herstart worden met behulp van de ?-knop."
      },
    );

    this.introJS.setOptions({
      nextLabel: "Volgende",
      prevLabel: "Vorige",
      skipLabel: "Afsluiten",
      doneLabel: "Klaar",
      showStepNumbers: false,
      overlayOpacity: 0.5,
      steps: introSteps
    });
  }

  updateKNMISelector() {
    this.knmiPermission.pipe(take(1)).subscribe(havePermission => {
      if (havePermission && this.locationData) {
        let position = new Position(this.locationData.latitude, this.locationData.longitude);
        this.knmiService.getKNMIStationsSortedByDistanceTo([position]).pipe(take(1)).subscribe(
          (stations) => {
            const entries = stations.map(s => Object.assign({}, s, {
              _desc: s.name + " (" + (s.distance / 1000).toFixed(0) + "km)",
            }));

            this.knmiStations = [{ id: 0 as 0, _desc: "(geen)" }, ...entries];
          },
          error => {
            this.knmiStations = [];
            this.notifier.notify('error', "Kon KNMI-stations niet laden");
            console.error('Error retrieving KNMI Data: ', error);
          }
        );
      }
    });
  }

  update() {

    this.updateKNMISelector();

    // Check if this location is currently being compared
    this.compareLocation = this.compareService.isComparing(this.id);
    this.fetchBasicMeasurementsFromBackend();
    this.fetchLocationDetails();
    this.getKNMIData();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    // setTimeout() is a workaround to defer until after all elements needed for the tour have been rendered.
    // Otherwise, the elements that the tutorial wants to point at cannot be found
    setTimeout(() => this.initialStartTour());
  }

  onChangeKNMIStation(event) {
    this.getKNMIData();
  }

  /**
   * Toggle comparison for this location
   */
  compare() {
    this.compareLocation = !this.compareLocation;
    this.compareService.compare(this.id);
  }

  fetchLocationDetails() {
    this.locationService.getLocationWithStats(this.id)
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe(
        data => {
          this.locationName = data.name;
          this.description = data.description;
          this.details = data.details;
          this.locationData = data;
          this.updateKNMISelector();
        },
        error => {
          console.error('Error retrieving data:', error);
          this.locationData = JSON.parse('{}');
          this.locationName = "Unknown location";
          this.description = "";
        }
      );
  }

  progress: Observable<JSON> = null;
  subscription = null;

  /**
   * Obtain data from the backend based on this.id
   * Updates this.measurementData
   */
  fetchBasicMeasurementsFromBackend() {
    this.userService.permissions.pipe(first()).subscribe({
      next: (permissions) => {
        this.locationService.getMeasurements(
          this.id,
          null, null,
          {
            referenceMeasurements: true,
            waterLevel: true,
            airWaterMeasurements: false,
            flowMeasurements: false,
            invalidated: true,
            onlyValidated: false,
          }
          
        ).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
          data => {
            this.processBasicMeasurementData(data);
          },
          error => {
            this.notifier.notify('error', 'Ophalen van data van deze locatie is mislukt.')
            console.error('Error retrieving data:', error);
          }
        );
      }
    });
  }

  refreshMeasurements() {
    this.fetchBasicMeasurementsFromBackend();
  }

  getKNMIData() {
    const station = this.knmiStations[this.selectedKNMIStationIndex];

    if (isKNMIPlaceholder(station)) {
      this.knmiData = [];
      this.loadingKNMIData = false;
      return;
    }

    if (!this.basicMeasurementData) {
      this.knmiData = [];
      this.loadingKNMIData = false;
      return;
    }

    this.loadingKNMIData = true;
    this.noKNMIData = false;
    this.knmiData = [];
    const timestamps = this.basicMeasurementData.measurements.timestamps;

    this.knmiService.getKNMIDataAtStation(station.id, moment.unix(timestamps[0]), moment.unix(timestamps[timestamps.length-1]))
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        if (!data.length) {
          this.noKNMIData = true;
          this.notifier.notify('warning', "KNMI-station " + station.name + " heeft geen regendata.");
        }
        this.loadingKNMIData = false;
        this.knmiData = data;
      },
      error => {
        this.loadingKNMIData = false;
        this.notifier.notify('error', "Kon KNMI-data niet laden");
        console.error('Error retrieving KNMI Data: ', error);
      }
    );
  }

  processBasicMeasurementData(data: BasicMeasurementData) {
    this.basicMeasurementData = data;
  }

  dateSelect(e: Highcharts.AxisSetExtremesEventObject) {
    if('min' in e && 'max' in e) {
      this.selectionDate = {
        start: moment(e.min / 1000, 'X'),
        end: moment(e.max / 1000, 'X'),
      };
    }
  }

  @HostListener('window:beforeprint')
  printPrep() {
    Array.from(document.querySelectorAll("div > .form-control:placeholder-shown")).forEach(element => element.parentElement.classList.add("noprint"));
  }

  print() {
    window.print()
  }

}
