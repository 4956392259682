import {Component, Input, HostListener, OnDestroy} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

import * as moment from 'moment';
import * as FileSaver from 'file-saver';

import { Defaults } from 'app/util/defaults';
import { CompareService } from "../services/compare-service"
import {
  EXPORT_CSV_XLSX,
  SET_NODE_MEASUREMENT_CONFIGURATION,
  WELL_DETAILS,
  EDIT_WELL_DETAILS,
  MEASUREMENT_DETAILS,
  NODE_HEALTH_ANALYSIS,
  LOCATION_LOGBOOK,
  KNMI_DATA,
  EDIT_LOCATION_DETAILS,
  WRITE_LOCATION_LOGBOOK,
  DASHBOARD_LOCATIONS_COMPARISON,
  REFERENCE_LEVELS,
  WATER_LEVEL_EMAIL_NOTIFICATIONS,
  WRITE_ANNOTATIONS,
  PROJECT_BLIK20KRIMP001,
  PROJECT_BLIK21BERGE001,
  PROJECT_BLIK22WPNOO001,
} from '../services/permission-definitions';
import { LocationService, DetailMeasurementData, BasicMeasurementData, LocationWithStatistics } from "../services/location.service";
import { isKNMIPlaceholder, KNMIService, KNMIStationAnnotated, KNMIStationDataEntry } from "../services/knmi-service";
import { WellDetails } from '../services/location.service';
import { NotifierService } from 'angular-notifier';
import { Observable, Subject } from 'rxjs';
import * as introJs from 'intro.js/intro.js';
import { DeploymentService } from "../services/deployment.service";
import { UserService } from 'app/services/user.service';
import { LocalStorageService } from 'app/services/localstorage.service';
import {first, take, takeUntil} from 'rxjs/operators';
import {Position} from "../shared/position";

/*************************************\
************** TODO *******************

Make the details charts work again:
- Flowmeter
- Air + water temp
- Air + water pressure

They have to get their data separately
now, to make the initial display time
of the main (water level) chart be
as quick as possible.

It would make sense to fetch flow data
at the same time as water level data,
is it is also 'above the fold'.

Air+water temp+pressure should be fetched
in a separate request, and only when these
charts are un-hidden.

Furthermore, the air+water temp+pressure
charts should be migrated to highcharts,
and their selected time spans synced up
with the main chart.
\*************************************/

type KNMIStationDropdownItem = KNMIStationAnnotated & { _desc: String };
type KNMIStationDropdownPlaceholder = { id: 0, _desc: String };

const KNMI_NUM_ENTRIES = 3;

@Component({
  selector: 'app-location-detail',
  templateUrl: './location-detail.component.html',
  styleUrls: ['location-detail.component.css'],
})

export class LocationDetailComponent implements OnDestroy {
  @Input() id: number;

  private ngUnsubscribe = new Subject();

  public locationData: LocationWithStatistics = null;
  public locationName: string;
  public description: string;
  public details: WellDetails;
  locationDebugDetails: [string, string][] = [];
  deploymentIDs: number[] = [];
  deploymentID = 0;

  editingDescription = false;
  // Is determined based on data received
  hasFlowmeter = false;

  public selectionDate = {start: moment().subtract(Defaults.TIME_WINDOW_DAYS, 'day'), end: moment()};

  // Is user driven
  showDetails = false;
  compareLocation = false;

  // Default permissions - the actual status is requested from the backend.
  public csvPermission: Observable<boolean>;
  public configPermission: Observable<boolean>;
  public wellDetailsPermission: Observable<boolean>;
  public editWellDetailsPermission: Observable<boolean>;
  public measurementDetailsPermission: Observable<boolean>;
  public analysisPermission: Observable<boolean>;
  public logPermission: Observable<boolean>;
  public knmiPermission: Observable<boolean>;
  public editLocationDetailsPermission: Observable<boolean>;
  public locationComparePermission: Observable<boolean>;
  public waterLevelEmailNotificationPermission: Observable<boolean>;

  // Project-specific permissions
  public blik20krimp001Permission: Observable<boolean>;
  public blik21berge001Permission: Observable<boolean>;
  public blik22wpnoo001Permission: Observable<boolean>;

  public selectedKNMIStationIndex = 0;
  public knmiStations: (KNMIStationDropdownItem | KNMIStationDropdownPlaceholder)[] = [{id: 0, _desc: "Laden..."}];
  public loadingKNMIData = false;
  public noKNMIData = false;

  public basicMeasurementData: BasicMeasurementData | null = null;
  public detailMeasurementData: DetailMeasurementData | null = null;
  public knmiData: KNMIStationDataEntry[] = [];
  public locationDetails: JSON = JSON.parse('{}');
  public analysisDetails: JSON = JSON.parse('{}');

  // Tutorial framework
  private introJS = introJs();

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private compareService: CompareService,
    private locationService: LocationService,
    private deploymentService: DeploymentService,
    private userService: UserService,
    private knmiService: KNMIService,
    private notifier: NotifierService,
    private localStorageService: LocalStorageService
  ) {

    this.csvPermission = userService.userHasPermission(EXPORT_CSV_XLSX);
    this.configPermission = userService.userHasPermission(SET_NODE_MEASUREMENT_CONFIGURATION);
    this.editLocationDetailsPermission = userService.userHasPermission(EDIT_LOCATION_DETAILS);
    this.wellDetailsPermission = userService.userHasPermission(WELL_DETAILS);
    this.editWellDetailsPermission = userService.userHasPermission(EDIT_WELL_DETAILS);
    this.measurementDetailsPermission = userService.userHasPermission(MEASUREMENT_DETAILS);
    this.analysisPermission = userService.userHasPermission(NODE_HEALTH_ANALYSIS);
    this.logPermission = userService.userHasPermission(LOCATION_LOGBOOK);
    this.knmiPermission = userService.userHasPermission(KNMI_DATA);
    this.blik20krimp001Permission = userService.userHasPermission(PROJECT_BLIK20KRIMP001);
    this.blik21berge001Permission = userService.userHasPermission(PROJECT_BLIK21BERGE001);
    this.blik22wpnoo001Permission = userService.userHasPermission(PROJECT_BLIK22WPNOO001);
    this.locationComparePermission = userService.userHasPermission(DASHBOARD_LOCATIONS_COMPARISON);
    this.waterLevelEmailNotificationPermission = userService.userHasPermission(WATER_LEVEL_EMAIL_NOTIFICATIONS);

    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
      this.id = params['id'];
      //this.deploymentIDs = [];
      this.update();
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  initialStartTour() {
    this.localStorageService.observeUserValue("locationDetailTutorialCompleted").subscribe(completed => {
      if (!completed) {
        this.startTour();
      }
    });
  }

  startTour() {
    this.userService.permissions.pipe(first()).subscribe((permissions) => {
      this.createIntroSteps(permissions);
      this.introJS.start();
      this.localStorageService.saveUserValue("locationDetailTutorialCompleted", "true");
    });
  }

  createIntroSteps(permissions: String[]) {
    let introSteps = [
      {
        intro: "Op deze pagina worden alle gegevens van de meetlocatie getoond."
      },
      {
        element: '#location-description',
        intro: "De naam en algemene beschrijving van de meetlocatie wordt hier weergegeven.",
        location: 'bottom'
      },
      {
        element: '#waterlevel-display',
        intro: "In de grafiek wordt de grondwaterdata getoond."
      },
      {
        element: document.querySelector(".highcharts-button"),
        intro: "Met behulp van het menu in de grafiek kan de grafiek geprint en gedownload worden."
      },
    ]

    if (permissions.indexOf(DASHBOARD_LOCATIONS_COMPARISON) > -1) {
      introSteps.push(
        {
          element: '#location-action-buttons',
          intro: "Hier kan de meetlocatie ook aan de vergelijking toegevoegd worden."
        }
      );
    }

    introSteps.push(
      {
        element: document.querySelector(".highcharts-navigator"),
        intro: "Hier kan de tijdspanne geselecteerd worden."
      },
      {
        element: '#toggleReferenceLevelButton',
        intro: "Met deze knop kan tussen meetwaarden ten opzichte van NAP of maaiveld geschakeld worden."
      }
    );

    if (permissions.indexOf(REFERENCE_LEVELS) > -1) {
      introSteps.push(
        {
          element: '#waterlevel-reference-line-controls',
          intro: "In NAP-weergave kan een referentielijn ingevoerd en getoond worden."
        },
        {
          element: '#waterlevel-reference-line-controls',
          intro: "Voor nieuwe projecten geldt dat deze bepaald worden na het eerste jaar en dan automatisch toegevoegd worden aan de grafieken."
        },
        {
          element: '#waterlevel-reference-line-controls',
          intro: "Ook worden daarin maaiveldniveau, filterstelling, Gemiddeld Hoogste Grondwaterstand (GHG) en Gemiddeld Laagste Grondwaterstand (GLG) getoond indien deze bekend zijn."
        },
      );
    }

    if(permissions.indexOf(KNMI_DATA) > -1) {
      introSteps.push(
        {
          element: '#knmi-selector',
          intro: "Als hier een KNMI-station met regen-data geselecteerd wordt, wordt de regendata in de grafiek getoond. De lijst is gesorteerd op afstand tot de huidige locatie.",
          location: 'bottom',
        }
      )
    }
    if(permissions.indexOf(MEASUREMENT_DETAILS) > -1) {
      introSteps.push(
        {
          element: "#location-action-buttons",
          intro: "Beheerders kunnen indien aanwezig gedetailleerdere metingen waaronder luchtdruk, waterdruk en andere kwaliteitsmetingen aanzetten."
        }
      )
    }
    if(permissions.indexOf(EDIT_LOCATION_DETAILS) > -1) {
      introSteps.push(
        {
        element: '#location-description',
        intro: "Beheerders kunnen door te klikken de naam en beschrijving aanpassen."
        }
      );
    }
    if(permissions.indexOf(EXPORT_CSV_XLSX) > -1) {
      introSteps.push(
        {
          element: '#location-action-buttons',
          intro: "Beheerders kunnen de brondata downloaden in CSV of Excel."
        }
      );
    }
    if(permissions.indexOf(WRITE_ANNOTATIONS) > -1) {
      introSteps.push(
        {
          element: '#waterlevel-display',
          intro: "Door op de grafiek te klikken kan een annotatie toegevoegd worden. Door op de annotatie te klikken kan deze weer verwijderd worden."
        }
      );
    }
    if(permissions.indexOf(WELL_DETAILS) > -1) {
      introSteps.push({
        element: "#well-details",
        intro: "Hier worden alle detail-gegevens van de peilbuis weergegeven. Beheerders kunnen deze ook aanpassen.",
      });

      if (permissions.indexOf(EDIT_WELL_DETAILS) > -1) {
        introSteps.push({
          element: '#well-details-save-btn',
          intro: "Als het toevoegen van data compleet is wordt de nieuwe data met deze knop opgeslagen."
        });
      }

      introSteps.push({
        element: '#well-attachments',
        intro: "Foto's en boorbeschrijving worden getoond. Door op de foto's te klikken worden ze groter getoond. Beheerders kunnen foto's toevoegen of de boorbeschrijving aanpassen."
      });

      if (permissions.indexOf(EDIT_WELL_DETAILS) > -1) {
        introSteps.push({
          element: '#well-attachments-save-btn',
          intro: "Als het toevoegen van foto's compleet is wordt de nieuwe data met deze knop opgeslagen."
        });
      }
    }

    if(permissions.indexOf(SET_NODE_MEASUREMENT_CONFIGURATION) > -1) {
      introSteps.push(
        {
          element: '#measurement-interval-manager',
          intro: "Hier kan een beheerder het meetinterval aanpassen van de meetlocatie."
        }
      );
    }
    if(permissions.indexOf(LOCATION_LOGBOOK) > -1) {
      introSteps.push(
        {
          element: '#location-log',
          intro: "Het logboek wordt gevuld met opmerkingen, onderhouds-informatie en andere relevante zaken."
        }
      )
    }
    if(permissions.indexOf(WRITE_LOCATION_LOGBOOK) > -1) {
      introSteps.push(
        {
          element: '#location-log-input',
          intro: "Hier kan een log-entry toegevoegd worden."
        }
      );
    }
    introSteps.push(
      {
        element: '#location-detail-print',
        intro: "Met deze knop kan een overzicht van de meetlocatie met de geselecteerde grafiek geprint worden."
      },
      {
        element: '#location-detail-tour-start',
        intro: "De rondleiding kan op elk moment herstart worden met behulp van de ?-knop."
      },
    );

    this.introJS.setOptions({
      nextLabel: "Volgende",
      prevLabel: "Vorige",
      skipLabel: "Afsluiten",
      doneLabel: "Klaar",
      showStepNumbers: false,
      overlayOpacity: 0.5,
      steps: introSteps
    });
  }

  updateKNMISelector() {
    this.knmiPermission.pipe(take(1)).subscribe(havePermission => {
      if (havePermission && this.locationData) {
        const position = new Position(this.locationData.latitude, this.locationData.longitude);
        this.knmiService.getKNMIStationsSortedByDistanceTo([position]).pipe(take(1)).subscribe(
          (stations) => {
            const entries = stations.map(s => Object.assign({}, s, {
              _desc: s.name + " (" + (s.distance / 1000).toFixed(0) + "km)",
            })).slice(0, KNMI_NUM_ENTRIES);

            this.knmiStations = [{ id: 0 as 0, _desc: "(geen)" }, ...entries];
          },
          error => {
            this.knmiStations = [];
            this.notifier.notify('error', "Kon KNMI-stations niet laden");
            console.error('Error retrieving KNMI Data: ', error);
          }
        );
      }
    });
  }

  update() {

    this.updateKNMISelector();

    // Check if this location is currently being compared
    this.compareLocation = this.compareService.isComparing(this.id);
    this.fetchBasicMeasurementsFromBackend();
    this.fetchLocationDetails();
    this.getKNMIData();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    // setTimeout() is a workaround to defer until after all elements needed for the tour have been rendered.
    // Otherwise, the elements that the tutorial wants to point at cannot be found
    setTimeout(() => this.initialStartTour());
  }

  onChangeKNMIStation(event) {
    this.getKNMIData();
  }

  /**
   * Toggle comparison for this location
   */
  compare() {
    this.compareLocation = !this.compareLocation;
    this.compareService.compare(this.id);
  }

  filenameSafeName() {
    return this.locationName.replace(/[^a-z0-9-]/gi, '_');
  }

  public downloadCsv() {
    this.userService.permissions.pipe(first()).subscribe({
      next: (permissions) => {
        let includeReferences = permissions.indexOf(REFERENCE_LEVELS) > -1;
        let includeAirWater = permissions.indexOf(MEASUREMENT_DETAILS) > -1;

        this.locationService.getCSV(this.id, this.selectionDate.start, this.selectionDate.end, true, includeReferences, includeAirWater)
          .subscribe(
            data => {
              const start = this.selectionDate.start.format('YYYY-MM-DD');
              const end = this.selectionDate.end.format('YYYY-MM-DD');
              const fileName = `blik_location_${this.id}_${this.filenameSafeName()}_from_${start}_to_${end}_.csv`;
              FileSaver.saveAs(new Blob([data], {type: 'text/csv'}), fileName);
            },
            error => {
              this.notifier.notify('error', 'Fout bij downloaden van CSV.');
              console.error('Error retrieving CSV: ', error);
            }
          );
      }
    });
  }

  public downloadXlsx() {
    this.userService.permissions.pipe(first()).subscribe({
      next: (permissions) => {
        let includeReferences = permissions.indexOf(REFERENCE_LEVELS) > -1;
        let includeAirWater = permissions.indexOf(MEASUREMENT_DETAILS) > -1;

        this.locationService.getXLSX(this.id, this.selectionDate.start, this.selectionDate.end, true, includeReferences, includeAirWater)
          .subscribe(
            data => {
              const start = this.selectionDate.start.format('YYYY-MM-DD');
              const end = this.selectionDate.end.format('YYYY-MM-DD');
              const fileName = `blik_location_${this.id}_${this.filenameSafeName()}_from_${start}_to_${end}_.xlsx`;
              FileSaver.saveAs(new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}), fileName);
            },
            error => {
              this.notifier.notify('error', 'Fout bij downloaden van XLSX.');
              console.error('Error retrieving XLSX: ', error);
            }
          );
      }
    });
  }

  editDescription() {
    this.editLocationDetailsPermission.pipe(take(1)).subscribe(d => {
      if(d) {
        this.editingDescription = true;
      }
    });
  }

  updateDescription() {
    if (this.description) {
      this.description = this.description.trim()
      this.locationService.updateDescription(this.id, this.description).subscribe(
        data => {
          this.notifier.notify('success', 'Beschrijving gewijzigd.');
        },
        error => {
          this.notifier.notify('error', 'Wijzigen van beschrijving is mislukt.');
        }
      );
    }
    this.editingDescription = false;
  }

  fetchLocationDetails() {
    this.locationService.getLocationWithStats(this.id)
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe(
        data => {
          this.locationName = data.name;
          this.description = data.description;
          this.details = data.details;
          this.locationData = data;

          const inFuture = moment(data.batteryDeadEstimate).isAfter(moment.now());
          this.locationDebugDetails = [
            ["Firmware", String(data.firmware || "unknown")],
            ["Last message", data.lastMeasurementAt ? moment(data.lastMessageReceivedAt).format('YYYY-MM-DD HH:mm') : "—"],
            ["Battery dead estimate", data.batteryDeadEstimate ? moment(data.batteryDeadEstimate).format('YYYY-MM-DD HH:mm') : "unknown"],
            ["Battery time remaining", data.batteryDeadEstimate ? moment(data.batteryDeadEstimate).fromNow(inFuture) : "unknown"]
          ];

          if (this.locationData.deployments) {
            this.deploymentIDs = this.locationData.deployments.map(d => d.id);
          }
          this.updateKNMISelector();
        },
        error => {
          console.error('Error retrieving data:', error);
          this.locationData = JSON.parse('{}');
          this.deploymentIDs = [];
          this.locationName = "Unknown location";
          this.description = "";
        }
      );
  }

  updateLocationName(newName: string) {
    if (newName !== this.locationName) {
      this.locationName = newName.trim();
      this.locationService.updateName(this.id, this.locationName).subscribe(
        data => {
          this.notifier.notify('success', 'Naam gewijzigd.');
        },
        error => {
          this.notifier.notify('error', 'Wijzigen van naam is mislukt.');
        }
      );
    }
  }

  progress: Observable<JSON> = null;
  subscription = null;

  /**
   * Obtain data from the backend based on this.id
   * Updates this.measurementData
   */
  fetchBasicMeasurementsFromBackend() {
    this.userService.permissions.pipe(first()).subscribe({
      next: (permissions) => {
        this.locationService.getMeasurements(
          this.id,
          null,
          null,
          {
            referenceMeasurements: permissions.includes(REFERENCE_LEVELS),
            waterLevel: true,
            airWaterMeasurements: false,
            flowMeasurements: false,
            invalidated: true,
            onlyValidated: false,
          },
        ).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
          data => this.processBasicMeasurementData(data),
          error => {
            this.notifier.notify('error', 'Ophalen van data van deze locatie is mislukt.')
            console.error('Error retrieving data:', error);
          }
        );
      }
    });
  }

  refreshMeasurements() {
    this.fetchBasicMeasurementsFromBackend();
  }

  /**
   * Obtain data from the backend based on this.id
   * Updates this.measurementData
   */
  fetchDetailMeasurementsFromBackend() {
    // TODO also enable flow measurements, but they seem very slow in the backend for some reason
    this.userService.permissions.pipe(first()).subscribe({
      next: (permissions) => {
        this.locationService.getMeasurements(
          this.id,
          null,
          null,
          {
            referenceMeasurements: permissions.includes(REFERENCE_LEVELS),
            waterLevel: false,
            airWaterMeasurements: permissions.includes(MEASUREMENT_DETAILS),
            flowMeasurements: false,
            invalidated: true, 
            onlyValidated: false,
          }
        ).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
          data => this.processDetailMeasurementData(data),
          error => {
            this.notifier.notify('error', 'Ophalen van data van deze locatie is mislukt.')
            console.error('Error retrieving data:', error);
          }
        );
      }
    });
  }

  getKNMIData() {
    const station = this.knmiStations[this.selectedKNMIStationIndex];

    if (isKNMIPlaceholder(station)) {
      this.knmiData = [];
      this.loadingKNMIData = false;
      return;
    }

    if (!this.basicMeasurementData) {
      this.knmiData = [];
      this.loadingKNMIData = false;
      return;
    }

    this.loadingKNMIData = true;
    this.noKNMIData = false;
    this.knmiData = [];
    const timestamps = this.basicMeasurementData.measurements.timestamps;
    
    this.knmiService.getKNMIDataAtStation(station.id, moment.unix(timestamps[0]), moment.unix(timestamps[timestamps.length-1]))
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        if (!data.length) {
          this.noKNMIData = true;
          this.notifier.notify('warning', "KNMI-station " + station.name + " heeft geen regendata.");
        }
        this.loadingKNMIData = false;
        this.knmiData = data;
      },
      error => {
        this.loadingKNMIData = false;
        this.notifier.notify('error', "Kon KNMI-data niet laden");
        console.error('Error retrieving KNMI Data: ', error);
      }
    );
  }

  processBasicMeasurementData(data: BasicMeasurementData) {
    this.basicMeasurementData = data;
    if (data) {
      this.hasFlowmeter = 'flowOut_mm3' in data.measurements;
    }
  }

  processDetailMeasurementData(data: DetailMeasurementData) {
    this.detailMeasurementData = data;
  }

  toggleShowDetails() {
    this.showDetails = !this.showDetails;
    if (this.showDetails) {
      this.fetchDetailMeasurementsFromBackend();
    }
  }

  dateSelect(e: Highcharts.AxisSetExtremesEventObject) {
    if('min' in e && 'max' in e) {
      this.selectionDate = {
        start: moment(e.min / 1000, 'X'),
        end: moment(e.max / 1000, 'X'),
      };
    }
  }

  onSelectDeployment(id) {
    this.deploymentID = id;
  }

  @HostListener('window:beforeprint')
  printPrep() {
    Array.from(document.querySelectorAll("div > .form-control:placeholder-shown")).forEach(element => element.parentElement.classList.add("noprint"));
  }

  print() {
    window.print()
  }

}
