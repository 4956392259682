<ng-container *ngIf="(group$ | async) && (locations$ | async); else loading">
    <ng-container *ngIf="!(group$ | async).unknown; else unknown">
        <div id="location-group-description">
            <div class="row">
                <div class="col-xs-12 col-md-9">
                    <div class="titleContainer">
                        <h1 *ngIf="(managePermission$ | async); else readOnlyName" class="editable" spellcheck="false" contenteditable [appContenteditable]="(group$ | async).name" [innerHtml]="(group$ | async).name" (onChange)="updateName($event)"></h1>
                    </div>
                </div>
                <div class="col-xs-12 col-md-3">
                    <div class="pull-right text-right">
                        <button class="btn btn-default m-sm m-l-none" (click)="startTour()">?</button>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="(managePermission$ | async); else readOnlyDescription">
                <p *ngIf="!editingDescription" [ngClass]="description ? 'description' : 'text-muted'" (click)="editDescription()" class="editable">{{ description || "Klik hier om een beschrijving toe te voegen."}}</p>
            </ng-container>
            <textarea *ngIf="(managePermission$ | async) && editingDescription" [(ngModel)]="description" appAutofocus class="form-control description-input" (blur)="updateDescription($event.target.value)"></textarea>
        </div>

        <div id="tutorial-interpretation">
            <h2 id="interpretation-label">Interpretaties</h2>
            <div class="form-group">
                <textarea aria-labelledby="interpretation-label" id="interpretation" appAutoHeight class="form-control form-control--lines--5" [(ngModel)]="interpretation" [readonly]="!(editPermission$ | async)"></textarea>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <button class="btn" [class.btn-default] = "interpretation === (group$ | async).interpretation" [class.btn-primary]="interpretation !== (group$ | async).interpretation" (click)="updateInterpretation()" [disabled]="interpretation === (group$ | async).interpretation">
                        <i class="fa fa-save" aria-hidden="true"></i>
                        Opslaan
                    </button>
                </div>
            </div>
        </div>

        <div id="tutorial-files">
            <h2>Bestanden</h2>
            <table class="table" *ngIf="((files$ | async) || []).length || (toBeUploaded.length)">
                <colgroup>
                    <col/>
                    <col/>
                    <col/>
                    <col/>
                    <col *ngIf="(editPermission$ | async) && (anyDeletableFiles$ | async)" style="width: 1%;" />
                </colgroup>
                <thead>
                    <tr>
                        <th>Bestandsnaam</th>
                        <th>Grootte</th>
                        <th>Toegevoegd op</th>
                        <th>
                            <span class="hidden-xs hidden-s">Toegevoegd door</span>
                            <span class="visible-xs visible-s">Door</span>
                        </th>
                        <th *ngIf="(editPermission$ | async) && (anyDeletableFiles$ | async)">Acties</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let file of ((files$ | async) || [])">
                        <th>
                            <a (click)="download(file)">
                                <i [class]="'fa fa-fw ' + file.icon" aria-hidden="true"></i>
                                {{ file.filename }}
                            </a>
                        </th>
                        <td>
                            {{ file.fileSize }}
                        </td>
                        <td>
                            {{ file.creationTime }}
                        </td>
                        <td>
                            {{ file.createdBy }}
                        </td>
                        <td *ngIf="(editPermission$ | async) && (anyDeletableFiles$ | async)">
                            <button (click)="deleteFile(file)" class="btn btn-default" [disabled]="!file.canDelete" title="Verwijderen">
                                <i class="fa fa-trash-o" aria-hidden="true"></i>
                                <span class="sr-only">Verwijderen</span>
                            </button>
                        </td>
                    </tr>
                    <tr *ngFor="let file of toBeUploaded; index as i">
                        <th>
                            <i class="fa fa-fw" [class.fa-spinner]="i === 0" [class.fa-spin]="i === 0" [class.fa-clock-o]="i !== 0" aria-hidden="true"></i>
                            {{ file.description.filename }}
                        </th>
                        <td>
                            {{ file.fileSize }}
                        </td>
                        <td>
                            —
                        </td>
                        <td>
                            —
                        </td>
                        <td *ngIf="(editPermission$ | async) && ((anyDeletableFiles$ | async) || (files$ | async).length === 0)">
                            <button class="btn btn-default" disabled title="Verwijderen">
                                <i class="fa fa-trash-o" aria-hidden="true"></i>
                                <span class="sr-only">Verwijderen</span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="alert alert-info" *ngIf="!((files$ | async) || []).length && !(toBeUploaded.length)">
                Er zijn geen bestanden toegevoegd.
            </div>

            <form *ngIf="uploadPermission$ | async">
                <h3>Bestand toevoegen</h3>
                <input class="hidden" #uploadInput (change)="fileChange($event)" type="file" multiple />

                <div class="form-group">
                    <button (click)="uploadInput.click()" class="btn btn-default" type="submit">
                        <i class="fa fa-upload" aria-hidden="true"></i>
                        Toevoegen
                    </button>
                </div>
            </form>
        </div>

        <div>
            <h2 id="tutorial-locations">Locaties</h2>

            <ul class="locations">
                <li *ngFor="let location of (locations$ | async)">
                    <a [routerLink]="['/location-detail/', location.id]">
                        {{ location.name || location.id }}
                    </a>
                </li>
            </ul>
        </div>
    </ng-container>
</ng-container>

<ng-template #loading>
    <div class="h2">Loading…</div>
</ng-template>

<ng-template #unknown>
    <h1>Onbekende locatiegroep</h1>
    <div class='alert alert-danger'>
        Kon locatiegroep nummer {{ id }} niet vinden.<br/>
        Misschien bestaat deze groep niet, of heeft u er geen rechten toe?
    </div>
</ng-template>

<ng-template #readOnlyDescription><p class="description">{{ description || ""}}</p></ng-template>

<ng-template #readOnlyName><h1>{{ (group$ | async).name }}</h1></ng-template>
