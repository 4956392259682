import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import * as introJs from 'intro.js';
import * as moment from 'moment';
import { combineLatest, Observable, Subject } from 'rxjs';
import { first, map, take, takeUntil } from 'rxjs/operators';

import { AuthService } from 'app/auth/auth.service';
import { DASHBOARD_LOCATION_GROUP_DETAILS, EDIT_LOCATION_GROUP_DETAILS, MANAGE_LOCATION_GROUPS, UPLOAD_FILES } from 'app/services/permission-definitions';
import { BlikLocation, LocationService } from 'app/services/location.service';
import { BlikLocationGroupDetails, LocationGroupService } from 'app/services/location-group.service';
import { LocalStorageService } from 'app/services/localstorage.service';
import { UserService } from 'app/services/user.service';
import { formatBytes, IUserFileDescription, UserFile, UserFileService } from 'app/services/user-file.service';

import { getIcon } from './file-helpers';
import { environment } from "../../environments/environment";

const collator = new Intl.Collator('nl-NL', {
  ignorePunctuation: true,
  numeric: true,
  usage: 'sort',
});

interface UnknownLocationGroup {
  unknown: true;
}

interface File extends IUserFileDescription {
  icon: string;
  fileSize: string;
  creationTime: string;
  createdBy: string;
}

@Component({
  selector: 'app-location-group-detail',
  templateUrl: './location-group-detail.component.html',
  styleUrls: ['./location-group-detail.component.css'],
})
export class LocationGroupDetailComponent implements OnInit, OnDestroy {
  @Input() id: number;
  private ngUnsubscribe = new Subject();
  public group$: Observable<BlikLocationGroupDetails | UnknownLocationGroup>;
  public allLocations$: Observable<BlikLocation[]>;
  public locations$: Observable<BlikLocation[]>;
  public files$: Observable<File[]>;
  public anyDeletableFiles$: Observable<boolean>;
  public editPermission$: Observable<boolean>;
  public managePermission$: Observable<boolean>;
  public uploadPermission$: Observable<boolean>;
  public editingDescription: boolean = false;
  public description: string = '';
  public interpretation: string = '';
  public toBeUploaded: UserFile[] = [];
  private uploading: boolean = false;
  private introJS = introJs();

  constructor(
    public auth: AuthService,
    private route: ActivatedRoute,
    private notifier: NotifierService,
    private locationGroupService: LocationGroupService,
    private locationService: LocationService,
    private localStorageService: LocalStorageService,
    private userFileService: UserFileService,
    userService: UserService,
  ) {
    this.editPermission$ = userService.userHasPermission(EDIT_LOCATION_GROUP_DETAILS);
    this.managePermission$ = userService.userHasPermission(MANAGE_LOCATION_GROUPS);
    this.uploadPermission$ = userService.userHasPermission(UPLOAD_FILES);

    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
      this.id = params['id'];
      this.update();
    });

    this.files$ = combineLatest([this.group$, userService.users])
      .pipe(map(([group, users]) => {
        if ('unknown' in group) {
          return [];
        }

        return group.files
          .map<File>(f => {
            const user = users[f.creatorId];

            return {
              id: f.id,
              url: `${environment.api_base_url}/userfiles/${f.id}`,
              filename: f.filename,
              length: f.length,
              fileSize: formatBytes(f.length),
              icon: getIcon(f.mimeType),
              creationTime: moment(f.creationTime).format('YYYY-MM-DD HH:mm'),
              createdBy: user ? user.name : '(onbekend)',
              canDelete: f.canDelete,
            };
          })
          .sort();
      }));

      this.anyDeletableFiles$ = this.files$
        .pipe(map((files) => files.some(f => f.canDelete)));
  }

  ngOnInit() {
    const interval = setInterval(() => {
      if (window.document.getElementById('location-group-description')) {
        clearInterval(interval);
        this.initialStartTour();
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  update() {
    this.fetchDetails();
    this.fetchLocations();
  }

  fetchDetails() {
    this.group$ = this.locationGroupService.getDetails(this.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(map((g => g ? g : { unknown: true })));

    this.group$.subscribe((group) => {
      if ('unknown' in group) {
        return;
      }

      this.description = group.description || '';
      this.interpretation = group.interpretation || '';
    });
  }

  fetchLocations() {
    this.allLocations$ = this.locationService.getLocations()
      .pipe(takeUntil(this.ngUnsubscribe));

    this.locations$ = combineLatest([this.group$, this.allLocations$])
      .pipe(map(([group, allLocations]) => {
        if ('unknown' in group) {
          return [];
        }

        return allLocations
          .filter(l => group.locationIds.includes(l.id))
          .sort((a, b) => collator.compare(a.name, b.name))
      }));
  }

  updateName(newName: string) {
    this.locationGroupService.update(this.id, { name: newName.trim() }).subscribe(
        _ => {
          this.notifier.notify('success', 'Naam gewijzigd.');
        },
        error => {
          console.error(error);
          this.notifier.notify('error', 'Wijzigen van naam is mislukt.');
        }
      );
  }

  editDescription() {
    this.editPermission$
      .pipe(take(1))
      .subscribe(d => {
        if(d) {
          this.editingDescription = true;
        }
      });
  }

  updateDescription(newDescription: string) {
    this.locationGroupService.update(this.id, { description: newDescription }).subscribe(
      _ => {
        this.notifier.notify('success', 'Beschrijving gewijzigd.');
      },
      error => {
        console.error(error);
        this.notifier.notify('error', 'Wijzigen van beschrijving is mislukt.');
      }
    );

    this.editingDescription = false;
  }

  updateInterpretation() {
    this.locationGroupService.update(this.id, { interpretation: this.interpretation }).subscribe(
      _ => {
        this.notifier.notify('success', 'Interpretaties gewijzigd.');
      },
      error => {
        console.error(error);
        this.notifier.notify('error', 'Wijzigen van interpretaties is mislukt.');
      }
    );
  }

  download(file: File) {
    this.userFileService.downloadFile(file);
  }

  deleteFile(file: File) {
    this.group$.pipe(first()).subscribe((group) => {
      if (!window.confirm(`Weet u zeker dat u het bestand "${file.filename}" wil verwijderen?`)) {
        return;
      }

      this.userFileService.deleteFile(file).subscribe(
        _ => {
          this.notifier.notify('success', `"${file.filename}" verwijderd.`);
          this.locationGroupService.refresh(this.id);
        },
        error => {
          console.error(error);
          this.notifier.notify('error', `Verwijderen van bestand "${file.filename}" is mislukt.`);
        }
      );
    });
  }

  uploadNext() {
    if (this.uploading) {
      return;
    }

    if (!this.toBeUploaded.length) {
      return;
    }

    this.uploading = true;

    const upload = this.toBeUploaded[0];
    this.userFileService.uploadUserFile(upload).subscribe({
      next: () => {
        this.notifier.notify('success', `"${upload.description.filename}" is geüpload.`);
        this.locationGroupService.refresh(this.id);
        this.toBeUploaded.shift();
        this.uploading = false;
        this.uploadNext();
      },
      error: () => {
        this.notifier.notify('error', `"Het uploaden van ${upload.description.filename}" is mislukt.`);
        this.toBeUploaded.shift();
        this.uploading = false;
        this.uploadNext();
      },
    });
  }

  fileChange(event: Event) {
    const files = (event.target as HTMLInputElement).files;

    if (!files || !files.length) {
      return;
    }

    const uf = Array.from(files).map(file => this.userFileService.createLocalFile(file, {
      locationGroupId: this.id,
      locationReadPermission: DASHBOARD_LOCATION_GROUP_DETAILS,
    }));

    this.toBeUploaded.push(...uf);
    this.uploadNext();
  }

  initialStartTour() {
    this.localStorageService.observeUserValue("locationGroupDetailTutorialCompleted")
      .subscribe(completed => {
        if (!completed) {
          this.startTour();
        }
      });
  }

  startTour() {
    this.editPermission$.subscribe((editPermission) => {
      this.createIntroSteps(editPermission);
      this.introJS.start();
      this.localStorageService.saveUserValue("locationGroupDetailTutorialCompleted", "true");
    });
  }

  createIntroSteps(editPermission: boolean) {
    const introSteps: introJs.Step[] = [
      {
        intro: "Op deze pagina worden alle gegevens van deze locatiegroep getoond."
      },
      {
        element: "#location-group-description",
        intro: "De naam en algemene beschrijving van deze locatiegroep wordt hier weergegeven.",
        position: "bottom",
      },
      {
        element: "#tutorial-interpretation",
        intro: editPermission
          ? "Interpretaties kunnen hier bijgehouden worden."
          : "Hier staan interpretaties voor deze locatiegroep.",
      },
      {
        element: '#tutorial-files',
        intro: "Hier kunnen rapporten en andere bestanden relevant voor deze locatiegroep beheerd worden."
      },
      {
        element: "#tutorial-locations",
        intro: "Dit zijn alle peilbuizen in deze locatiegroep.",
      },
    ];

    this.introJS.setOptions({
      nextLabel: "Volgende",
      prevLabel: "Vorige",
      skipLabel: "Afsluiten",
      doneLabel: "Klaar",
      showStepNumbers: false,
      overlayOpacity: 0.5,
      steps: introSteps,
    });
  }
}
