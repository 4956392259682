<ng-template #noAccount>
  <p>
    U bent nog niet uitgenodigd om een meetnet in te zien; neem contact op met uw meetnetbeheerder of <a href="mailto:info@blik-sensing.nl">info@blik-sensing.nl</a> als u denkt dat dit niet klopt.
  </p>
</ng-template>

<div>
  <h2>
    Welkom op het Blik Sensing Water Dashboard.
  </h2>
  <p *ngIf="!auth.authenticated; else noAccount">
    <a (click)="auth.login()">Log in</a> om uw nodes te zien.
  </p>
</div>
