import { Directive, OnInit, ElementRef } from '@angular/core';

/**
 * This directive auto-grows the input when a key is released.
 */
@Directive({
  selector: '[appAutoHeight]',
  host: {
    '(keyup)': "autogrow()"
  }
})
export class AutoHeightDirective implements OnInit {

  constructor(private elementRef: ElementRef) { };

  ngOnInit(): void {
    setTimeout(() => {
      const textArea = this.elementRef.nativeElement;
      textArea.style.height = '0px';
      textArea.style.height = textArea.scrollHeight + "px";
    });
  }

  autogrow(){
    const textArea = this.elementRef.nativeElement;
    textArea.style.height = '0px';
    textArea.style.height = textArea.scrollHeight + 'px';
  }
}
