
import {startWith} from 'rxjs/operators';
import {Subject, Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {LocationID} from "./location.service";

@Injectable()
export class CompareService {

  private STORAGE_ID_COMPARELOCATIONS = "compare";

  private compareSub = new Subject<LocationID[]>();

  /**
   * Fetches the current compared locations from sessionStorage.
   * @returns {[number]} Array of location ids
   */
  getComparingLocations(): LocationID[] {
    let storedLocations = sessionStorage.getItem(this.STORAGE_ID_COMPARELOCATIONS);
    if (storedLocations) {
      return JSON.parse(storedLocations);
    }
    return [];
  }

  /**
   * Returns an Observable for the locations stored in session storage.
   * @returns {Observable<number[]>}
   */
  watchStorage(): Observable<LocationID[]> {
    const compareLocations = this.getComparingLocations();
    // startWith() will push compareLocations to the subscriber when he first subscribes (as initial value)
    return this.compareSub.asObservable().pipe(startWith(compareLocations));
  }


  /**
   * Check if the given location id is being compared.
   * @param {number} id Location id
   * @returns {boolean} true if location id is being compared.
   */
  isComparing(id: LocationID): boolean {
    return this.getComparingLocations().indexOf(id) != -1;
  }

  /**
   * Toggle comparison of this location.
   * @param {number} id Location id
   */
  compare(id: LocationID): void {
    let compareLocations = this.getComparingLocations();
    // Push into array if it does not exist or remove it if does exist.
    if (compareLocations.indexOf(id) == -1) {
      compareLocations.push(id);
    } else {
      compareLocations = compareLocations.filter(l => l != id);
    }
    this.storeState(compareLocations);
  }

  /**
   * Add the location id to comparisons if it is not yet being compared.
   * @param {number} id Location id
   */
  add(id: LocationID): void {
    const compareLocations = this.getComparingLocations();
    if (compareLocations.indexOf(id) === -1) {
      compareLocations.push(id);
    }
    this.storeState(compareLocations);
  }

  /**
   * Removes the given location from comparison.
   * @param {number} id Location id
   */
  remove(id: LocationID): void {
    const compareLocations = this.getComparingLocations().filter(l => l != id);
    this.storeState(compareLocations);
  }

  /**
   * Clears the location comparisons.
   */
  clear(): void {
    this.storeState([]);
  }

  set(locations: LocationID[]): void {
    const uniqueLocations = Array.from(new Set(locations));
    this.storeState(uniqueLocations);
  }

  private storeState(compareLocations: LocationID[]): void {
    sessionStorage.setItem(this.STORAGE_ID_COMPARELOCATIONS, JSON.stringify(compareLocations));
    this.compareSub.next(compareLocations);
  }
}
