<h1>Analysis</h1>
<div class="deployment-detail-container">
    <div id="analysischart" label="analysischart"></div>
    <div *ngIf="!analysisDetails || progress" class="loadercontainerspanner">
        <div class="loadercontainer">
            <div class="loader outer" style="margin:auto;">
            <div class="loader middle" style="margin:auto;">
                <div class="loader inner" style="margin:auto;">
                </div>
            </div>
            </div>
        </div>
        <div class="loaderprogressindicator" *ngIf="progress">
            {{progress}}
        </div>
    </div>
</div>

<h1>Log</h1>
<div id="eventlist" *ngIf="analysisDetails">
    <table>
        <tr>
            <th>Time</th>
            <th>Event</th>
        </tr>
        <tr *ngFor="let e of analysisDetails.events; let i = index">
            <td>{{e.fromTime*1000 | date:'yyyy-MM-dd HH:mm'}}</td>
            <td>{{e.description}}</td>
        </tr>
    </table>
</div>
