<ng-template #loadingrow>
    <blik-loader-small></blik-loader-small>
</ng-template>

<h1>Logboek<i class="togglebutton {{showLog?'fa fa-chevron-down':'fa fa-chevron-up'}}" (click)="toggleOpen()"></i></h1>
<div id="location-log" *ngIf="showLog" >
    <table class="table table-hover">
        <thead>
            <tr>
                <th></th>
                <th class="column-type">Type</th>
                <th class="column-time">Tijd</th>
                <th class="column-user">Wie</th>
                <th class="column-content">Inhoud</th>
                <!-- <th *ngFor="let c of columns; let i = index">{{c.header}}</th> -->
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let entry of logData; let i = index">
                <td>
                    <ng-container *ngIf="entry.type == 'user'">
                        <img *ngIf="!(entry.submitting == true); else loadingrow" (click)="delLogEntry(i)" class="delbutton" src="assets/img/feather/trash-2.svg">
                    </ng-container>
                </td>
                <td class="column-type">
                    <location-log-author-type type={{entry.type}}></location-log-author-type>
                </td>
                <td class="column-time">{{entry.time_readable}}</td>
                <td class="column-user">{{entry.author?.name}}</td>
                <td class="column-content">{{entry.content}}</td>
                <!-- <ng-container *ngFor="let c of columns; let j = index">
          <td>{{logData[i][c.parameter]}}</td>
        </ng-container> -->
            </tr>
            <tr *ngIf="logData != null && logData.length == 0">
                <td span=5>Logboek is nog leeg</td>
            </tr>
        </tbody>
    </table>
    <ng-container *ngIf="writeLocationLogbookPermission | async">
        <img (click)="addLogEntry()" class="addbutton noprint" src="assets/img/feather/plus-square.svg">
        <input type="text" class="noprint" [(ngModel)]="newEntry" placeholder="Nieuwe log.." id="location-log-input" (keyup.enter)="addLogEntry()">
    </ng-container>
</div>
