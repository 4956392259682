import { Directive, OnInit, ElementRef } from '@angular/core';

/**
 * This directive helps setting autofocus for conditional elements.
 * It also auto-grows the input when a key is released.
 * Using native HTML5 autofocus attribute does not work in combination with *ngIf.
 */
@Directive({
  selector: '[appAutofocus]',
  host: {
    '(keyup)': "autogrow()"
  }
})
export class AutofocusDirective implements OnInit {

  constructor(private elementRef: ElementRef) { };

  ngOnInit(): void {
    this.elementRef.nativeElement.focus();

    setTimeout(() => {
      let textArea = this.elementRef.nativeElement;
      textArea.style.height = '0px';
      textArea.style.height = textArea.scrollHeight + "px";
    });
  }

  autogrow(){
    let textArea = this.elementRef.nativeElement;
    // textArea.style.overflow = 'hidden';
    textArea.style.height = '0px';
    textArea.style.height = textArea.scrollHeight + 'px';
  }
}
