export const getIcon = (mimeType: string): string => {
    const [type, subtype] = mimeType.split('/');

    switch (type) {
        case 'image':
            return 'fa-file-image-o';

        case 'audio':
            return 'fa-file-audio-o';

        case 'text':
            return getTextIcon(subtype);

        case 'video':
            return 'fa-file-video-o';

        case 'application': {
            return getApplicationIcon(subtype);
        }

        default:
            return 'fa-file-o';
    }

};

const getTextIcon = (subtype: string): string => {
    switch (subtype) {
        case 'csv':
            return 'fa-file-excel-o';

        case 'json':
        case 'ld+json':
        case 'xml':
            return 'fa-file-code-o';

        default:
            return 'fa-file-text-o';
    }

}

const getApplicationIcon = (subtype: string): string => {
    switch (subtype) {
        case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'vnd.oasis.opendocument.text':
        case 'msword':
        case 'rtf':
            return 'fa-file-word-o';

        case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'vnd.oasis.opendocument.spreadsheet':
        case 'vnd.ms-excel':
        case 'csv':
            return 'fa-file-excel-o';

        case 'vnd.openxmlformats-officedocument.presentationml.presentation':
        case 'vnd.oasis.opendocument.presentation':
        case 'vnd.ms-powerpoint':
            return 'fa-file-powerpoint-o';

        case 'zip':
            return 'fa-file-archive-o';

        case 'pdf':
            return 'fa-file-pdf-o';

        case 'json':
        case 'ld+json':
        case 'xml':
            return 'fa-file-code-o';

        default:
            return 'fa-file-o';
    }

}
