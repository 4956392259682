import {Component, Input, Output, SimpleChange, EventEmitter, HostListener} from '@angular/core';

import * as Highcharts from 'highcharts';
import * as moment from 'moment';

import { Defaults } from 'app/util/defaults';
import { LocationService, ReferenceLevels, ValidationStatus, LocationMeasurementsValidations, LocationWithStatistics, BasicMeasurementData, DetailMeasurementData } from "../services/location.service";
import { User, UserService } from "../services/user.service";
import { Series } from 'highcharts';
import { Observable } from 'rxjs';
import { REFERENCE_LEVELS, ANNOTATIONS, WRITE_ANNOTATIONS, EDIT_MANUAL_VALIDATION } from 'app/services/permission-definitions';
import { NotifierService } from 'angular-notifier';
import { KNMIStationDataEntry } from 'app/services/knmi-service';

// Sizes of the grass image
const GRASS_HEIGHT = 48;
const GRASS_WIDTH = 176;
// Minimum zoom range (3h)
const MIN_ZOOM_RANGE_MS = 1000 * 60 * 60 * 3;

// Below are the transformations for incoming detailed data series (enabled using the 'detail graphs' button).
// If a series is encountered of the form xxxx_yy, xxxx is interpreted as the name, and yy as the unit.
// The unit determines the y-axis on which the series is put, and the y-values transformation. All are defined in the maps below.
const DETAIL_DATA_NAME_MAPPING = {
  "airPressure": "Luchtdruk",
  "airTemp": "Luchttemperatuur",
  "waterPressure": "Waterdruk",
  "waterTemp": "Watertemperatuur",
  "EC": "Elektrische geleidbaarheid (EC)",
  "acidity": "Zuurgraad",
  "pH": "Zuurgraad",
  "per": "Tetrachlooretheen (per)",
  "som-ckws": "Som CKW's",
};
const DETAIL_DATA_UNIT_TO_Y_AXIS_MAPPING = {
  "Pa": 2,
  "mK": 3,
  "µg/l": 4,
  "ug/l": 4,
  "pH": 5,
  "mS/cm": 6,
}
const DETAIL_DATA_SERIES_TRANSFORM_FUNCTIONS = {
  "mK": series => series.map(t => t === null ? null : (t - 273150) / 1000),
  "Pa": series => series,
  "pH": series => series,
  "µg/l": series => series,
  "ug/l": series => series,
  "mS/cm": series => series,
}
const DETAIL_DATA_UNIT_MAPPING = {
  "mK": "°C",
  "Pa": "Pa",
  "pH": "pH",
  "µg/l": "µg/l",
  "ug/l": "µg/l",
  "mS/cm": "mS/cm",
}

const DETAIL_DATA_TOOLTIP_FORMATTER_MAPPING = {
  "mK": Defaults.FORMAT_HIGHCHARTS_SERIES_ONE_DECIMAL,
  "Pa": Defaults.FORMAT_HIGHCHARTS_SERIES_NO_DECIMAL,
  "pH": Defaults.FORMAT_HIGHCHARTS_SERIES_NO_DECIMAL,
  "µg/l": Defaults.FORMAT_HIGHCHARTS_SERIES_TWO_DECIMALS,
  "ug/l": Defaults.FORMAT_HIGHCHARTS_SERIES_TWO_DECIMALS,
  "mS/cm": Defaults.FORMAT_HIGHCHARTS_SERIES_TWO_DECIMALS,
}

interface AnnotationContent {
  content: string;
  author?: string;
  createdTime?: moment.Moment;
}

@Component({
  selector: 'app-waterlevel-display',
  templateUrl: './waterlevel-display.component.html',
  styleUrls: ['./waterlevel-display.component.css'],
})
export class WaterlevelDisplayComponent {
  @Input() locationData: LocationWithStatistics;
  @Input() basicMeasurementData: BasicMeasurementData | null;
  @Input() detailMeasurementData: DetailMeasurementData | null;
  @Input() knmiData: KNMIStationDataEntry[];
  @Input() showDetailMeasurementData: boolean;
  @Input() allowValidation: boolean = true;
  @Input() isReadOnly: boolean = false;

  @Output() dateSelection: EventEmitter<any> = new EventEmitter();
  @Output() measurementsRefreshRequester: EventEmitter<any> = new EventEmitter();

  public referenceControlsPermission: boolean = false;
  public canWriteAnnotations: boolean = false;
  public validationControlsPermission: boolean = false;

  public validationComment: String = "";
  public validationStatus: boolean = false;
  selectedTimestampsForValidation: number[] = [];

  // colorDirt = 'rgba(158, 77, 7, 0.7)'
  colorDirt = 'rgba(156, 103, 59, 1.0)'
  // colorWater = 'rgba(60, 120, 189, 0.5)';
  colorWater = 'rgba(152, 182, 217, 0.5)';
  colorValidatedMeasurements = 'rgba(60, 120, 189, 0.5)';
  colorInvalidMeasurements = 'rgba(217, 83, 79, 0.7)';
  colorUnvalidatedMeasurements = 'rgba(239, 240, 241, 0.2)';

  lineReferenceAboveReference: number = 0;

  static showInNapReference = false;

  referenceLevelButtonText = this.getReferenceLevelButtonText();

  grassImages; // References to the SVG elements on top of the chart
  chartSeries = [];
  chartOptions = {
    credits: {
      enabled: true,
      text: "Blik Sensing",
      href: "https://blik-sensing.nl"
    },
    navigation: {
    //   bindings: {}
      events: {
        selectButton: function (event) {
            event.button.classList.add('highcharts-active');
        },
        deselectButton: function (event) {
            event.button.classList.remove('highcharts-active');
        }
      }
    },
    tooltip: {
      shared: true,
    },
    // stockTools: {
    //   gui: {
		// 		enabled: true, // disable the builtin toolbar
		// 	}
    // },
    chart: {
      backgroundColor: 'transparent',
      type: 'area',
      zoomType: 'x',
      // spacingTop is needed to render the grass on top of the chart
      spacingTop: GRASS_HEIGHT,
      events: {
        redraw: (event) => {
          this.renderGrass(event);
          setTimeout(function() {
            window.dispatchEvent(new Event('resize'));
          }, 0.5);
        },
        click: (e: Highcharts.PointerEventObject) => {
          var target: Element = e.target as Element;

          if(target && target.classList) {
            if( target.classList.contains('highcharts-area')
             || target.classList.contains('highcharts-plot-band')
             || target.classList.contains('highcharts-background')
              ) {
              if(e && e['yAxis'].length > 0 && e['yAxis'][0].value && e['yAxis'][0].value != NaN
                   && e['xAxis'].length > 0 && e['xAxis'][0].value && e['xAxis'][0].value != NaN
                ) {
                this.addAnnotationPopup(e['xAxis'][0].value, e['yAxis'][0].value);
              }
            }
          }
        },
        selection: (e) => {
          return this.handleValidationSelection(e);
        }
      },
    },
    rangeSelector: {
      enabled: true,
      allButtonsEnabled: true,
      floating: true,
      verticalAlign: 'bottom',
      selected: 2
    },
    navigator: {
      enabled: true,
      yAxis: {
        reversed: false
      },
      series: {
        fillColor: this.colorWater,
        type: 'area',
        color: this.colorWater,
        fillOpacity: 0.4,
        dataGrouping: {
            smoothed: false
        },
        lineWidth: 2,
        lineColor: this.colorValidatedMeasurements,
        marker: {
            enabled: false
        },
        shadow: true
      },
      adaptToUpdatedData: false,
    },
    plotOptions: {
      area: {
        fillColor: this.colorDirt,
        // The area between the line and the threshold value is filled.
        // We want everything above the line to look like earth, so use Infinity.
        threshold: Infinity
      }

    },
    title: {
      text: ""
    },
    xAxis: {
      type: 'datetime',
      // Change formats for the zoomlevels that we want to display differently than the default
      dateTimeLabelFormats: {
        day: Defaults.FORMAT_HIGHCHARTS_MONTHDAY,
        month: Defaults.FORMAT_HIGHCHARTS_MONTHYEAR
      },
      minRange: MIN_ZOOM_RANGE_MS,
      title: {
        text: 'Datum'
      },
      events: {
        setExtremes: (e) => {
          if('max' in e && 'min' in e) {
            this.dateSelection.emit(e);
          }
        }
      },
    },
    yAxis: [{
        labels: {
          format: Defaults.FORMAT_HIGHCHARTS_VALUE
        },
        title: {
          text: this.getMeasurementSeriesLabel()
        }
      }, {
        showEmpty: false,
        min: 0,
        title: {
          text: "Neerslag (mm per uur)"
        },
      }, {
        showEmpty: false,
        title: {
          text: "Druk (Pa)"
        },
      }, {
        showEmpty: false,
        title: {
          text: "Temperatuur (°C)"
        },
      }, {
        showEmpty: false,
        title: {
          text: "Concentratie (µg/l)"
        },
      }, {
        showEmpty: false,
        title: {
          text: "pH"
        },
      }, {
        showEmpty: false,
        title: {
          text: "Elektrische conductiviteit (mS/cm)"
        },
      },
      {
        showEmpty: false,
        title: {
          text: "Onbekende eenheid"
        },
      },
    ],
    series: this.chartSeries,
    exporting: {
      chartOptions: {
        chart: {
          backgroundColor: 'rgb(245,245,245)'
        }
      },
      buttons: {
        contextButton: {
          menuItems: ["printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF"]
        }
      },
      sourceWidth: 1920,
      sourceHeight: 1080,
    },
    legend: {
      width: "35%",
      align: "center",
      alignColumns: false,
    },
  } as Highcharts.Options;

  private globalChartOptions = {
    lang: {
        months: ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'],
        weekdays: ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
        contextButtonTitle: 'Contextmenu',
        decimalPoint: ',',
        downloadJPEG: 'Download JPEG-afbeelding',
        downloadPDF: 'Download PDF-document',
        downloadPNG: 'Download PNG-afbeelding',
        downloadSVG: 'Download SVG-vector-afbeelding',
        exitFullscreen: 'Volledig scherm sluiten',
        loading: 'Laden...',
        noData: 'Geen data',
        printChart: 'Print grafiek',
        rangeSelectorFrom: 'Van',
        rangeSelectorTo: 'tot',
        resetZoomTitle: 'Reset zoom naar 1:1',
        shortMonths: ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
        shortWeekdays: ['zon', 'maa', 'din', 'woe', 'don', 'vri', 'zat'],
        thousandsSep: ' ',
        viewData: 'Toon tabel',
        viewFullscreen: 'Open in volledig scherm',
    }
  } as Highcharts.Options;

  private annotationPermission: Observable<boolean>;
  // Some type checking is better than no type checking: for now only add typing to the current implementation, these additional properties should be refactored out some day.
  public chart: Highcharts.Chart & { oldhasUserSize?: any, hasUserSize?: any, resetParams?: [number, number, boolean] } = null;
  validationModeActive: boolean = false;
  private me: User = null;

  constructor(private locationService: LocationService
  , private userService: UserService
  , private notifier: NotifierService
  , private permissionService: UserService
  ) {
    userService.me.subscribe(newMe => {this.me = newMe;});
    Highcharts.setOptions(this.globalChartOptions);
    this.permissionService.userHasPermission(REFERENCE_LEVELS).subscribe(b => {
      this.referenceControlsPermission = b;
    });
    this.permissionService.userHasPermission(WRITE_ANNOTATIONS).subscribe(b => {
      this.canWriteAnnotations = b && !this.isReadOnly;
    });
    this.permissionService.userHasPermission(EDIT_MANUAL_VALIDATION).subscribe(b => {
      this.validationControlsPermission = b && !this.isReadOnly;
    })
    this.annotationPermission = this.permissionService.userHasPermission(ANNOTATIONS);
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {

    // Hahaa! You would expect this to be in ngOnInit(), didn't you?
    // https://github.com/angular/angular/issues/7782
    // Constructor is not an option, because everything needs to be constructed
    // before creating the chart
    if(this.chart === null) {
      this.chart = Highcharts.chart('waterleveldisplaychart', this.chartOptions);
    }

    if (changes.basicMeasurementData) {
      this.updateMeasurementDataSeries = true;
    }
    if (changes.locationData) {
      this.updateLocationDataSeries = true;
      this.updatePlotLinesAndBands = true;
      if(changes.locationData.currentValue) {
        this.permissionService.userHasPermission(REFERENCE_LEVELS).subscribe(b => {
          if(b && this.locationData.id != null) {
            this.locationService.getReferenceLevels(changes.locationData.currentValue.id).subscribe((d: ReferenceLevels) => {
              if(d) {
                this.lineReferenceAboveReference  = d.waterLevel;
              }
            }, e => {
              console.error("Error getting reference levels", e)
            });
          }
        });
      }
    }
    if (changes.knmiData) {
      this.updateKnmiDataSeries = true;
    }
    if (this.detailMeasurementData && (changes.detailMeasurementData || changes.showDetailMeasurementData)) {
      this.updateDetailDataSeries = true;
    }
    this.displayData();
  }

  updateMeasurementDataSeries: boolean = false;
  updateLocationDataSeries: boolean = false;
  updateKnmiDataSeries: boolean = false;
  updatePlotLinesAndBands: boolean = false;
  updateDetailDataSeries: boolean = false;

  waterLevelSeriesIsNap: boolean = null;

  waterLevelSeries: Series = null;
  knmiDataSeries: Series = null;
  knmiPressureDataSeries: Series = null;
  knmiTemperatureDataSeries: Series = null;
  referenceSeries: Series = null;
  plotLinesSeries: Array<Series> = null;
  detailDataSeries: Map<string, Series> = new Map();

  fetchedAnnotations: boolean = false;

  displayData() {
    let chart = this.chart;
    let measurements = this.basicMeasurementData && this.basicMeasurementData.measurements;
    let referenceMeasurements = this.basicMeasurementData && this.basicMeasurementData.referenceMeasurements;
    let knmiData = this.knmiData;
    let waterLevelSeriesShouldBeNap = WaterlevelDisplayComponent.showInNapReference;

    let bottomOfWellAboveReference;
    let groundLevelMetersAboveReference;
    let validationPlotBands;

    if(this.locationData && this.locationData.details && this.locationData.details.physicalLocation) {
      groundLevelMetersAboveReference = this.locationData.details.physicalLocation.groundLevelMetersAboveReference;
      if(this.locationData.details.wellProperties) {
        bottomOfWellAboveReference = this.locationData.details.wellProperties.topOfWellMetersAboveReference
                                   - this.locationData.details.wellProperties.depthFromTopOfWellMeters
                                   ;
      }
    }

    if (measurements) {
      let timestamps = measurements.timestamps;
      let measurementsMmGround = measurements.waterGround_mm;
      let measurementsMmNap = measurements.waterNAP_mm;
      let validities = measurements.validity;
      validationPlotBands = this.calculateValidityPlotBands(validities, timestamps);
      chart.xAxis[0].update({plotBands: validationPlotBands });

      if (!this.waterLevelSeries || this.waterLevelSeriesIsNap != waterLevelSeriesShouldBeNap || this.updateMeasurementDataSeries) {
        this.updateMeasurementDataSeries = false;

        let measurementsMeters = (waterLevelSeriesShouldBeNap ? measurementsMmNap : measurementsMmGround).map(e => (e === null ? null : e * 1e-3));

        // Pass false as redraw parameter, because we call another update
        var seriesOptions: Highcharts.SeriesAreaOptions|Highcharts.SeriesLineOptions;
        if (waterLevelSeriesShouldBeNap) {
          seriesOptions = {
            type: "line",
            color: this.colorValidatedMeasurements,
            name: this.getMeasurementSeriesLabel(),
            data: timestamps.map((t, i) => [t * 1000, measurementsMeters[i]]),
            yAxis: 0,
            showInNavigator: true,
            tooltip: {
              xDateFormat: Defaults.FORMAT_HIGHCHARTS_DATETIME,
              pointFormat: Defaults.FORMAT_HIGHCHARTS_SERIES_TWO_DECIMALS
            },
            events: {
              click: (e: Highcharts.SeriesClickEventObject) => {
                if(e && e.point.x != NaN
                     && e.point.y != NaN
                  ) {
                  this.addAnnotationPopup(e.point.x, e.point.y);
                }
              }
            },
          }
        } else {
          seriesOptions = {
            type: "area",
            color: this.colorValidatedMeasurements,
            fillColor: this.colorDirt,
            lineColor: this.colorValidatedMeasurements,
            name: this.getMeasurementSeriesLabel(),
            data: timestamps.map((t, i) => [t * 1000, measurementsMeters[i]]),
            yAxis: 0,
            showInNavigator: true,
            tooltip: {
              xDateFormat: Defaults.FORMAT_HIGHCHARTS_DATETIME,
              pointFormat: Defaults.FORMAT_HIGHCHARTS_SERIES_TWO_DECIMALS
            },
            events: {
              click: (e: Highcharts.SeriesClickEventObject) => {
                if(e && e.point.x != NaN
                     && e.point.y != NaN
                  ) {
                  this.addAnnotationPopup(e.point.x, e.point.y);
                }
              }
            },
          }
        }
        if (!this.waterLevelSeries) {
          this.waterLevelSeries = chart.addSeries(seriesOptions, false, false);
          let [minT, maxT] = this.getInitialTimeframe(timestamps);
          chart.xAxis[0].setExtremes(minT, maxT);
        } else {
          this.waterLevelSeries.update(seriesOptions);
        }
        chart.yAxis[0].update({
          title: {
            text: this.getMeasurementSeriesLabel()
          }
        }, false);
      }

      if (this.updateDetailDataSeries) {
        this.doUpdateDetailDataSeries();
        this.updateDetailDataSeries = false;
      }

      if (this.updateKnmiDataSeries) {
        if (this.knmiDataSeries) {
          this.knmiDataSeries.remove(false);
          this.knmiDataSeries = null;
        }
        if (this.knmiPressureDataSeries) {
          this.knmiPressureDataSeries.remove(false);
          this.knmiPressureDataSeries = null;
        }
        if (this.knmiTemperatureDataSeries) {
          this.knmiTemperatureDataSeries.remove(false);
          this.knmiTemperatureDataSeries = null;
        }
        this.updateKnmiDataSeries = false;
      }

      if (knmiData && knmiData.length > 0) {
        if (!this.knmiDataSeries) {
          if (knmiData.some((v: KNMIStationDataEntry) => v.rain != null)) {
            const data: [number, number][] = knmiData.map(d => [
              moment(d.time).valueOf(),
              d.rain != null ? d.rain / 1000 : null
            ]);
            const seriesOptions: Highcharts.SeriesOptionsType = {
              name: "Neerslag (mm per uur)",
              data: data,
              color: 'rgb(0,128,255)',
              type: 'column',
              yAxis: 1,
              tooltip: {
                xDateFormat: Defaults.FORMAT_HIGHCHARTS_DATETIME,
                pointFormat: Defaults.FORMAT_HIGHCHARTS_SERIES_TWO_DECIMALS,
                headerFormat: "<span style=\"font-size: 10px\">Het uur van {point.key}</span><br/>"
              },
            };
            this.knmiDataSeries = this.chart.addSeries(seriesOptions, false, false);
          }
        }
        if(!this.knmiPressureDataSeries) {
          if (knmiData.some((v: KNMIStationDataEntry) => v.airPressure_Pa != null)) {
            const data: [number, number][] = knmiData.map(d => [
              moment(d.time).valueOf(),
              d.airPressure_Pa
            ]);
            const seriesOptions: Highcharts.SeriesOptionsType = {
              name: "KNMI Luchtdruk (Pa)",
              data: data,
              color: 'rgb(0,255,128)',
              type: 'spline',
              yAxis: 2,
              visible: this.showDetailMeasurementData,
              tooltip: {
                xDateFormat: Defaults.FORMAT_HIGHCHARTS_DATETIME,
                pointFormat: Defaults.FORMAT_HIGHCHARTS_SERIES_TWO_DECIMALS,
                headerFormat: "<span style=\"font-size: 10px\">Het uur van {point.key}</span><br/>",
                valueSuffix: 'Pa',
              },
            };
            this.knmiPressureDataSeries = this.chart.addSeries(seriesOptions, false, false);
          }
        }
        if(!this.knmiTemperatureDataSeries) {
          if (knmiData.some((v: KNMIStationDataEntry) => v.airTemperature_K != null)) {
            const data: [number, number][] = knmiData.map((d: KNMIStationDataEntry ) => [
              moment(d.time).valueOf(),
              d.airTemperature_K != null ? d.airTemperature_K.celsius : null
            ]);
            const seriesOptions: Highcharts.SeriesOptionsType = {
              name: "KNMI Luchttemperatuur (°C)",
              data: data,
              color: 'rgb(0,255,255)',
              type: 'spline',
              yAxis: 3,
              visible: this.showDetailMeasurementData,
              tooltip: {
                xDateFormat: Defaults.FORMAT_HIGHCHARTS_DATETIME,
                pointFormat: Defaults.FORMAT_HIGHCHARTS_SERIES_TWO_DECIMALS,
                headerFormat: "<span style=\"font-size: 10px\">Het uur van {point.key}</span><br/>",
                valueSuffix: '°C',
              },
            };
            this.knmiTemperatureDataSeries = this.chart.addSeries(seriesOptions, false, false);
          }
        }
      }
    }

    if (referenceMeasurements && (!this.referenceSeries || this.waterLevelSeriesIsNap != waterLevelSeriesShouldBeNap)) {
      if (this.referenceSeries) {
        this.referenceSeries.remove(false);
        this.referenceSeries = null;
      }
      let timestamps = referenceMeasurements.timestamps;
      let measurementsMmGround = referenceMeasurements.waterGround_mm;
      let measurementsMmNap = referenceMeasurements.waterNAP_mm;
      let measurementsMeters = (waterLevelSeriesShouldBeNap ? measurementsMmNap : measurementsMmGround).map(e => (e === null ? null : e * 1e-3));

      // Pass false as redraw parameter, because we call another update
      this.referenceSeries = this.chart.addSeries({
        name: this.getReferenceMeasurementSeriesLabel(),
        data: timestamps.map((t, i) => [t * 1000, measurementsMeters[i]]),
        type: 'scatter',
        marker: {symbol: 'line'}, // TODO we need to add the horizontal line symbol to HighCharts as it is not a default symbol
        yAxis: 0,
        color: 'red',
        tooltip: {
          xDateFormat: Defaults.FORMAT_HIGHCHARTS_DATETIME,
          pointFormat: Defaults.FORMAT_HIGHCHARTS_SERIES_TWO_DECIMALS
        },
      } as Highcharts.SeriesOptionsType, false, false);
    }

    if (this.waterLevelSeriesIsNap != waterLevelSeriesShouldBeNap || this.updatePlotLinesAndBands) {
      this.updatePlotLinesAndBands = false;
      var plotBands = [];

      // Only show bands when graph in relation to Maaiveld
      if(!WaterlevelDisplayComponent.showInNapReference) {
        if(bottomOfWellAboveReference) {
          plotBands.push({
            color: this.colorWater,
            from: bottomOfWellAboveReference - groundLevelMetersAboveReference,
            to: 0
          });
          plotBands.push({
            color: "grey",
            from: -100,
            to: bottomOfWellAboveReference - groundLevelMetersAboveReference
          });
        } else {
          plotBands.push({
            color: this.colorWater,
            from: -100,
            to: 0
          });
        }
      }
      this.chart.update({
        yAxis: {
          plotBands: plotBands
        }
      }, false);

      // Graph in relation to NAP
      if (waterLevelSeriesShouldBeNap) {
        var thisObject = this;

        var allThePlotLines = [];

        if(bottomOfWellAboveReference) {
          allThePlotLines.push({id: 'Bodem', color: '#000000', width:3, value: bottomOfWellAboveReference, visible: true, zIndex: 1});
        }
        if(this.lineReferenceAboveReference) {
          allThePlotLines.push({id: 'Referentielijn', color: '#000000', width:1, value: this.lineReferenceAboveReference, visible: true, zIndex: 1});
        }
        if(groundLevelMetersAboveReference) {
          allThePlotLines.push({id: 'Maaiveld', color: '#dd4400', width:1, value: groundLevelMetersAboveReference, visible: true, zIndex: 1});
        }
        if(this.locationData && this.locationData.statistics) {
          if(this.locationData.statistics.GHGNAP_m) {
            allThePlotLines.push({id: 'GHG', color: '#ee0000', width:1, value: this.locationData.statistics.GHGNAP_m, visible: true, zIndex: 1});
          }
          if(this.locationData.statistics.RHGNAP_m) {
            allThePlotLines.push({id: 'RHG', color: '#dd4466', width:1, value: this.locationData.statistics.RHGNAP_m, visible: true, zIndex: 1});
          }
          if(this.locationData.statistics.RLGNAP_m) {
            allThePlotLines.push({id: 'RLG', color: '#dd66dd', width:1, value: this.locationData.statistics.RLGNAP_m, visible: true, zIndex: 1});
          }
          if(this.locationData.statistics.GLGNAP_m) {
            allThePlotLines.push({id: 'GLG', color: '#ee00ee', width:1, value: this.locationData.statistics.GLGNAP_m, visible: true, zIndex: 1});
          }
        }

        if(this.plotLinesSeries) {
          this.plotLinesSeries.forEach(p => p.remove(false));
        }
        this.plotLinesSeries = allThePlotLines.map(p => {
          return this.chart.addSeries({
            type: 'line',
            // Series that mimics the plot line
            color: p.color,
            name: p.id,
            visible: p.visible,
            marker: {
                enabled: false
            },
            events: {
                // Event for showing/hiding plot line
                legendItemClick: function(e) {
                    allThePlotLines = allThePlotLines.map(e => {
                      if(e.id == p.id) {
                        e.visible = !this.visible;
                      }
                      return e;
                    });
                    this.chart.yAxis[0].update({plotLines: allThePlotLines.filter(e => e.value && e.visible)}, false);
                    return true;
                }
            }
          } as Highcharts.SeriesOptionsType, false, false);
        });
        this.chart.yAxis[0].update({plotLines: allThePlotLines.filter(e => e.value && e.visible)}, false);
      }

      // Graph in relation to Maaiveld
      else {
        if(this.plotLinesSeries) {
          this.plotLinesSeries.forEach(p => p.remove(false));
        }
        this.plotLinesSeries = null;
        this.chart.yAxis[0].update({plotLines: []}, false);
      }

      this.chart.yAxis[0].update({max: waterLevelSeriesShouldBeNap ? groundLevelMetersAboveReference : 0}, false);
    }

    if(this.locationData && !this.fetchedAnnotations) {
      this.fetchedAnnotations = true;

      this.annotationPermission.subscribe(haveAnnotationPermission => {
        if (!haveAnnotationPermission) {
          return;
        }

        if (this.locationData.id == null) {
          return;
        }

        this.locationService.getAnnotations(this.locationData.id).subscribe((annotations) => {
          for (let a of annotations) {
            this.userService.getUser(a.customerId).subscribe(user => {
              const content: AnnotationContent = {
                content: a.content,
                author: user.name,
                createdTime: moment(a.creationTime),
              };

              this.addAnnotationToChart(a.annotationTime === null ? null : moment(a.annotationTime).unix() * 1000
                                      , a.waterLevel === null ? null : a.waterLevel
                                      , content
                                      , a.id
                                      );
            });
          }
        });
      });
    }
    this.chart.redraw(false);

    this.dateSelection.emit({
      start: this.chart.xAxis[0].getExtremes().dataMin,
      end: this.chart.xAxis[0].getExtremes().dataMax
    });

    this.waterLevelSeriesIsNap = waterLevelSeriesShouldBeNap;
  }

  private calculateValidityPlotBands(validities: any, timestamps: any) {
    let currentIndex = 0;
    let plotBands = [];

    let stop = false;

    while(!stop) {
      let currentValidity = validities[currentIndex];
      let indexInRestOfArray = validities.slice(currentIndex).findIndex((el) => el !== currentValidity)
      let nextIndex = currentIndex + indexInRestOfArray;
      stop = indexInRestOfArray < 0

      if(stop) {
        nextIndex = timestamps.length -1;
      }

      plotBands.push(
        this.makeValidityPlotBand(timestamps, currentIndex, nextIndex - 1, currentValidity)
      )

      currentIndex = nextIndex;
    }

    return plotBands;
  }

  // Makes a plotband that 'overhangs' the start and end indexes to make it clear what the range of validity is
  private makeValidityPlotBand(timestamps: any, startIndex: number, endIndex: any, currentValidity: any): any {
    let minIndex = 0;
    let maxIndex = timestamps.length - 1;

    let indexBeforeStart = Math.max(startIndex - 1, minIndex);
    let indexAfterEnd = Math.min(endIndex + 1, maxIndex);

    let startTimeStamp = timestamps[startIndex];
    let endTimeStamp = timestamps[endIndex];

    if (currentValidity == 0) {
      // If measurements are invalid, extend the red band to the adjacent measurements
      startTimeStamp = timestamps[indexBeforeStart];
      endTimeStamp = timestamps[indexAfterEnd];
    }

    return {
      from: startTimeStamp * 1000,
      to: endTimeStamp * 1000,
      color: this.getMeasurementColor(currentValidity),
      zIndex: -200
    };
  }

  private getInitialTimeframe(timestamps: number[]): [number, number] {
    let first = timestamps[0];
    let last = timestamps[timestamps.length-1];
    const MAX_RANGE_SECONDS = 21 * 24 * 60 * 60; // 21 days
    if ((last - first) > MAX_RANGE_SECONDS) {
      first = last - MAX_RANGE_SECONDS;
    }
    return [first * 1000, last * 1000];
  }

  private getMeasurementColor(validity: any) {
    if(validity === null) {
      return this.colorUnvalidatedMeasurements;
    }
    if(validity == 1) {
      return this.colorValidatedMeasurements;
    }
    if(validity == 0) {
      return this.colorInvalidMeasurements;
    }
  }

  detailDataGetUnit(measurementKey: string): string {
    let parts = measurementKey.split("_", 2);
    if (parts.length == 2) {
      return parts[1];
    } else {
      return null;
    }
  }

  detailDataTransformToYAxisUnit(measurementKey: string, data: number[]): number[] {
    let unit = this.detailDataGetUnit(measurementKey)
    if (unit in DETAIL_DATA_SERIES_TRANSFORM_FUNCTIONS) {
      return DETAIL_DATA_SERIES_TRANSFORM_FUNCTIONS[unit](data);
    } else {
      console.warn("Don't know how to transform series data for " + measurementKey);
      return data;
    }
  }

  detailDataSeriesName(measurementKey: string): string {
    let name = measurementKey;
    let unit = null;
    let parts = measurementKey.split("_", 2);
    if (parts.length == 2) {
      name = parts[0];
      unit = parts[1];
    }

    if (name in DETAIL_DATA_NAME_MAPPING) {
      name = DETAIL_DATA_NAME_MAPPING[name];
    } else  {
      console.warn("Don't know series name for " + measurementKey);
    }

    if (unit in DETAIL_DATA_UNIT_MAPPING) {
      unit = DETAIL_DATA_UNIT_MAPPING[unit];
    } else {
      console.warn("Don't know series unit for " + measurementKey);
      unit = "onbekende eenheid";
    }

    return name + " (" + unit + ")";
  }

  detailDataYAxis(measurementKey: string): number {
    var unit = this.detailDataGetUnit(measurementKey);

    if (unit in DETAIL_DATA_UNIT_TO_Y_AXIS_MAPPING) {
      return DETAIL_DATA_UNIT_TO_Y_AXIS_MAPPING[unit];
    } else {
      console.warn("Don't have an axis for " + measurementKey);
      return 7;
    }
  }

  doUpdateDetailDataSeries() {
    let chart = this.chart;
    let detailMeasurements = this.detailMeasurementData ? this.detailMeasurementData.measurements : null;

    if (this.showDetailMeasurementData && detailMeasurements) {
      let timestamps = detailMeasurements['timestamps'].map(t => t * 1000);

      for (let measurementKey in detailMeasurements) {
        if (measurementKey == "timestamps") continue;

        if (!(measurementKey in this.detailDataSeries)) {
          let measurementValues = this.detailDataTransformToYAxisUnit(measurementKey, detailMeasurements[measurementKey]);

          let seriesOptions : Highcharts.SeriesLineOptions = {
            type: "line",
            name: this.detailDataSeriesName(measurementKey),
            data: timestamps.map((t, i) => [t, measurementValues[i]]),
            yAxis: this.detailDataYAxis(measurementKey),
            tooltip: {
              xDateFormat: Defaults.FORMAT_HIGHCHARTS_DATETIME,
              pointFormat : DETAIL_DATA_TOOLTIP_FORMATTER_MAPPING[this.detailDataGetUnit(measurementKey)]
            },
            connectNulls: true
          }
          this.detailDataSeries[measurementKey] = chart.addSeries(seriesOptions);

        } else {
          this.detailDataSeries[measurementKey].update({visible: true});
        }
      }

    } else {
      for (let measurementKey in this.detailDataSeries) {
        this.detailDataSeries[measurementKey].update({visible: false});
      }
    }

    if (this.knmiPressureDataSeries) {
      this.knmiPressureDataSeries.update({ type: 'spline', visible: this.showDetailMeasurementData });
    }

    if (this.knmiTemperatureDataSeries) {
      this.knmiTemperatureDataSeries.update({ type: 'spline', visible: this.showDetailMeasurementData });
    }
  }

  toggleReferenceLevel() {
    WaterlevelDisplayComponent.showInNapReference = !WaterlevelDisplayComponent.showInNapReference;
    this.referenceLevelButtonText = this.getReferenceLevelButtonText();
    this.deselectAllPointsSelectedForValidation();
    this.displayData();
  }

  getReferenceLevelButtonText(): any {
    if (WaterlevelDisplayComponent.showInNapReference) {
      return 'Toon relatief aan maaiveld';
    } else {
      return 'Toon relatief aan NAP';
    }
  }

  getMeasurementSeriesLabel() {
    return `Peil (m ${WaterlevelDisplayComponent.showInNapReference ? 'NAP' : 'maaiveld'})`;
  }

  getReferenceMeasurementSeriesLabel() {
    return `Referentiemeting (m ${WaterlevelDisplayComponent.showInNapReference ? 'NAP' : 'maaiveld'})`;
  }

  clearChart() {
    if (this.chart) {
      let series = this.chart.series;
      // Remove series one by one and only redraw if there is one left
      while(series.length > 0) {
        series[0].remove(series.length === 1);
      }
    }
  }

  renderGrass(event) {
    // Get the Highcharts SVGRenderer
    let renderer = event.target.renderer;

    // Remove any previous grass image
    if (this.grassImages && this.grassImages.length > 0) {
      this.grassImages.forEach(img => {
        img.added && img.destroy();
      });
    }

    // Don't render grass for NAP
    if (WaterlevelDisplayComponent.showInNapReference) {
      return;
    }

    // If there is any data, determine the min and max of the xAxis
    if (event.target.xAxis[0]) {
      let xAxis = event.target.xAxis[0];
      if (xAxis.dataMin && xAxis.dataMax) {
        // extremes is an object containing dataMin, dataMax and (if zoomed) userMin, userMax
        let extremes = xAxis.getExtremes();

        // dataMin and dataMax are coordinates on the xAxis (in milliseconds),
        // so use the Axis.toPixels() function to convert to screen coordinates.
        let minX = xAxis.toPixels(extremes.userMin || extremes.dataMin);
        let maxX = xAxis.toPixels(extremes.userMax || extremes.dataMax);
        let width = maxX-minX;

        let numberOfImages = Math.floor(width/GRASS_WIDTH);
        this.grassImages = Array(numberOfImages);
        for (let i = 0; i <= numberOfImages; i ++) {
          let grassImage = renderer.image('assets/images/grass.png', minX + i*GRASS_WIDTH, 0, GRASS_WIDTH, GRASS_HEIGHT);

          // Clip the last image based on the remaining width
          if (i === numberOfImages) {
            let start = minX + numberOfImages*GRASS_WIDTH;
            let remainingWidth = width % GRASS_WIDTH;
            let clipRect = renderer.clipRect(start, 0, remainingWidth, GRASS_HEIGHT);
            grassImage.clip(clipRect);
          }
          grassImage.add();
          this.grassImages[i] = grassImage;
        }
      }
    }
  };

  updateLineReference() {
    this.locationService.setReferenceLevels(this.locationData.id, {waterLevel: this.lineReferenceAboveReference}).subscribe();
    this.updatePlotLinesAndBands = true;
    this.displayData();
  }

  addAnnotationPopup(x: number, y: number): void {
    if (!this.canWriteAnnotations) {
      return;
    }

    const t = prompt("Voer uw annotatie in");

    if (!t) {
      return;
    }

    this.locationService.addAnnotation(this.locationData.id, {
      content: t,
      waterLevel: y,
      annotationTime: moment(x, 'x').toISOString(),
    }).subscribe(id => {
      const content: AnnotationContent = {
        content: t,
        author: this.me.name,
        createdTime: moment(),
      };
      this.addAnnotationToChart(x, y, content, id)
    }, e => {
      this.notifier.notify('error', "Toevoegen annotatie mislukt");
      console.error("Error adding annotation");
    });
  }

  addAnnotationToChart(x: number | null, y: number | null, content: AnnotationContent, id: number): void {
    let text = '';

    if (content.author)
    {
      text += content.author;
      text += '<br/>';
    }

    if (content.createdTime) {
      text += content.createdTime.format('YYYY-MM-DD HH:mm');
      text += '<br/>';
    }

    text += content.content;

    const annotation: Highcharts.AnnotationsOptions = {
      events: {
        remove: () => {
          this.locationService.delAnnotation(this.locationData.id, id).subscribe(d => d, e => {
            this.notifier.notify('error', "Verwijderen annotatie mislukt");
            console.error("Error deleting annotation");
          });
        }
      },
      draggable: '',
      id: id,
      labels: [{
        text: text,
        point: {
          x: x,
          y: y,
          xAxis: 0,
          yAxis: 0
        },
      }]
    };
    this.chart.addAnnotation(annotation, true);
  }

  showReferenceControls(): boolean {
    return this.referenceControlsPermission && WaterlevelDisplayComponent.showInNapReference;
  }

  showValidationControls(): boolean {
    return this.validationControlsPermission;
  }

  @HostListener('window:beforeprint')
  printPrep() {
    this.chart.oldhasUserSize = this.chart.hasUserSize;
    this.chart.resetParams = [this.chart.chartWidth, this.chart.chartHeight, false];
    this.chart.setSize(600, this.chart.chartHeight, false);

    this.chart.reflow();
  }

  @HostListener('window:afterprint')
  afterPrint() {
    this.chart.setSize.apply(this.chart, this.chart.resetParams);
    this.chart.hasUserSize = this.chart.oldhasUserSize;
  }

  toggleValidationMode(event: any) {
    this.validationModeActive = !this.validationModeActive;
  }

  validationInputsDisabled(){
    return this.selectedTimestampsForValidation.length < 1
  }

  markSelectionValid() {
    this.markSelectionValidated("valide", ValidationStatus.VALIDATED);
  }

  markSelectionInvalid() {
    this.markSelectionValidated("invalide", ValidationStatus.INVALIDATED_OTHER);
  }

  markSelectionNotValidated() {
    this.markSelectionValidated("ongevalideerd", ValidationStatus.NOT_VALIDATED)
  }

  markSelectionValidated(userReadableValidity: string, validationStatus: ValidationStatus) {
    let timestamps = this.selectedTimestampsForValidation;
    var startMoment = moment(timestamps[0], 'X');
    var endMoment = moment(timestamps[timestamps.length-1], 'X')

    if(this.getUserValidityConfirmation(startMoment, endMoment, userReadableValidity)) {
      this.postValidationEntry(startMoment, endMoment, userReadableValidity, validationStatus);
    }
  }

  getUserValidityConfirmation(startMoment, endMoment, userReadableValidity: String) : boolean {
    return confirm("Weet u zeker dat u metingen van "
      + startMoment.toLocaleString() + " tot " + endMoment.toLocaleString() + " als " + userReadableValidity + " wilt markeren?");
  }

  postValidationEntry(startMoment, endMoment, userReadableValidity, validationStatus: ValidationStatus) {
    let entry: LocationMeasurementsValidations = {
      telemetric: [
        {
            logEntry : {
              content: this.me.name + " heeft de metingen van "
              + startMoment.toLocaleString() + " tot " + endMoment.toLocaleString()
              + " als " + userReadableValidity + " gemarkeerd" + this.getFormattedValidationComment()
            },
            manualValidationStatus: validationStatus,
            timestamps: this.selectedTimestampsForValidation
        }
      ],
      external: [
        {
            logEntry : {
              content: this.me.name + " heeft de metingen van "
              + startMoment.toLocaleString() + " tot " + endMoment.toLocaleString()
              + " als " + userReadableValidity + " gemarkeerd" + this.getFormattedValidationComment()
            },
            manualValidationStatus: validationStatus,
            timestamps: this.selectedTimestampsForValidation
        }
      ]
    }
    this.locationService.addValidationEntry(this.locationData.id, entry).subscribe(
      data => {
        this.notifier.notify('success', 'Validatie succesvol doorgevoerd.');
        this.deselectAllPointsSelectedForValidation();
        this.refreshMeasurementDataFromBackend();
      },
      error => {
        this.notifier.notify('error', 'Validatie doorvoeren is mislukt.');
        console.error('Error adding validation entry: ', error);
      }
    );
  }

  getFormattedValidationComment() {
    return this.validationComment.length > 0
              ? " met commentaar: " + this.validationComment + "."
              : "."
  }

  refreshMeasurementDataFromBackend() {
    this.measurementsRefreshRequester.emit();
  }

  getValidationModeActive() {
    return this.validationModeActive;
  }

  deselectAllPointsSelectedForValidation() {
    this.selectedTimestampsForValidation = []
    this.chart.xAxis[0].removePlotBand('mask-selection');
  }

  handleValidationSelection(selectionContext: Highcharts.ChartSelectionContextObject): boolean {
    if(this.validationModeActive) {
      let data = this.waterLevelSeries.data
      let xAxis = selectionContext.xAxis[0]

      let startIndex = data.findIndex((entry) => entry ? entry.x > xAxis.min : false)
      let endIndex = data.findIndex((entry) => entry ? entry.x > xAxis.max : false)

      for(let i = startIndex; i < endIndex; i++) {
        this.selectedTimestampsForValidation.push(data[i].x / 1000) // Timestamps in the dataset are in milliseconds
      }

      this.chart.xAxis[0].removePlotBand('mask-selection');
      this.chart.xAxis[0].addPlotBand({
          id: 'mask-selection',
          from: xAxis.min,
          to: xAxis.max,
          zIndex: 5,
          color: 'rgba(0, 0, 0, 0.2)'
      });
    }

    // The return value of this function determines if you zoom or not. During validation we don't want to zoom, only select
    return !this.validationModeActive;
  }
}
