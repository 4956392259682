import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {Cacheable} from "../shared/cacheable";

export type LocationID = string | number;

export interface PhysicalLocationDetails {
  town?: string,
  address?: string,
  addition?: string,
  rijksdriehoekX?: number,
  rijksdriehoekY?: number,
  WGS84degNorth?: number,
  WGS84degEast?: number,
  ETRS89degEast?: number,
  ETRS89degNorth?: number,
  groundLevelMetersAboveReference?: number,
  heightReference?: string,
  heightMeasureDate?: Date,

  // Everything below is not explicitly defined in the backend ("unknown properties")

  groundWaterFlowJudgment?: string,
  groundLevelStability?: string,
  groundOwner?: string,
  groundOwnerDeals?: string,
  mvMethod?: string,
}

export interface WellProperties {
  tubeNumber?: number,
  constructionDate?: string,

  filterConfiguration?: string,
  material?: string,
  onlay?: string,
  lock?: string,
  insideDiameterMm?: number,
  outsideDiameterMm?: number,
  topOfWellMetersAboveReference?: number,
  depthFromTopOfWellMeters?: number,
  
  depthMeasureDate?: Date,

  // Everything below is not explicitly defined in the backend ("unknown properties")

  topOfFilterMetersAboveReference?: number,
  bottomOfFilterMetersAboveReference?: number,
  
  lastDepthMeasureDate?: Date,
  trajectFiltergrindEnZwelklei?: string,
  pipeType?: string,
  pipeStatus?: string,
  hasLDDop?: boolean,
  hasSandTrap?: boolean,
  placeDate?: Date,
  kopMethod?: string,
  cableLengthMeters?: number,
  variableDiameter?: boolean,
  pipeMaterial?: string,
  fillingMaterial?: string,
  glue?: string,
  sockMaterial?: string,
  wellStability?: string,
  wellOwner?: string,
}

export interface WellDetails {
  NITGnumber?: string,
  BROid?: string,
  physicalLocation?: PhysicalLocationDetails,
  wellProperties?: WellProperties,

  // Everything below is not explicitly defined in the backend ("unknown properties")

  wellID?: string,
  internalName?: string,
  initialFunction?: string,
  inUseSince?: Date,
  inUseUntil?: Date,
  deliveryContext?: string,
  placementNorm?: string,
  maintenanceCompanyKVKID?: string,
}

export interface Statistics {
  id?: number
, locationId?: number
, creationTime?: string
, customerId?: number
, GHGNAP_m: number
, GLGNAP_m: number
, RHGNAP_m: number
, RLGNAP_m: number
}

export enum NotificationStatus {
  UNKNOWN = "UNKNOWN",
  OK = "OK",
  ABOVE_UPPER_LIMIT = "ABOVE_UPPER_LIMIT",
  BELOW_LOWER_LIMIT = "BELOW_LOWER_LIMIT",
  ABOVE_UPPER_LIMIT_BELOW_TIME_LIMIT = "ABOVE_UPPER_LIMIT_BELOW_TIME_LIMIT",
  BELOW_LOWER_LIMIT_BELOW_TIME_LIMIT = "BELOW_LOWER_LIMIT_BELOW_TIME_LIMIT",
}

export interface Location<ID extends LocationID = LocationID> {
  id?: ID,
  name?: string,
  description?: string,
  address?: string,
  latitude?: number,
  longitude?: number,
  altitude?: number,
  details?: WellDetails,
}

export interface BlikLocation extends Location<number> {}

export interface BroLocation extends Location<string> {
  broGmwId?: string,
  broGmwTubeNumber?: number,
}

export interface LocationWithStatistics extends BlikLocation {
  lastMeasurementAt?: Date,
  lastMessageReceivedAt?: Date,
  currentGroundWaterLevel?: number,
  currentTemperatureKelvin?: number,
  currentAirPressurePa?: number,
  currentBatteryVoltageVolts?: number,
  minGroundWaterLevel?: number,
  maxGroundWaterLevel?: number,
  averageGroundWaterLevel?: number,
  notificationStatus?: NotificationStatus,
  deployments?: [{
      analysisSubReports: [],
      id: number,
      nodeID: number,
      locationID: number,
      distanceAboveGroundLevel: number,
      distanceAboveNap: number,
      distanceAboveSeaLevel: number,
      fromTime: number,
      toTime: number,
      report?: any,
      subReports?: any[],
      configs?: any[],
      events?: any[],
      batteryDeadTime: number,
      lastMessageReceivedAt: number,
      waterSensorAboveTopOfWell: number,
      flowMeterCoverageM3: number,
      flowMeterGreenIsIn: number,
      flowMeterPulseAmountM3: number,
      airPressureCalibratedOffsetPa: number
    }],
  firmware?: number,
  batteryDeadEstimate?: Date,
  statistics?: Statistics,
}

export interface LocationMetadata {
  id?: number,
  locationId?: number,
  creationTime?: string,
  annotationTime?: string,
  customerId?: number,
}

export interface LocationAnnotation extends LocationMetadata {
  content?: string,
  waterLevel?: number,
}

export interface ReferenceLevels extends LocationMetadata {
  name?: string,
  content?: string,
  waterLevel?: number,
}

export interface LocationLogEntry extends LocationMetadata {
  type?: string,
  content?: string
}

export interface LocationMeasurementsValidations {
  telemetric?: [TimeseriesValidationEvent],
  reference?: [TimeseriesValidationEvent],
  external?: [TimeseriesValidationEvent]
}

export interface TimeseriesValidationEvent {
  logEntry : LocationLogEntry,
  manualValidationStatus: ValidationStatus,
  timestamps: number[]
}

export interface MeasurementDataOptions<
  TReferenceMeasurements extends boolean,
  TWaterLevel extends boolean,
  TAirWater extends boolean,
  TFlow extends boolean,
  TInvalidated extends boolean,
  TOnlyValidated extends boolean
> {
  referenceMeasurements: TReferenceMeasurements,
  waterLevel: TWaterLevel,
  airWaterMeasurements: TAirWater,
  flowMeasurements: TFlow,
  onlyValidated: TOnlyValidated,
  invalidated: TInvalidated,
};

export interface MeasurementData<
  TReferenceMeasurements extends boolean,
  TWaterLevel extends boolean,
  TAirWater extends boolean,
  TFlow extends boolean,
  TInvalidated extends boolean,
  TOnlyValidated extends boolean
> {
  measurements: Measurements<TWaterLevel, TAirWater, TFlow, TInvalidated, TOnlyValidated>,
  referenceMeasurements: TReferenceMeasurements extends true
    ? Measurements<TWaterLevel, TAirWater, TFlow, TInvalidated, TOnlyValidated>
    : never,
}

type NaIf<T extends boolean> = T extends true
  ? number[]
  : never;

type TMeasurementValidity<TInvalidated, TOnlyValidated> = TInvalidated extends true
  ? (TOnlyValidated extends true ? never : (0 | 1 | null)[])
  : (TOnlyValidated extends true ? null[] : (1 | null)[]);

export interface Measurements<
  TWaterLevel extends boolean,
  TAirWater extends boolean,
  TFlow extends boolean,
  TInvalidated extends boolean,
  TOnlyValidated extends boolean
> {
  timestamps: number[];
  waterGround_mm: NaIf<TWaterLevel>;
  waterNAP_mm: NaIf<TWaterLevel>;
  validity: TMeasurementValidity<TInvalidated, TOnlyValidated>;
  flowIn_mm3: NaIf<TFlow>;
  flowOut_mm3: NaIf<TFlow>;
  rainIn_mm: NaIf<TFlow>;
  rainOut_mm: NaIf<TFlow>;
  airPressure_Pa: NaIf<TAirWater>;
  airTemp_mK: NaIf<TAirWater>;
  waterPressure_Pa: NaIf<TAirWater>;
  waterTemp_mK: NaIf<TAirWater>;
}

export type BasicMeasurementData = MeasurementData<true, true, false, false, true, false>;
export type DetailMeasurementData = MeasurementData<false, false, boolean, false, boolean, false>;

export enum ValidationStatus {
  NOT_VALIDATED = "NOT_VALIDATED", //	(Not) yet validated
  VALIDATED = "VALIDATED", //	Validated and approved
  MISSING_SENSOR_VALUES = "MISSING_SENSOR_VALUES", //	Not able to (correctly) determine water level due to missing sensor values
  INVALID_SENSOR_VALUES = "INVALID_SENSOR_VALUES", //	Not able to (correctly) determine water level due to sensor values outside of valid range
  PLUMMET_DRY = "PLUMMET_DRY", //	Dry plummet detected
  PLUMMET_TOO_DEEP = "PLUMMET_TOO_DEEP", //	Too much water above the plummet, outside of the measuring range of the sensor
  WELL_FLOODED = "WELL_FLOODED", //	Water level above the top of the well
  INVALIDATED_OTHER = "INVALIDATED_OTHER", //	Other reason for invalidation
  VALID_MANUAL_ADJUSTMENT = "VALID_MANUAL_ADJUSTMENT", //	Valid after manual correction
}

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private http:HttpClient) {
  }

  // Duplicate data... oh well
  private _locationsWithStats: Record<LocationID, Cacheable<LocationWithStatistics>> = {};
  private _allLocations: Cacheable<BlikLocation[]>;
  private _allBroLocations: Cacheable<BroLocation[]>;
  

  refreshLocation(id: LocationID) {
    if(this._locationsWithStats[id]) {
      this._locationsWithStats[id].refresh();
    }
    if(this._allLocations) {
      this._allLocations.refresh();
    }
  }

  getLocationWithStats(id: LocationID): Observable<LocationWithStatistics> {
    const apiUrl = environment.api_base_url + '/locations/' + id;
    if(this._locationsWithStats[id]) {
    } else {
      this._locationsWithStats[id] = new Cacheable<LocationWithStatistics>(
        this.http.get<LocationWithStatistics>(apiUrl, {
          params: {
            stats: 'true'
          }
      }));
    }
    return this._locationsWithStats[id].get();
  }

  getLocations(): Observable<LocationWithStatistics[]> {
    if(this._allLocations) {
    } else {
      const apiUrl = environment.api_base_url + '/locations/';
      this._allLocations = new Cacheable<LocationWithStatistics[]>(this.http.get<LocationWithStatistics[]>(apiUrl)); // stats defaults to true here
    }
    return this._allLocations.get();
  }

  getBroLocations(): Observable<BroLocation[]> {
    if(this._allBroLocations) {
    } else {
      const apiUrl = environment.api_base_url + '/locations/broall/';
      this._allBroLocations = new Cacheable<BroLocation[]>(this.http.get<BroLocation[]>(apiUrl));
    }
    return this._allBroLocations.get();
  }

  hasActiveDeployment(location: LocationWithStatistics) {
    if (!location.deployments) {
      return false;
    }

    let now = moment();

    for (let deployment of location.deployments) {
      let result = true;
      if (deployment.fromTime) {
        result = result && moment(deployment.fromTime) < now;
      }
      if (deployment.toTime) {
        result = result && moment(deployment.toTime) > now;
      }
      if (result) {
        return true;
      }
    }

    return false;
  }

  /**
   * Update the location name.
   * @param {number} id Location ID
   * @param {string} name New name
   * @returns {Observable<Object>}
   */
  updateName(id: number, name: string) {
    const apiUrl = environment.api_base_url + '/locations/' + id + '/name';
    let sub = this.http.put(apiUrl, name);
    sub.subscribe(() => {this.refreshLocation(id);})
    return sub;
  }

  /**
   * Update the location description.
   * @param {number} id Location ID
   * @param {string} description New description.
   * @returns {Observable<Object>}
   */
  updateDescription(id: number, description: string) {
    const apiUrl = environment.api_base_url + '/locations/' + id + '/description';
    let sub = this.http.put(apiUrl, description);
    sub.subscribe(() => {this.refreshLocation(id);})
    return sub;
  }

  /**
   * Get measurement data for a given timespan.
   * @param id Location ID
   * @param startDate Start date
   * @param endDate End date
   * @param include What data to include in the result.
   * @param include.referenceMeasurements Whether to include referenceMeasurements or only regular measurements
   * @param include.waterLevel Whether to include water level series (both in regular measurements as well as referenceMeasurements)
   * @param include.airWaterMeasurements Whether to include air+water temp+pressure series (both in regular measurements as well as referenceMeasurements)
   * @param include.flowMeasurements Whether to include flowmeter series (both in regular measurements as well as referenceMeasurements)
   * @param include.invalidated Whether to include invalidated data.
   */
   getMeasurements<
    TReferenceMeasurements extends boolean,
    TWaterLevel extends boolean,
    TAirWater extends boolean,
    TFlow extends boolean,
    TInvalidated extends boolean,
    TOnlyValidated extends boolean,
  >(
    id: LocationID,
    startDate: moment.Moment | null,
    endDate: moment.Moment | null,
    include: MeasurementDataOptions<TReferenceMeasurements, TWaterLevel, TAirWater, TFlow, TInvalidated, TOnlyValidated>,
  ) {
    const apiUrl = environment.api_base_url + '/locations/' + id + '/measurements';

    const params: Record<string, string> = {
      includeReferences: include.referenceMeasurements ? "1" : "0",
      includeAirWater: include.airWaterMeasurements ? "1" : "0",
      includeFlow: include.flowMeasurements ? "1" : "0",
      includeWaterLevel: include.waterLevel ? "1" : "0",
      includeInvalidated: include.invalidated ? "1" : "0",
      onlyValidated: include.onlyValidated ? "1" : "0",
    };

    if (startDate) {
      params.startDate = startDate.toISOString();
    }

    if (endDate) {
      params.endDate = endDate.toISOString();
    }

    return this.http.get<MeasurementData<TReferenceMeasurements, TWaterLevel, TAirWater, TFlow, TInvalidated, TOnlyValidated>>(apiUrl, {
      params,
    });
  }

  addValidationEntry(locationId: number, validationEntry: LocationMeasurementsValidations){
    const apiUrl = environment.api_base_url + '/locations/' + locationId + '/measurements/manual-validation'
    return this.http.post(apiUrl, validationEntry, {responseType: 'text'});
  }

  /**
   * Get log of location
   * @param {number} id Location ID
   * @returns {Observable<Object>}
   */
  getLog(id: LocationID) {
    const apiUrl = environment.api_base_url + '/locations/' + id + '/log';
    return this.http.get(apiUrl);
  }

  /**
   * Get CSV data for the given timespan.
   * @param {number} id Location ID
   * @param {moment.Moment} startDate Start date
   * @param {moment.Moment} endDate End date
   * @returns {Observable<string>}
   */
  getCSV(id: LocationID, startDate: moment.Moment, endDate: moment.Moment, includeInvalidated: boolean, includeReferences: boolean, includeAirWater: boolean) {
    const apiUrl = environment.api_base_url + '/locations/' + id + '/measurements/csv';

    var params: any = {
      includeInvalidated: includeInvalidated,
      includeReferences: includeReferences,
      includeAirWater: includeAirWater,
    };

    if (endDate != null) {
      params.before = moment(endDate).toISOString();
    }

    if (startDate != null) {
      params.after = moment(startDate).toISOString();
    }

    return this.http.get(apiUrl, {responseType: 'blob', params: params});
  }

    /**
   * Get XLSX data for the given timespan.
   * @param {number} id Location ID
   * @param {moment.Moment} startDate Start date
   * @param {moment.Moment} endDate End date
   * @returns {Observable<string>}
   */
  getXLSX(id: LocationID, startDate: moment.Moment, endDate: moment.Moment, includeInvalidated: boolean, includeReferences: boolean, includeAirWater: boolean) {
    const apiUrl = environment.api_base_url + '/locations/' + id + '/measurements/xlsx';

    var params: any = {
      includeInvalidated: includeInvalidated,
      includeReferences: includeReferences,
      includeAirWater: includeAirWater,
    };

    if (endDate != null) {
      params.before = moment(endDate).toISOString();
    }

    if (startDate != null) {
      params.after = moment(startDate).toISOString();
    }

    return this.http.get(apiUrl, {responseType: 'blob', params: params});
  }

  addLogEntry(locationID, entry) {
    const apiUrl = environment.api_base_url + '/locations/' + locationID + '/log';
    return this.http.post(apiUrl, entry, {responseType: 'text'});
  }

  delLogEntry(locationID, logID) {
    const apiUrl = environment.api_base_url + '/locations/' + locationID + '/log/' + logID;
    return this.http.delete(apiUrl, {responseType: 'text'});
  }

  getAnnotations(locationID: number): Observable<LocationAnnotation[]> {
    const apiUrl = environment.api_base_url + '/locations/' + locationID + '/annotations';
    return this.http.get<LocationAnnotation[]>(apiUrl);
  }

  addAnnotation(locationID: number, annotation: LocationAnnotation): Observable<number> {
    const apiUrl = environment.api_base_url + '/locations/' + locationID + '/annotations';
    const response = this.http.post(apiUrl, annotation, {responseType: 'text'});
    return response.pipe(map(id => Number.parseInt(id)));
  }

  delAnnotation(locationID: number, annotationID: number) {
    const apiUrl = environment.api_base_url + '/locations/' + locationID + '/annotations/' + annotationID;
    return this.http.delete(apiUrl, {responseType: 'text'});
  }

  setReferenceLevels(locationID, referenceLevels: ReferenceLevels): Observable<string> {
    const apiUrl = environment.api_base_url + '/locations/' + locationID + '/referencelevels';
    return this.http.put(apiUrl, referenceLevels, {responseType: 'text'});
  }

  getReferenceLevels(locationID): Observable<Object> {
    const apiUrl = environment.api_base_url + '/locations/' + locationID + '/referencelevels';
    return this.http.get(apiUrl);
  }
}
