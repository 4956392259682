/// <reference types="@types/googlemaps" />
import {Component, OnInit, NgZone, ViewChild, ElementRef, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import { Router } from '@angular/router';
import { MapsAPILoader, LatLngBounds, AgmMap } from '@agm/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './../auth/auth.service';
import { Observable, Subject, of, combineLatest } from 'rxjs';


import * as introJs from 'intro.js/intro.js';
import { UserService } from 'app/services/user.service';
import { LocalStorageService } from 'app/services/localstorage.service';
import { SEARCH_LOCATION_BY_NAME, PROJECT_BLIK20KRIMP001, DASHBOARD_MAP_MULTI_SELECT, DASHBOARD_LOCATIONS_COMPARISON, PROJECT_BLIK21BERGE001, VIEW_PUBLIC_BRO_DATA, DASHBOARD_SATELLITE_BASE_LAYER } from 'app/services/permission-definitions';
import { first, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { LocationService, Location, BlikLocation, BroLocation } from "../services/location.service";
import { ClusterStyle } from '@agm/js-marker-clusterer/services/google-clusterer-types';
import { BlikLocationGroup, LocationGroupService } from 'app/services/location-group.service';

// If the zoom level is less than this when flying to a search result, zoom to this level
const MIN_ZOOM_LEVEL_FLY_TO = 16;

interface AutocompleteLocation<T extends Location> {
  id: T['id'],
  lat: number,
  lng: number,
  name: string,
  address: string,
  addressGmapsUrl: string,
  location: T,
}

@Component({
  selector: 'app-location-map',
  templateUrl: './location-map.component.html',
  styleUrls: ['./location-map.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationMapComponent implements OnInit, OnDestroy {
  @ViewChild('autocomplete', { static: false }) autocomplete;

  private latLngBounds: LatLngBounds;
  // Lat/long of the center of the locations
  latitude: number;
  longitude: number;
  zoom: number;
  mapTypeId: 'roadmap' | 'satellite' | 'hybrid' | 'terrain' = 'roadmap';

  // Reference to the native map instance
  private map: AgmMap;

  public searchByNamePermission: Observable<boolean>;
  public multiSelectPermission: Observable<boolean>;
  public viewPublicBroDataPermission: Observable<boolean>;
  public showLayerSelect$: Observable<boolean>;
  public showLocationGroupSelect = false;
  public canCompareLocations = false;
  public showBroLocations = false;
  public selectedLocationGroupId: string = "0";

  // Used for autocomplete
  @ViewChild('search', {static: false})
  public searchElementRef: ElementRef;

  public detailWindowsOpen = new Map<Number, Boolean>();
  drawingManager: google.maps.drawing.DrawingManager;
  selectionPolygon: google.maps.Polygon | null;
  selectionInfoWindow: google.maps.InfoWindow | null;
  selectedLocations: AutocompleteLocation<Location>[] = [];

  searchKeyword: keyof AutocompleteLocation<Location> = "name";

  // Style for the map marker clusters.
  // This should have absolutely been CSS or at least part of the template,
  // but we have to define it here in the component due to utterly stupid design of js-marker-cluster
  public clusterStyles: ClusterStyle[] = [
    {width: 53, height: 53},
    {width: 56, height: 56},
    {width: 66, height: 68},
    {width: 78, height: 80},
    {width: 90, height: 92}
  ].map((v, i) => {return {
    url: "assets/images/agm/m" + (i+1) + ".png",
    textColor: "#ffffff",
    textSize: 14,
    ...v
  }});

  // Cached on the class so the user can click the back button and initiate the map from these boundaries
  private static cachedBounds;
  address: any;

  // Tutorial framework
  private introJS = introJs();
  private markersLoaded: boolean = false;

  constructor(private router: Router,
    public auth: AuthService,
    private http: HttpClient,
    private mapsAPILoader: MapsAPILoader,
    private localStorageService: LocalStorageService,
    private ngZone: NgZone,
    private userService: UserService,
    private locationService: LocationService,
    private locationGroupService: LocationGroupService,
    private changeDetector: ChangeDetectorRef,
  ) {
    // Initialize the bounds from cache, if available
    this.latLngBounds = LocationMapComponent.cachedBounds;
    this.searchByNamePermission = userService.userHasPermission(SEARCH_LOCATION_BY_NAME);
    this.multiSelectPermission = userService.userHasPermission(DASHBOARD_MAP_MULTI_SELECT);
    this.viewPublicBroDataPermission = userService.userHasPermission(VIEW_PUBLIC_BRO_DATA);
    this.showLayerSelect$ = userService.userHasPermission(DASHBOARD_SATELLITE_BASE_LAYER);
    userService.userHasPermission(DASHBOARD_LOCATIONS_COMPARISON).pipe(takeUntil(this.ngUnsubscribe)).subscribe(v => this.canCompareLocations = v);
  }

  locations: AutocompleteLocation<BlikLocation>[] = [];
  visibleLocations: AutocompleteLocation<BlikLocation>[];
  locations$: Observable<AutocompleteLocation<BlikLocation>[]>;
  broLocations: AutocompleteLocation<BroLocation>[] = [];
  broLocations$: Observable<AutocompleteLocation<BroLocation>[]>;
  allLocations: AutocompleteLocation<Location>[] = [];
  allLocations$: Observable<[AutocompleteLocation<BlikLocation>[], AutocompleteLocation<BroLocation>[]]>;
  locationGroups: BlikLocationGroup[];
  locationGroups$: Observable<BlikLocationGroup[]>;

  private ngUnsubscribe = new Subject();

  ngOnInit() {
    this.loadAutoComplete();
    this.locations$ = this.locationService.getLocations()
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(map(d => this.sortByNameAscending(this.processLocationData(d))));

    this.locations$.subscribe((v) => {
      this.locations = v;
      this.visibleLocations = v.filter(l => this.isLocationVisible(l));
      this.changeDetector.detectChanges();
    });

    this.locationGroups$ = this.locationGroupService.getAll()
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(map(d => this.sortByNameAscending(d.filter(group => group.locationIds.length))));

    this.locationGroups$.subscribe((v) => {
      this.locationGroups = v;
      this.showLocationGroupSelect = v.length > 1;
    });

    combineLatest([this.locations$, this.locationGroups$]).subscribe(() => {
      // setTimeout() is a workaround to defer until after all elements needed for the tour have been rendered.
      // Otherwise, the elements that the tutorial wants to point at cannot be found
      setTimeout(() => this.initialStartTour());
    });

    this.viewPublicBroDataPermission.pipe(take(1)).subscribe(canViewPublicBroData => {
      if (canViewPublicBroData) {
        this.broLocations$ = this.locationService.getBroLocations().pipe(takeUntil(this.ngUnsubscribe), switchMap((d) => of(this.processLocationData(d))));
      }
      else {
        this.broLocations$ = of([]);
      }
      this.broLocations$.subscribe((v) => {
        this.broLocations = v;
        this.changeDetector.detectChanges();
      });

      this.allLocations$ = combineLatest([this.locations$, this.broLocations$]).pipe(takeUntil(this.ngUnsubscribe));
      this.allLocations$.subscribe(([locations, broLocations]) => {
        this.allLocations = this.sortByNameAscending([...locations, ...broLocations]);
        this.changeDetector.detectChanges();
      });
    });

    this.changeDetector.detectChanges();

    this.localStorageService.observeUserValue("locationMapShowBroLocations").subscribe(showBroLocations => {
      this.showBroLocations = showBroLocations === "true"
    });

    combineLatest([this.localStorageService.observeUserValue("locationMapBaseLayer"), this.showLayerSelect$]).subscribe(([baseLayer, showLayerSelect]) => {
      if (!showLayerSelect) {
        this.mapTypeId = 'roadmap';
        return;
      }

      switch (baseLayer) {
        case 'hybrid':
        case 'roadmap':
        case 'satellite':
        case 'terrain':
          this.mapTypeId = baseLayer;
          break;

        default:
          this.mapTypeId = 'roadmap';
          break;
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private loadAutoComplete() {
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"]
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.flyToLatLong(place.geometry.location.lat(), place.geometry.location.lng());
        });
      });
    });
  }

  getFitBounds() {
    if(this.latLngBounds === undefined) {
      return true;
    } else {
      return this.latLngBounds;
    }
  }

  private flyToLatLong(lat: number, long: number) {
    this.latitude = lat;
    this.longitude = long;
    if (this.zoom === undefined || this.zoom < MIN_ZOOM_LEVEL_FLY_TO) {
      this.zoom = MIN_ZOOM_LEVEL_FLY_TO;
    }
  }

  onMapReady(map: AgmMap) {
    // Store a reference to the native map
    this.map = map;
    this.initDrawingManager(map);
  }

  initDrawingManager(map: any) {
    const options: google.maps.drawing.DrawingManagerOptions = {
      drawingControl: false,
      polygonOptions: {
        draggable: true,
        editable: true,
        /*geodesic: true,*/
        strokeColor: "#3C78BD",
        fillColor: "#3C78BD",
      },
      drawingMode: null
    };

    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    this.drawingManager.setMap(map);

    this.selectionInfoWindow = new google.maps.InfoWindow();

    google.maps.event.addListener(this.drawingManager, 'polygoncomplete', this.polygonDrawComplete.bind(this));
  }

  polygonDrawComplete(polygon: google.maps.Polygon) {
    this.drawingManager.setDrawingMode(null);
    this.selectionPolygon = polygon;
    const listener = (() => this.updateSelectionPolygon(polygon)).bind(this);
    google.maps.event.addListener(polygon.getPath(), "set_at", listener);
    google.maps.event.addListener(polygon.getPath(), "insert_at", listener);
    this.updateSelectionPolygon(polygon);
  }

  updateSelectionPolygon(polygon: google.maps.Polygon) {
    this.selectedLocations = [...this.visibleLocations, ...(this.showBroLocations ? this.broLocations : [])]
      .filter(location => google.maps.geometry.poly.containsLocation(new google.maps.LatLng(location.lat, location.lng), polygon));

    const bounds = new google.maps.LatLngBounds();
    polygon.getPath().forEach(e => bounds.extend(e));
    this.selectionInfoWindow.setPosition(bounds.getCenter());

    this.selectionInfoWindow.open(polygon.getMap());
    this.selectionInfoWindow.addListener("closeclick", this.clearSelection.bind(this));

    let content = `${this.selectedLocations.length} locaties`;

    if (this.canCompareLocations) {
      // Bit of a hack, to call a function on this Angular component from a plain HTML button
      window["_locationMapSelectionCompare"] = this.compareSelection.bind(this);
      content += "<br /><button onClick='_locationMapSelectionCompare()'>Vergelijken</button>";
    }

    this.selectionInfoWindow.setContent(content);
  }

  clearSelection() {
    if (this.selectionPolygon != null) {
      this.selectionPolygon.setMap(null);
      this.selectionPolygon = null;
    }
    this.selectionInfoWindow.close();
  }

  drawSelectionPolygon() {
    this.clearSelection();
    this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
  }

  compareSelection() {
    this.router.navigate(['vergelijk', this.selectedLocations.map(l => l.id).join(',')]);
  }

  onNavigateToDetails(id: number) {
    this.router.navigate(['/location-detail', id]);
  }

  onNavigateToBroDetails(id: string) {
    this.router.navigate(['/bro-location-detail', id]);
  }

  initialStartTour() {
    this.localStorageService.observeUserValue("locationMapTutorialCompleted").subscribe(completed => {
      if (!completed) {
        this.startTour();
      }
    });
  }

  startTour() {
    this.userService.permissions.pipe(first()).subscribe(permissions => {
      let multipleMenuOptions = true;
      let portalCompany = "Blik";

      // Client-specific dashboard versions

      if (permissions.indexOf(PROJECT_BLIK20KRIMP001) > -1) {
        portalCompany = "gemeente Krimpenerwaard";
        // Pretty ugly, but found no easy other option. This project has no menu options other than 'Kaart'.
        multipleMenuOptions = false;
      }
      else if (permissions.indexOf(PROJECT_BLIK21BERGE001) > -1) {
        portalCompany = "gemeente Berg en Dal";
        // Pretty ugly, but found no easy other option. This project has no menu options other than 'Kaart'.
        multipleMenuOptions = false;
      }

      let searchLocationByName = permissions.indexOf(SEARCH_LOCATION_BY_NAME) > -1;
      let selectMultipleLocations = permissions.indexOf(DASHBOARD_MAP_MULTI_SELECT) > -1;

      this.createIntroSteps(portalCompany, multipleMenuOptions, searchLocationByName, selectMultipleLocations, this.showLocationGroupSelect);
      this.introJS.start();
      this.localStorageService.saveUserValue("locationMapTutorialCompleted", "true");
    });
  }

  createIntroSteps(portalCompany: string, multipleMenuOptions: boolean, searchLocationByName: boolean, selectMultipleLocations: boolean, showLocationGroupSelect: boolean) {
    let steps: {intro: string, element?: string|Element}[] = [
      {
        intro:
          "Welkom op het grondwaterdata-portal van " + portalCompany + "!<br />" +
          "Met deze virtuele rondleiding maken we u wegwijs in de portal."
      },
      {
        intro: "Deze rondleiding wordt eenmalig getoond bij het eerste keer bezoeken van de website of na inloggen, waarna de rondleiding is aangepast op de rechten van de ingelogde gebruiker. Daarna is deze op elk moment te raadplegen door op het vraagteken rechtsbovenin te klikken."
      },
      {
        intro: "Voor een optimale gebruikerservaring raden wij aan om Google Chrome of Firefox te gebruiken."
      },
    ];


    if (multipleMenuOptions) {
      steps.push({
        element: '#side-menu',
        intro:
          "Via het menu links kunt u naar de verschillende pagina's navigeren.<br />" +
          "Per pagina start een rondleiding specifiek voor de pagina."
      });
    }

    steps.push(
      {
        intro:
          "Op deze pagina worden alle meetlocaties op de kaart getoond.<br />" +
          "Zoomen kan met het scrollwiel van de muis."
      },
      {
        element: "#address-search-input",
        intro: "Er kan gezocht worden op straatnaam en plaats."
      }
    );

    if (searchLocationByName) {
      steps.push({
        element: "#location-search-input",
        intro: "Er kan ook gezocht worden op de naam van de meetlocatie."
      });
    }

    steps.push(
      {
        element: document.querySelector("agm-map img[usemap]"),
        intro: "Door op een meetlocatie te klikken wordt de naam en adresinformatie van de meetlocatie getoond en kunnen de details geopend worden."
      },
    );

    if (showLocationGroupSelect) {
      steps.push(
        {
          element: "#location-group-select",
          intro: "Te veel locaties op de kaart? Kies hier een groep om op te focussen."
        },
      );
    }

    if (selectMultipleLocations) {
      steps.push({
          element: "#draw-polygon",
          intro: "Met deze knop kan een gebied op de kaart getekend worden. De locaties binnen dit gebied kunnen dan vergeleken worden."
      });
    }

    steps.push({
        element: '#map-tour-start',
        intro: "De rondleiding kan op elk moment herstart worden met behulp van de ?-knop."
    });

    this.introJS.setOptions({
      nextLabel: "Volgende",
      prevLabel: "Vorige",
      skipLabel: "Afsluiten",
      doneLabel: "Klaar",
      showStepNumbers: false,
      overlayOpacity: 0.5,
      steps: steps
    });
  }

  onBoundsChange(bounds: LatLngBounds) {
    if(this.markersLoaded) {
      LocationMapComponent.cachedBounds = bounds;
    }
  }

  processLocationData<T extends Location>(locationData: T[]): AutocompleteLocation<T>[] {
    const result = [];

    for (const location of locationData) {
      if (location.latitude && location.longitude) {
        result.push({
          id: location.id,
          lat: location.latitude,
          lng: location.longitude,
          name: location.name,
          address: location.address,
          addressGmapsUrl: "https://www.google.com/maps/search/?api=1&query=" + encodeURIComponent(location.address),
          location: location,
        });
      }
    }

    this.markersLoaded = true;

    return result;
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  selectInListEvent(item) {
    this.flyToLatLong(item.lat, item.lng);
    this.detailWindowsOpen.set(item.id, true);
    this.changeDetector.detectChanges();
  }

  markerClickEvent(item) {
    this.detailWindowsOpen.set(item.id, true);
    this.changeDetector.detectChanges();
  }

  resetLocationGroupId() {
    this.selectedLocationGroupId = "0";
    this.selectLocationGroupId();

    window.setTimeout(() => {
      this.autocomplete.focus();
      this.autocomplete.open();
    }, 500);
  }

  selectLocationGroupId() {
    this.detailWindowsOpen.clear();

    this.visibleLocations = [];
    this.changeDetector.detectChanges();

    this.visibleLocations = this.locations.filter(l => this.isLocationVisible(l));
    this.changeDetector.detectChanges();

    if (this.selectionPolygon) {
      this.updateSelectionPolygon(this.selectionPolygon);
    }
  }

  isLocationVisible(location: BlikLocation) {
    if (!this.selectedLocationGroupId) {
      return true;
    }

    const id = Number.parseInt(this.selectedLocationGroupId);

    if (!id) {
      return true;
    }

    const group = this.locationGroups.find(g => g.id === id);

    if (!group) {
      return true;
    }

    return group.locationIds.includes(location.id);
  }

  toggleBroLocations() {
    this.localStorageService.saveUserValue("locationMapShowBroLocations", this.showBroLocations.toString());

    if (this.selectionPolygon) {
      this.updateSelectionPolygon(this.selectionPolygon);
    }
  }

  locationMapBaseLayerChange(event) {
    const newMapTypeId = event.target.value;
    this.localStorageService.saveUserValue('locationMapBaseLayer', newMapTypeId);
  }

  private sortByNameAscending<T extends { name?: string }>(items: T[]): T[] {
    return [...items].sort((a, b) => a.name.localeCompare(b.name, 'nl', { sensitivity: 'base', numeric: true, usage: 'sort' }));
  }
}
