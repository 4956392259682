export class Position {
  latitudeMicrodegreesNorth: number;
  longitudeMicrodegreesEast: number;
  altitudeMillimeters: number;

  constructor(latDegrees: number, longDegrees: number) {
    this.latitudeMicrodegreesNorth = latDegrees * 1000000;
    this.longitudeMicrodegreesEast = longDegrees * 1000000;
  }

  static distanceToInMeter(one: Position, other: Position): number {
    let R = 6371000; // Radius of the earth in meters

    let latDistance = Position.getLatitudeRadians(other) - Position.getLatitudeRadians(one);
    let lonDistance = Position.getLongitudeRadians(other) - Position.getLongitudeRadians(one);

    let a = Math.sin(latDistance / 2) * Math.sin(latDistance / 2)
          + Math.cos(Position.getLatitudeRadians(one)) * Math.cos(Position.getLatitudeRadians(other))
            * Math.sin(lonDistance / 2) * Math.sin(lonDistance / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let distance = R * c;

    // Approximation...
    let height = Position.getAltitudeMeters(one) - Position.getAltitudeMeters(other);
    if(height !== undefined && height != null && !isNaN(height)) {
      distance = Math.sqrt(distance * distance + height * height);
    }

    return distance;
  }

  static getLatitudeRadians(one: Position) {
    if(one.latitudeMicrodegreesNorth === undefined) return undefined;
    return one.latitudeMicrodegreesNorth / 1000000 * 0.017453292519943295;
  }

  static getLongitudeRadians(one: Position) {
    if(one.longitudeMicrodegreesEast === undefined) return undefined;
    return one.longitudeMicrodegreesEast / 1000000 * 0.017453292519943295;
  }

  static getAltitudeMeters(one: Position) {
    if(one.altitudeMillimeters === undefined) return undefined;
    return one.altitudeMillimeters * 1000;
  }
}
