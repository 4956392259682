import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Cacheable } from "app/shared/cacheable";
import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";

import { environment } from "../../environments/environment";


export interface BlikLocationGroup {
    id: number;
    locationIds: number[];
    name: string;
    description: string;
}

export interface BlikLocationGroupDetails extends BlikLocationGroup {
    interpretation: string;
    files: UserFile[];
}

export interface UserFile {
    id: string;
    mimeType: string;
    filename: string;
    length: number;
    creatorId: number;
    creationTime: string;
    canDelete: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class LocationGroupService {

    private _all: Cacheable<BlikLocationGroup[]> | null = null;

    private _details: Map<number, Cacheable<BlikLocationGroupDetails>> = new Map();


    constructor(private http: HttpClient) {
    }

    getAll(): Observable<BlikLocationGroup[]> {
        if (this._all) {
            return this._all.get();
        }

        const apiUrl = environment.api_base_url + '/locations/groups/';

        this._all = new Cacheable<BlikLocationGroup[]>(this.http.get<BlikLocationGroup[]>(apiUrl));

        return this._all.get();
    }

    getDetails(id: number): Observable<BlikLocationGroupDetails | null> {
        if (this._details.has(id)) {
            return this._details.get(id).get();
        }

        const apiUrl = `${environment.api_base_url}/locations/groups/${id}`;

        const details = new Cacheable<BlikLocationGroupDetails>(
            this.http.get<BlikLocationGroupDetails>(apiUrl)
                .pipe(catchError((error: HttpErrorResponse) => {
                    // This, ofcoure, is not proper errorhandling, but that is way out of scope, for now the page just has to not get stuck on "loading...".
                    return of<BlikLocationGroupDetails | null>(null);
                }))
                .pipe(map(d => {
                    if (!d) {
                        return null;
                    }

                    // Hack for flakey API (definitions) and missing fields.
                    return Object.assign<{}, BlikLocationGroupDetails, BlikLocationGroupDetails>({}, {
                        id,
                        locationIds: [],
                        name: '',
                        description: '',
                        interpretation: '',
                        files: [],
                    }, d);
                }))
        );

        this._details.set(id, details);

        return details.get();
    }

    refresh(id?: number) {
        if (this._all) {
            this._all.refresh();
        }

        if (id !== undefined && this._details.has(id)) {
            this._details.get(id).refresh();
        }
    }

    /**
     * Updates a location group.
     * @param id - The id of the location group to update.
     * @param updates - An object containing the properties to update.
     * @returns An observable which updates with the new name.
     */
     update(id: number, updates: Partial<Omit<BlikLocationGroupDetails, 'id'>>) {
        const apiUrl = `${environment.api_base_url}/locations/groups/${id}`;

        const sub = this.http.patch<never>(apiUrl, updates);
        sub.subscribe(() => { this.refresh(id); })
        return sub;
    }
};
