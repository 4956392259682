import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from "../../auth/auth.service";
import {LocationUserSettings, UserService} from "../../services/user.service";
import {NotifierService} from "angular-notifier";
import {Subscription} from "rxjs";
import {LocationService} from "../../services/location.service";

enum WatchMeasurements {
  NONE = "NONE",
  VALIDONLY = "VALIDONLY",
  ALL = "ALL",
}

@Component({
  selector: 'location-user-settings',
  templateUrl: './location-user-settings.component.html',
  styleUrls: ['./location-user-settings.component.css']
})
export class LocationUserSettingsComponent implements OnInit, OnDestroy {
  @Input() locationID: number;



  _settings: LocationUserSettings;
  helperObserve: WatchMeasurements;
  _sub: Subscription;

  constructor(
    public auth: AuthService,
    public user: UserService,
    private notifier: NotifierService,
    private locationService: LocationService,
  ) {

  }

  ngOnInit() {
    this._sub = this.user.getMyLocationSettings(this.locationID).subscribe((settings: LocationUserSettings) => {
      this._settings = settings;
      if(settings.enableEmailWaterLevelNotifications) {
        if(!settings.includeInvalidatedMeasurements) {
          this.helperObserve = WatchMeasurements.VALIDONLY
        } else {
          this.helperObserve = WatchMeasurements.ALL;
        }
      } else {
        this.helperObserve = WatchMeasurements.NONE
      }
    })
  }

  ngOnDestroy() {
    if(this._sub) {
      this._sub.unsubscribe();
      this._sub = undefined;
    }
  }

  saveSettings() {
    this.user.setMyLocationSettings(this.locationID, this._settings).subscribe(
      data => {
        this.notifier.notify('success', 'Instellingen geüpdatet.');
        this.locationService.refreshLocation(this.locationID);
      },
      error => {
        console.error(error);
        this.notifier.notify('error', "Updaten instellingen mislukt.");
      }
    )
  }

  applyWatchList(e: WatchMeasurements) {
    switch(e) {
      case WatchMeasurements.NONE: {
        this._settings.enableEmailWaterLevelNotifications = false;
        this._settings.includeInvalidatedMeasurements = false;
        break;
      }
      case WatchMeasurements.VALIDONLY: {
        this._settings.enableEmailWaterLevelNotifications = true;
        this._settings.includeInvalidatedMeasurements = false;
        break;
      }
      case WatchMeasurements.ALL: {
        this._settings.enableEmailWaterLevelNotifications = true;
        this._settings.includeInvalidatedMeasurements = true;
        break;
      }
    }
  }

}
