import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location, LocationStrategy } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ImpersonatorService {
  private _impersonatedUserId: number | null;

  constructor(private router: Router) {
    const params = new URLSearchParams(location.search);
    this._impersonatedUserId = +params.get("impersonate") || null;
  }

  startImpersonation(userId: number) {
    window.open(this.router.serializeUrl(
      this.router.createUrlTree([`/`], {queryParams: {impersonate: userId}})
    ), '_blank');
  }

  public get isImpersonating(): boolean {
    return this._impersonatedUserId != null;
  }

  public get impersonatedUserId() {
    return this._impersonatedUserId;
  }

  stopImpersonation() {
    if (confirm("Venster sluiten?")) {
      window.close();
    }
  }
}
