import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Observable, of} from "rxjs";
import {LocationWithStatistics, NotificationStatus} from "../../services/location.service";
import {WATER_LEVEL_EMAIL_NOTIFICATIONS} from "../../services/permission-definitions";
import {UserService} from "../../services/user.service";

class StatusItem {
  clazz: string;
  text: string;
  contenttext: string;
  permission: Observable<boolean>;
}

@Component({
  selector: 'location-status',
  templateUrl: './location-status.component.html',
  styleUrls: ['./location-status.component.css']
})
export class LocationStatusComponent implements OnChanges {

  @Input() location: LocationWithStatistics;
  @Input() showWaterLevel: boolean = false;
  public statusItems: StatusItem[];

  constructor(
    private userService: UserService,
  ) {
    this.statusItems = [];
  }

  ngOnChanges(changes: SimpleChanges) {
    this.statusItems = this.locationToLEDs(this.location);
  }

  locationToLEDs(location: LocationWithStatistics): StatusItem[] {
    var items: StatusItem[] = [];

    if(!location) {
      return items;
    }

    let perm = this.userService.userHasPermission(WATER_LEVEL_EMAIL_NOTIFICATIONS);
    switch (location.notificationStatus) {
      case NotificationStatus.UNKNOWN:
        items.push({
          clazz: "led notificationstatus unknown",
          text: "Waterstandstatus nog niet bekend",
          contenttext: "",
          permission: perm,
        });
        break;
      case NotificationStatus.OK:
        items.push({
          clazz: "led notificationstatus ok",
          text: "Waterstand tussen de limieten",
          contenttext: "",
          permission: perm,
        });
        break;
      case NotificationStatus.ABOVE_UPPER_LIMIT:
        items.push({
          clazz: "led notificationstatus aboveupper overtime error",
          text: "Waterstand boven de ingestelde bovengrens, langer dan de ingestelde tijdsdrempel",
          contenttext: "",
          permission: perm,
        });
        break;
      case NotificationStatus.BELOW_LOWER_LIMIT:
        items.push({
          clazz: "led notificationstatus belowlower overtime error",
          text: "Waterstand onder de ingestelde ondergrens, langer dan de ingestelde tijdsdrempel",
          contenttext: "",
          permission: perm,
        });
        break;
      case NotificationStatus.ABOVE_UPPER_LIMIT_BELOW_TIME_LIMIT:
        items.push({
          clazz: "led notificationstatus aboveupper belowtime warning",
          text: "Waterstand boven de ingestelde bovengrens, korter dan de ingestelde tijdsdrempel",
          contenttext: "",
          permission: perm,
        });
        break;
      case NotificationStatus.BELOW_LOWER_LIMIT_BELOW_TIME_LIMIT:
        items.push({
          clazz: "led notificationstatus belowlower belowtime warning",
          text: "Waterstand onder de ingestelde ondergrens, korter dan de ingestelde tijdsdrempel",
          contenttext: "",
          permission: perm,
        });
        break;
      default: {
        items.push({
          clazz: "led notificationstatus inactive",
          text: "Waterstand wordt niet over bericht",
          contenttext: "",
          permission: perm,
        });
        break;
      }
    }

    if(this.showWaterLevel && location.currentGroundWaterLevel) {
      items.push({
        clazz: "text double waterlevel",
        text: "Huidige waterstand ten opzichte van maaiveld",
        contenttext: "" + location.currentGroundWaterLevel.toFixed(2) + "m",
        permission: of(true),
      });
    }

    return items;
  }

}
