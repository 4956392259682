import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'location-log-author-type',
  templateUrl: './location-log-author-type.component.html',
  styleUrls: ['./location-log-author-type.component.css']
})
export class LocationLogAuthorTypeComponent implements OnInit {

  @Input() type: string;

  pictureSource: string;
  style: {src: string, class: string};

  settingsMap = { 'admin': { src: "assets/img/feather/star.svg", class: 'admin' }
                , 'auto-failure': { src: "assets/img/feather/x-square.svg", class: 'auto-failure' }
                , 'auto-success': { src: "assets/img/feather/check-square.svg", class: 'auto-success' }
                , 'auto-warning': { src: "assets/img/feather/square.svg", class: 'auto-warning' }
                , 'user': { src: "assets/img/feather/message-square.svg", class: 'user' }
                , 'unknown': { src: "assets/img/feather/square.svg", class: 'unknown' }
                , 'node_installation': { src: "assets/img/feather/download.svg", class: 'admin' }
                , 'node_removal': { src: "assets/img/feather/upload.svg", class: 'admin' }
                , 'validation_result_invalid': { src: "assets/img/feather/alert-circle.svg", class: 'auto-warning' }
                }

  constructor() { }

  ngOnInit() {
    this.style = this.settingsMap[this.type] || this.settingsMap['unknown'];
  }

  ngOnChange(changes) {
    if(changes.type) {
      this.style = this.settingsMap[this.type] || this.settingsMap['unknown'];
    }
  }

}
