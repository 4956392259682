<ng-template #itemTemplate let-item>
    <a [innerHTML]="item.name"></a>
</ng-template>

<ng-template #notFoundTemplate let-notFound>
    <div class="alert alert-warning">
        <span [innerHtml]="notFound"></span><br/>
        <a *ngIf="showLocationGroupSelect && selectedLocationGroupId !== '0'" (click)="resetLocationGroupId()" class="alert-link">Zoeken in alle groepen?</a>
    </div>
</ng-template>

<div class="row map-header-row gray-bg">
    <div class="col-xs-10 col-md-5 col-lg-3" id="address-search-input">
        <label>Zoek op adres:</label>
        <input #search type="text" class="form-control" (keydown.enter)="$event.preventDefault()" placeholder="Straatnaam en plaats" autocorrect="off" autocapitalize="off" spellcheck="off">
    </div>
    <div class="col-xs-10 col-md-5 col-lg-3" id="location-search-input">
        <ng-container *ngIf="this.searchByNamePermission | async">
            <label>Zoek op meetlocatie:</label>
            <ng-autocomplete
                #autocomplete
                [data]="visibleLocations"
                [searchKeyword]="searchKeyword"
                placeHolder="Naam meetlocatie"
                initialValue=""
                [isLoading]="(locations$ | async) === null"
                [itemTemplate]="itemTemplate"
                [notFoundTemplate]="notFoundTemplate"
                notFoundText="Geen peilbuizen gevonden."
                (selected)='selectInListEvent($event)'>
            </ng-autocomplete>
        </ng-container>
    </div>
    <div class="btn-group btn-group-toggle d-flex flex-column flex-md-row top-right text-right">
        <select *ngIf="showLocationGroupSelect" class="btn btn-default m-sm m-l-none" [(ngModel)]="selectedLocationGroupId" (change)="selectLocationGroupId($event)" id="location-group-select">
            <option [value]=0>(alle locaties)</option>
            <option *ngFor="let k of locationGroups$ | async" [value]="k.id">{{k.name}}</option>
        </select>
        <label *ngIf="viewPublicBroDataPermission | async" class="btn btn-default  m-sm m-l-none">
            <input class=check-inline id="broActiveToggle" type="checkbox" (change)=toggleBroLocations($event) [(ngModel)]="showBroLocations" autocomplete="off" /> BRO-locaties
        </label>
        <button *ngIf="multiSelectPermission | async" class="btn btn-default m-sm m-l-none" (click)="drawSelectionPolygon()" id="draw-polygon" title="Selectie tekenen op de kaart"><i class="fa fa-object-group"></i></button>
        <button class="btn btn-default m-sm m-l-none " (click)="startTour()" id="map-tour-start">?</button>
    </div>
</div>
<div class="map-row">
    <label *ngIf="showLayerSelect$ | async" class="sr-only" for="map-layer-select">Kaarttype:</label>
    <select *ngIf="showLayerSelect$ | async" class="btn btn-default" [ngModel]="mapTypeId" (change)="locationMapBaseLayerChange($event)" id="map-layer-select">
        <option value="roadmap">Kaart</option>
        <option value="hybrid">Satelliet met labels</option>
        <option value="satellite">Satelliet</option>
    </select>
    <agm-map [mapTypeId]=mapTypeId [(zoom)]=zoom [latitude]=latitude [longitude]=longitude (mapReady)="onMapReady($event)" (boundsChange)=onBoundsChange($event) [fitBounds]=getFitBounds()>
        <ng-container *ngIf="visibleLocations && visibleLocations.length > 0"><!-- workaround for clustering triggering for each individual marker removal:  https://github.com/sebholstein/angular-google-maps/issues/1285#issuecomment-483696738 -->
            <agm-marker-cluster
                [maxZoom]=15
                [minimumClusterSize]=10
                [averageCenter]=true
                [gridSize]=60
                [styles]=clusterStyles
            >
                <agm-marker
                    *ngFor="let location of visibleLocations"
                    [latitude]=location.lat [longitude]=location.lng
                    [zIndex]="2"
                    [title]=location.name
                    [agmFitBounds]="true"
                    (markerClick)="markerClickEvent(location)"
                >
                    <agm-info-window *ngIf="detailWindowsOpen.get(location.id)" [isOpen]="detailWindowsOpen.get(location.id)">
                        <location-status [location]="location.location"></location-status>
                        <strong>{{ location.name }}</strong><br>
                        <a *ngIf="location.address" href="{{location.addressGmapsUrl}}" target="_blank"> {{ location.address }} </a><br>
                        <strong><a (click)=onNavigateToDetails(location.id)>Naar details</a></strong>
                    </agm-info-window>
                </agm-marker>
            </agm-marker-cluster>
        </ng-container>
        <!-- Keep BRO locations outside of the clusters because they are all across the country while people's locations are not, for most customers  -->
        <!-- Once WB-731 is resolved, this is no longer needed. -->
        <!-- The correct marker size is 26×43, but we halve it such that Blik markers stand out better -->
        <agm-marker
            *ngFor="let location of (broLocations$ | async)"
            [latitude]=location.lat [longitude]=location.lng
            [iconUrl]="{url: 'assets/images/agm/spotlight-poi2_hdpi_green.png', scaledSize: {width: 13.5, height: 21.5}}"
            [zIndex]="1"
            [title]=location.name
            [agmFitBounds]="false"
            (markerClick)="markerClickEvent(location)"
            [visible]="showBroLocations"
        >
            <agm-info-window *ngIf="detailWindowsOpen.get(location.id)" [isOpen]="detailWindowsOpen.get(location.id)">
                <location-status [location]="location.location"></location-status>
                <strong>{{ location.name }}</strong><br>
                <a *ngIf="location.address" href="{{location.addressGmapsUrl}}" target="_blank"> {{ location.address }} </a><br>
                <strong><a (click)=onNavigateToBroDetails(location.id)>Naar details</a></strong>
            </agm-info-window>
        </agm-marker>
    </agm-map>
</div>
