<div>
  <div class="row">
    <div class="col-xs-11"><h1>Locaties vergelijken</h1></div>
    <div class="col-xs-1 text-right" ><button class="btn btn-default m-sm m-l-none " (click)="startTour()" id="location-overview-tour-start">?</button></div>
  </div>
  <div class="form-inline" id="location-add-controls">
    <select *ngIf="showLocationGroupSelect" class="form-control" [(ngModel)]="selectedLocationGroup" (change)="selectedLocationGroupChange()" aria-label="Locatiegroepen">
      <option [ngValue]="null">
        {{ loadingLocations ? "Laden..." : "Locatiegroep"}}
      </option>
      <option *ngFor="let locationGroup of locationGroups" [ngValue]="locationGroup.id">
        {{ locationGroup.name }}
      </option>
    </select>
    <select class="form-control" [(ngModel)]="selectedLocation" aria-label="Locatie toevoegen aan vergelijking" id="new-location" [disabled]="selectedLocationGroup === null">
      <option [ngValue]="null" disabled>
        {{ loadingLocations ? "Laden..." : (showLocationGroupSelect ? "Locatie in groep" : "Locatie toevoegen" )}}
      </option>
      <option *ngFor="let availableLocation of availableLocations" [ngValue]="availableLocation" [disabled]="isSelected(availableLocation.id)">
        {{ availableLocation.name }}
      </option>
    </select>
    <button class="btn btn-default" (click)="addLocation()" [disabled]="!selectedLocation">Locatie toevoegen</button>
    <ng-container *ngIf="this.csvPermission | async">
      <span class="separator"></span>
      <button class="btn btn-default" id="export-csv-button" (click)="exportCsv()">Exporteer CSVs</button>
      <button class="btn btn-default" id="export-xlsx-button" (click)="exportXlsx()">Exporteer XLSXs</button>
    </ng-container>
  </div>

  <div *ngIf="!loadingLocations && locations.length > 0">
    <div class="tags">
      <span class="tag label label-default"
            *ngFor="let location of locationData | isSelected:locations"
            (mouseenter)="highlight(location.id)"
            (mouseleave)="highlight(null)">
        <span>{{location.name}}</span>
        <a (click)="removeLocation(location.id)"><i class="remove glyphicon glyphicon-remove-sign glyphicon-white"></i></a>
      </span>
      <span class="tag label label-default"
            *ngFor="let location of broLocationData | isSelected:locations"
            (mouseenter)="highlight(location.id)"
            (mouseleave)="highlight()">
        <span>{{location.name}}</span>
        <a (click)="removeLocation(location.id)"><i class="remove glyphicon glyphicon-remove-sign glyphicon-white"></i></a>
      </span>
    </div>

    <div *ngIf="this.knmiPermission | async" class="row" id="location-description">
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 pull-right text-right">
        <div class="knmiIndicatorContainer">
          <blik-loader-small *ngIf="loadingKNMIData"></blik-loader-small>
          <span *ngIf="noKNMIData" class="glyphicon glyphicon-remove noDataIcon"></span>
        </div>
        <div class="knmiSelectorContainer">
          <label for="knmiStationSelector">KNMI-station</label>
          <select id="knmiStationSelector" class="form-control" [(ngModel)]="selectedKNMIStationIndex" (change)="onChangeKNMIStation($event)">
            <option *ngFor="let k of knmiStations; let i = index" [value]="i">{{k._desc}}</option>
          </select>
        </div>
      </div>
    </div>

    <app-waterlevel-compare
      [locations]=this.locations
      [locationData]=this.locationData
      [highlighted]=this.highlighted
      [knmiData]=knmiData
      (dateSelection)='dateSelect($event)'
      (dateRange)='onDateRangeChanged($event)'
    ></app-waterlevel-compare>
  </div>
</div>
