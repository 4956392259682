import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import { LocationService } from '../services/location.service';
import { UserService } from '../services/user.service';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { WRITE_LOCATION_LOGBOOK } from 'app/services/permission-definitions';

@Component({
  selector: 'location-log',
  templateUrl: './location-log.component.html',
  styleUrls: ['./location-log.component.css']
})
export class LocationLogComponent implements OnInit {

  @Input() locationID: number;

  showLog: boolean = true;
  logData: any[];
  newEntry: string;
  writeLocationLogbookPermission: Observable<boolean>;

  columns = [
    { parameter: 'type', header: "Type"}
  , { parameter: 'time_readable', header: "Tijd"}
  , { parameter: 'author_readable', header: "Wie"}
  , { parameter: 'content', header: "Inhoud"}
  ]

  constructor( private locationService: LocationService
  , private userService: UserService
  , private notifier: NotifierService
  ) {
    this.writeLocationLogbookPermission = this.userService.userHasPermission(WRITE_LOCATION_LOGBOOK);
  }

  processData(data: any[]) {
    for(let d of data) {
      this.processEntry(d);
    }
    this.logData = data;
  }

  processEntry(d) {
    d.time_readable = moment(d.annotationTime || d.creationTime).format('YYYY-MM-DD HH:mm');
    d.author = {name: "..."};
    this.userService.getUser(d.customerId).subscribe(
      u => {
        d.author = u;
      }
      , e => {
        console.log("Error getting author", e);
      }
    );
  }

  ngOnInit() {
    this.fetchLog(this.locationID);
  }

  ngOnChange(changes) {
    if(changes.locationID) {
      this.fetchLog(changes.locationID.currentValue);
    }
  }

  fetchLog(id) {
    this.locationService.getLog(id).subscribe(
      (data: any[]) => {
        this.processData(data);
      }
    , error => {
        console.log("Error ", error);
      }
    )
  }

  delLogEntry(i: number) {
    this.locationService.delLogEntry(this.locationID, this.logData[i].id).subscribe(
      (r: string) => {
        this.logData.splice(i, 1);
      }
      , e => {
        this.notifier.notify('error', "Verwijderen logboekregel mislukt");
        console.log("Error deleting log entry", e);
      }
    )
  }

  addLogEntry() {
    var line = this.newEntry;
    this.newEntry = "";
    this.userService.me.subscribe(
      me => {
        var d = { type: 'user'
        , creationTime: moment().toISOString()
        , content: line
        , customerId: me.id
        , id: 0
        }
        this.locationService.addLogEntry(this.locationID, d).subscribe(
          (r: string) => {
            var number = parseInt(r, 10);
            if(number != 0) {
              d.id = number;
              this.processEntry(d);
              this.logData.push(d);
            }
          }
          , e => {
            this.notifier.notify('error', "Toevoegen logboekregel mislukt");
            console.log("Error adding log entry", e);
          }
        )
      }
    )
  }

  toggleOpen() {
    this.showLog = !this.showLog;
  }

}
