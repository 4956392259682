import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { ImpersonatorService } from 'app/services/impersonator.service';

@Injectable()
export class ImpersonateInterceptor implements HttpInterceptor {
  constructor(
    private impersonatorService: ImpersonatorService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let impersonation = this.impersonatorService.impersonatedUserId;
    if (impersonation) {
      request = request.clone({
        setParams: {
          impersonate: `${impersonation}`
        }
      });
    }

    return next.handle(request);
  }
}
