import { Component, Input } from '@angular/core';
import { UserImage } from '../user-image-gallery/user-image-gallery.component';
import { LocationAttachmentsService } from 'app/services/location-attachments.service';
import { UserFileService } from 'app/services/user-file.service';
import { NotifierService } from 'angular-notifier';
import { WELL_DETAILS } from 'app/services/permission-definitions';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'well-attachments',
    templateUrl: './well-attachments.component.html',
    styleUrls: ['./well-attachments.component.css', '../user-image-gallery/user-image-gallery.component.css']
})
export class WellAttachmentsComponent {

    constructor(
        private locationAttachmentsService: LocationAttachmentsService,
        private userFileService: UserFileService,
        private notifier: NotifierService,
    ) {
        locationAttachmentsService
    }

    private _locationId: number;

    get locationId() {
        return this._locationId;
    }

    @Input() set locationId(locationId: number) {
        this._locationId = locationId;

        this.locationAttachmentsService.getPhotos(locationId)
            .subscribe(photos => {
                this.photos = photos
                    .map(photo => new UserImage({
                        caption: photo.extra && photo.extra.caption || '',
                        id: photo.id,
                        filename: photo.filename,
                        length: photo.size,
                        canDelete: photo.canDelete,
                        url: `/userfiles/${photo.id}`,
                    }, this.userFileService));
            });

        this.locationAttachmentsService.getBoreholeLog(locationId)
            .subscribe(boreholeLog => {
                this.boreholeLog = boreholeLog ? new UserImage({
                    caption: '',
                    id: boreholeLog.id,
                    filename: boreholeLog.filename,
                    length: boreholeLog.size,
                    canDelete: boreholeLog.canDelete,
                    url: `/userfiles/${boreholeLog.id}`,
                }, this.userFileService) : null;
            });
    }

    @Input() editable: boolean = false;

    photos: UserImage[] = [];
    pendingPhotos: FileList;
    boreholeLog: UserImage | null;
    pendingBoreholeLog: File;

    formBusy$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    // FIXME this is bound to a property in the user-image-gallery component to make sure its ngOnChanges() is called.
    // If we don't do this, somehow the *ngFor inside it *is* re-evaluated when new photos are added but ngOnChanges() is not called and the lightbox breaks for the new image.
    uglyChangeTriggerHack = 0;

    setPendingBoreholeLog(file: File) {
        this.pendingBoreholeLog = file;
    }

    replaceBoreholeLog() {
        if (!this.pendingBoreholeLog) {
            return;
        }

        let file = this.pendingBoreholeLog;
        let userFile = this.userFileService.createLocalFile(file, { locationId: this.locationId, locationReadPermission: WELL_DETAILS });
        this.boreholeLog = new UserImage({ caption: '', url: null, filename: file.name, id: null, length: file.size, canDelete: true }, this.userFileService, userFile);
    }

    setPendingPhotos(files: FileList) {
        this.pendingPhotos = files;
    }

    addPendingPhotos() {
        if (!this.pendingPhotos) {
            return;
        }

        for (let i = 0; i < this.pendingPhotos.length; ++i) {
            let file = this.pendingPhotos.item(i);
            let userFile = this.userFileService.createLocalFile(file, { locationId: this.locationId, locationReadPermission: WELL_DETAILS });
            let userImage = new UserImage({ caption: file.name, url: null, filename: file.name, id: null, length: file.size, canDelete: true }, this.userFileService, userFile);
            this.photos.push(userImage);
        }

        this.uglyChangeTriggerHack++;
    }

    save() {
        this.formBusy$.next(true);
        this.uploadBoreholeLog();
    }

    uploadBoreholeLog() {
        console.log("upload borehole log");

        const img = this.boreholeLog;

        if (!img) {
            this.onBoreholeLogUploadComplete();
            return;
        }

        if (!img.userFile.isOnServer() && !img.userFile.isDeletionPending()) {
            this.userFileService.uploadUserFile(img.userFile).subscribe(
                userFile => {
                    this.boreholeLog.description.id = userFile.description.id;
                    this.onBoreholeLogUploadComplete();
                },
                error => {
                    this.notifier.notify('error', "Uploaden van " + img.description.filename + " is niet gelukt.");
                }
            );
        }
        else if (!img.userFile.isOnServer() && img.userFile.isDeletionPending()) {
            this.boreholeLog = null;
        }
        else if (img.userFile.isOnServer() && img.userFile.isDeletionPending()) {
            img.userFile.delete().subscribe(
                response => {
                    this.boreholeLog = null;
                    this.onBoreholeLogUploadComplete();
                },
                error => {
                    this.notifier.notify('error', "Verwijderen van " + img.description.filename + " is niet gelukt.");
                }
            );
        }
        else {
            this.onBoreholeLogUploadComplete();
        }
    }

    onBoreholeLogUploadComplete() {
        this.uploadPhotos();
    }

    uploadPhotos() {
        let done = true;

        for (let photo of this.photos) {
            if (!photo.userFile.isOnServer() && !photo.userFile.isDeletionPending()) {
                done = false;

                this.userFileService.uploadUserFile(photo.userFile).subscribe(
                    userFile => {
                        photo.description.id = userFile.description.id;
                        this.onPhotoUploadComplete();
                    },
                    error => {
                        this.notifier.notify('error', "Uploaden van " + photo.description.filename + " is niet gelukt.");
                    }
                );
            }
            else if (!photo.userFile.isOnServer() && photo.userFile.isDeletionPending()) {
                this.photos = this.photos.filter(p => p !== photo);
            }
            else if (photo.userFile.isOnServer() && photo.userFile.isDeletionPending()) {
                done = false;

                photo.userFile.delete().subscribe(
                    response => {
                        this.photos = this.photos.filter(p => p !== photo);
                        this.onPhotoUploadComplete();
                    },
                    error => {
                        this.notifier.notify('error', "Verwijderen van " + photo.description.filename + " is niet gelukt.");
                    }
                );
            }
        }

        if (done) {
            this.onPhotoUploadComplete();
        }
    }

    async onPhotoUploadComplete() {
        this.uglyChangeTriggerHack++;

        if (this.photos.every(photo => photo.userFile.isOnServer() !== photo.userFile.isDeletionPending())) {
            try {
                await this.saveAttachments();
                this.notifier.notify('success', 'Boorbeschrijving en foto\'s opgeslagen');
            }
            catch (e) {
                this.notifier.notify('error', 'Nak');
            }

            this.formBusy$.next(false);
        }
    }

    async saveAttachments() {
        let b = this.boreholeLog && this.boreholeLog.userFile.description.id
            ? { id: this.boreholeLog.userFile.description.id }
            : null;

        await this.locationAttachmentsService.setBoreholeLog(this.locationId, b);

        let p = this.photos
            .map(p => ({ id: p.userFile.description.id }))
            .filter(p => p.id);

        await this.locationAttachmentsService.setPhotos(this.locationId, p);
    }

    openBoreholeLog() {
        this.boreholeLog.getUrl().toPromise().then((url) => window.open(url, "_blank"));
    }
}
