import {Component, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from "../../auth/auth.service";
import {User, UserService, UserSettings} from "../../services/user.service";
import {Subscription} from "rxjs";
import {NotifierService} from "angular-notifier";

class Setting {
  public _type: string;
  public _id: string;
  public _name: string;
}

class SettingsGroup {
  public _settings: Setting[]

  constructor(settings: Setting[] = []) {
    this._settings = settings;
  }
}

class Settings<T> {
  public _current: T;
  public _groups: SettingsGroup[];

  constructor(group: SettingsGroup[] = []) {
    this._groups = group;
  }
  addGroup(group: SettingsGroup) {
    this._groups.push(group);
  }

  get(): T {
    return this._current;
  }
}

@Component({
  selector: 'user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.css']
})
export class UserSettingsComponent implements OnInit, OnDestroy {

  _settings: UserSettings;
  _sub: Subscription;
  response: string;

  constructor(
    public auth: AuthService,
    public user: UserService,
    private notifier: NotifierService,
  ) {
  }

  ngOnInit() {
    this._sub = this.user.getMySettings().subscribe((settings: UserSettings) => {
      this._settings = settings;
    })
    this.response = undefined;
  }

  ngOnDestroy() {
    if(this._sub) {
      this._sub.unsubscribe();
      this._sub = undefined;
    }
    this.response = undefined;
  }

  saveSettings() {
    console.log("Current: ", this._settings)
    this.user.setMySettings(this._settings).subscribe(
      data => {
        this.notifier.notify('success', 'Instellingen geüpdatet.');
      },
      error => {
        console.error(error);
        this.notifier.notify('error', "Updaten instellingen mislukt.");
      }
    )
  }

}
