import { Pipe, PipeTransform } from '@angular/core';

/**
 * Keep the items where its id property occurs in the given selection (array of ids).
 */
@Pipe({
  name: 'isSelected'
})
export class IsSelected implements PipeTransform {
  transform(items: Array<any>, selection: Array<any>): Array<any> {
    return items.filter(item => selection.indexOf(item.id) !== -1);
  }
}
