import { Injectable } from '@angular/core';
import * as auth0 from 'auth0-js';
import { AUTH_CONFIG } from './auth0-variables';
import { UserProfile } from './profile.model';
import { Router } from '@angular/router';
import { UserService } from "../services/user.service";
import { Observable, Observer } from "rxjs";
import { map, shareReplay } from 'rxjs/operators';

(window as any).global = window;

/**
 * This service is used for authenticating the user in the app.
 * Communicates with Auth0 using the relevant environment settings.
 *
 * The following tutorial was used to build this service: https://auth0.com/docs/quickstart/spa/angular2/01-login
 */
@Injectable()
export class AuthService {
  // Create Auth0 web auth instance
  private _Auth0 = new auth0.WebAuth({
    clientID: AUTH_CONFIG.CLIENT_ID,
    domain: AUTH_CONFIG.CLIENT_DOMAIN,
    responseType: 'token',
    redirectUri: AUTH_CONFIG.REDIRECT,
    audience: AUTH_CONFIG.AUDIENCE,
    scope: AUTH_CONFIG.SCOPE
  });
  
  public userProfile: Observable<UserProfile>;

  constructor(public router: Router, public userService: UserService) { 
    this.userProfile = this.createUserProfileObservable();

    // Always renew the token when we open this page
    if (this.authenticated) {
      this.renewToken();
    }
  }

  get accessToken(): string {
    return localStorage.getItem('access_token');
  }

  get expiresAt(): number {
    return +localStorage.getItem('expires_at');
  }

  get authenticated(): boolean {
    // Check if current date is greater than expiration and user is currently logged in
    return Date.now() < this.expiresAt;
  }

  login() {
    this._Auth0.authorize();
  }

  signup() {
    this._Auth0.authorize({screen_hint: 'signup'});
  }

  private createUserProfileObservable() {
    return Observable.create((observer: Observer<UserProfile>) => {
      if (this.authenticated) {
        // Use access token to retrieve user's profile
        this._Auth0.client.userInfo(this.accessToken, (err, profile) => {
          if (err) {
            console.error("Error while fetching user profile", err);
            observer.next(null);
          } else if (profile) {
            observer.next(profile);
          }
        });
      } else {
        observer.next(null);
      }
    }).pipe(shareReplay(1));
  }

  /**
   * We expect the auth0 access token to be passed to be passed to this function.
   * In the auth0 tutorial, _Auth0.parseHash() is called to get the access_token from the URL.
   *
   * The response from auth0 is intercepted using a route guard (before routing event is handled),
   * because the access token gets stripped from the route before we can read it.
   * The hash that we receive here can be passed to auth0.
   * @param hash the current route
   */
  handleLoginCallback(hash) {
    this._Auth0.parseHash({hash}, (err, authResult) => {
      if (authResult && authResult.accessToken) {
        this.setSession(authResult);
      } else if (err) {
        console.error(`Error: ${err.error}`);
      }
      this.router.navigate(['/']);
    });
  }

  private setSession(authResult) {
    // Save session data and update login status subject
    let expiresAt = authResult.expiresIn * 1000 + Date.now();
    localStorage.setItem('expires_at', "" + expiresAt);
    localStorage.setItem('access_token', authResult.accessToken);
    // Schedule renewing the token one hour before it expires
    setTimeout(this.renewToken, (authResult.expiresIn - 3600) * 1000);
  }

  private discardToken() {
    // Remove token from localStorage
    localStorage.removeItem('expires_at');
    localStorage.removeItem('access_token');
  }

  private renewToken() {
    // Check for valid Auth0 session
    this._Auth0.checkSession({}, (err, authResult) => {
      if (err) {
        this.discardToken();
      } else {
        this.setSession(authResult);
      }
    });
  }

  logout() {
    // Remove token and profile, update login status subject,
    // and log out of Auth0 authentication session
    // This does a refresh and redirects back to homepage
    // Make sure you have the returnTo URL in your Auth0
    // Dashboard Application settings in Allowed Logout URLs
    this.discardToken();
    this._Auth0.logout({
      returnTo: AUTH_CONFIG.BASE_URI,
      clientID: AUTH_CONFIG.CLIENT_ID
    });
  }

  resetPassword() {
    // Wrap the Auth0 changePassword callback in a promise, so we can return a promise from this function
    return new Promise((resolve, reject) => {
      this.userProfile.subscribe(profile => {
        this._Auth0.changePassword({
          connection: 'Username-Password-Authentication',
          email: profile.email
        }, function (err, response) {
          if(err){
            reject(err.message);
          } else{
            resolve(response);
          }
        });
      });
    });

  }
}
