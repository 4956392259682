import {Component, OnInit, Input, SimpleChange, OnDestroy, OnChanges} from '@angular/core';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { DeploymentService } from "../services/deployment.service";
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'deployment-status',
  templateUrl: './deployment-status.component.html',
  styleUrls: ['./deployment-status.component.css']
})
export class DeploymentStatusComponent implements OnInit, OnDestroy, OnChanges {

  deployment;
  @Input() deploymentID = 0;

  progress$: Observable<any>;

  statusItems = [];

  subscription: Subscription = null;

  constructor(private deploymentService: DeploymentService) { }

  ngOnInit() {
    this.processDeploymentData({});
  }

  ngOnDestroy() {
    if(this.subscription) this.subscription.unsubscribe();
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    if(changes.deploymentID) {
      if(changes.deploymentID.currentValue > 0) {
        if(this.subscription) this.subscription.unsubscribe();
        this.subscription = this.deploymentService.getAnalysis(changes.deploymentID.currentValue, false).subscribe(d => {
          this.processDeploymentData(d);
        });
        this.progress$ = this.deploymentService.getProgress(this.deploymentID);
      } else {
        this.progress$ = null;
      }
    }
  }

  processDeploymentData(d) {

    var items = [];

    var now = moment.now();

    {
      var itemConnectivity = {class: "connection", text: ""};
      if(d.report && d.report.messagesReceived) {
        var pc_retransmitted = d.report.messagesRetransmitted/d.report.messagesReceived;
        var pc_received = d.report.messagesReceived/(d.report.messagesReceived+d.report.messagesMissed);
        var untilTime = d.toTime ? d.toTime*1000 : now ;
        var connectionIssues = [];
        var connectionBad = false;
        if(pc_received < .90) {
          connectionIssues.push("Received below 90%");
          connectionBad = true;
        } else if(pc_received < .95) {
          connectionIssues.push("Received below 95%");
        }
        if(pc_retransmitted > .5) {
          connectionIssues.push("Retransmissions above 50%");
        }
        if(connectionIssues.length > 0) {
          itemConnectivity.class += connectionBad ? " error" : " warning";
          itemConnectivity.text = "Connection issues: " + connectionIssues.join(", ");
        } else {
          itemConnectivity.class += " ok";
          itemConnectivity.text = "Connection OK";
        }
      } else {
        itemConnectivity.text = "Connection status unknown: never received a message";
      }
      items.push(itemConnectivity);
    }

    {
      var item = {class: "battery", text: ""};
      if(d.batteryDeadTime) {
        var batteryMillisecondsLeft = d.batteryDeadTime*1000 - untilTime;
        item.text = "Battery estimated to run out " + moment(batteryMillisecondsLeft).from(0);
        if(batteryMillisecondsLeft < 1000*60*60*24*40) {
          if(batteryMillisecondsLeft < 1000*60*60*24*10) {
            item.class += " error";
          } else {
            item.class += " warning";
          }
        } else {
            item.class += " ok";
        }
      } else {
        item.text = "No battery information";
      }
      items.push(item);
    }

    {
      var item = {class: "activity", text: ""};
      if(d.fromTime && !d.toTime) {
        if(d.lastMessageReceivedAt) {
          var ago = d.lastMessageReceivedAt * 1000 - now;
          item.text = "Last message received " + moment(ago).from(0);
          if(ago < -1000*60*60*24) {
            if(ago < -1000*60*60*24*5) {
              if(ago < -1000*60*60*24*30) {
                item.class += " old";
              } else {
                item.class += " error";
              }
            } else {
              item.class += " warning";
            }
          } else {
            item.class += " ok";
          }
        } else {
          item.class += " error";
          item.text = "Never received a message";
        }
      } else {
          item.text = "Inactive deployment";
      }
      items.push(item);
    }

    this.statusItems = items;

  }

  analyze() {
    if(this.deploymentID > 0) {
      this.deploymentService.getAnalysis(this.deploymentID, true);
    }
    
  }

}
