import {NgModule} from "@angular/core";
import { MeasurementExportComponent, MeasurementExportDialog } from './measurement-export.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { MeasurementExportService } from "./measurement-export.service";
import { Daterangepicker } from 'ng2-daterangepicker';

@NgModule({
  declarations: [MeasurementExportComponent],
  exports: [MeasurementExportComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, ModalModule, Daterangepicker],
  providers: [MeasurementExportService, MeasurementExportDialog, BsModalService],
  entryComponents: [MeasurementExportComponent]
})

export class MeasurementExportModule {}
