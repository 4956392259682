import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { User, UserService } from 'app/services/user.service';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private user: UserService,
    private router: Router
  ) { }

  canActivate() {
    return this.user.me.pipe(map(e => {
      if (e == null || e.id == null) {
        this.router.navigate(['/welcome']);
        return false;
      } else {
        return true;
      }
    }));
  }

}
