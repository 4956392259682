import { Component } from '@angular/core';
import { detectBody } from '../../../app.helpers';
import { StatusMessage, StatusService } from 'app/services/status.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'basic',
  templateUrl: 'basicLayout.template.html',
  host: {
    '(window:resize)': 'onResize()'
  }
})
export class BasicLayoutComponent {
  messages: StatusMessage[] = [];
  statusServiceSubscription: Subscription | undefined;

  constructor(
    public statusService: StatusService
  ) {

  }

  ngOnInit() {
    this.statusServiceSubscription = this.statusService.messages.subscribe((messages) => {
      this.messages = messages;
    })

    detectBody();
  }

  ngOnDestroy() {
    if(this.statusServiceSubscription) {
      this.statusServiceSubscription.unsubscribe();
      this.statusServiceSubscription = undefined;
    }
  }

  public onResize(){
    detectBody();
  }

}
