import { AfterViewInit, Component, Injectable, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DaterangePickerComponent, DaterangepickerConfig } from 'ng2-daterangepicker';
import { Observable } from 'rxjs';

import { AuthService } from 'app/auth/auth.service';
import { BlikLocation } from 'app/services/location.service';
import { UserService } from 'app/services/user.service';
import { Job, JobService, JobState } from 'app/services/job.service';
import { IUserFileDescription, UserFileService } from 'app/services/user-file.service';
import { PUSH_FILES_TO_REMOTE } from 'app/services/permission-definitions';

import { MeasurementExportOptions, MeasurementExportService, UploadTargetOptions, UploadTargetProtocol } from './measurement-export.service';
import * as moment from 'moment';
import { take } from 'rxjs/operators';

const DEFAULT_PORTS: Record<UploadTargetProtocol, number> = {
  [UploadTargetProtocol.download]: null,
  [UploadTargetProtocol.FTP]: 21,
  [UploadTargetProtocol.FTPS]: 21,
  [UploadTargetProtocol.SFTP]: 22,
}


@Component({
  selector: 'measurement-export-dialog',
  templateUrl: './measurement-export.component.html',
  styleUrls: ['./measurement-export.component.css'],
})
export class MeasurementExportComponent implements AfterViewInit {
  @Input() public exportOptions: MeasurementExportOptions = new MeasurementExportOptions();
  @Input() public locations: BlikLocation[];  
  @Input() public uploadOptions: UploadTargetOptions = new UploadTargetOptions();

  @Input() public filename: string = "blik_export";

  public ftpPermission: Observable<boolean>;

  public form: FormGroup;
  public job: Job | null = null;
  public step: 'start' | 'upload' | 'filename' | 'progress' | 'done' | 'error' | 'closed' = 'start';

  @ViewChild(DaterangePickerComponent, { static: false })
  private dateRangePicker: DaterangePickerComponent;

  public dateRangePickerOptions: DaterangepickerConfig['settings'] = {
    locale: { format: 'YYYY-MM-DD' }
  };
  
  constructor(
    public auth: AuthService,
    private service: MeasurementExportService,
    private notifier: NotifierService,
    private userFileService: UserFileService,
    private jobService: JobService,
    public userService: UserService,
    public modalRef: BsModalRef,
    public modalService: BsModalService
  ) {
    this.ftpPermission = userService.userHasPermission(PUSH_FILES_TO_REMOTE);
  }

  ngAfterViewInit() {
    if (this.dateRangePicker && this.dateRangePicker.datePicker) {
      this.dateRangePicker.datePicker.setStartDate(this.exportOptions.after);
      this.dateRangePicker.datePicker.setEndDate(this.exportOptions.before);
    }
    this.filename = "blik_export_" + moment().format('YYYY-MM-DD_HH-mm');
  }

  onHidden() {
    this.step = 'closed';
  }

  public selectedDate(value: { start: moment.Moment, end: moment.Moment }) {
    this.exportOptions.after = value.start;
    this.exportOptions.before = value.end;
  }

  public nextStep() {
    switch (this.step) {
      case 'start':
        if (this.uploadOptions.protocol === UploadTargetProtocol.download) {
          this.step = 'filename';
          return;
        }
        this.step = 'upload';
        return;

      case 'upload':
        this.step = 'filename';
        return;

      case 'filename':
        this.start();
        return;

      case 'error':
        this.step = 'start';
        return;
    }
  }

  public previousStep() {
    switch (this.step) {
      case 'start':
        return;

      case 'upload':
        this.step = 'start';
        return;

      case 'filename':
        if (this.uploadOptions.protocol === UploadTargetProtocol.download) {
          this.step = 'start';
          return;
        }
        this.step = 'upload';
        return;
    }
  }

  public start() {
    this.step = 'progress';
    this.service.postExportZipJob(this.filename, this.locations, this.exportOptions, this.uploadOptions.protocol == UploadTargetProtocol.download ? null : this.uploadOptions).subscribe({
      next: job => this.updateJob(job),
      error: e => this.notifier.notify('error', 'Export aanmaken is mislukt')
    });
  }

  get progress() {
    if (this.job) {
      return Math.floor(90 * this.jobService.totalProgress(this.job));
    } else {
      return 0;
    }
  }

  updateJob(job: Job) {
    this.job = job;
    
    if (job.state == JobState.FINISHED) {
      if (this.isDownload) {
        this.userFileService.downloadFile(job.result as IUserFileDescription);
        this.step = 'done';
      }
      else {
        this.step = 'done';
      }
    }

    if (job.state == JobState.FAILED) {
      this.step = 'error';
    }

    if (!this.jobService.isDone(job) && this.step !== 'closed') {
      setTimeout(() => {
        this.jobService.refreshJob(job).subscribe(job => this.updateJob(job))
      }, 500);
    }
  }

  onProtocolChange(newProtocol: UploadTargetProtocol) {
    const prevProtocol = this.uploadOptions.protocol; // works because (onModelChange) is defined before [(ngModel)]
    if (!this.uploadOptions.port || this.uploadOptions.port == DEFAULT_PORTS[prevProtocol]) {
      this.uploadOptions.port = DEFAULT_PORTS[newProtocol];
    }
  }

  get isError() {
    return this.job && this.job.state == JobState.FAILED;
  }

  get error() {
    return this.job ? this.job.errorMessage : "";
  }

  get isSuccess() {
    return this.job && this.job.state == JobState.FINISHED;
  }

  get isDownload() {
    return this.uploadOptions.protocol == UploadTargetProtocol.download;
  }

  get readyToClose() {
    return this.job && this.jobService.isDone(this.job);
  }
}

@Injectable()
export class MeasurementExportDialog {
  constructor(
    private modalService: BsModalService
  ) {

  }

  openExportDialog(
      locations: BlikLocation[],
      exportOptions?: MeasurementExportOptions,
      uploadOptions?: UploadTargetOptions,
  ) {
      const modalRef = this.modalService.show(
          MeasurementExportComponent, {
              ignoreBackdropClick: true,
              initialState: {
                  exportOptions: exportOptions || new MeasurementExportOptions(),
                  uploadOptions: uploadOptions || new UploadTargetOptions(),
                  locations: locations,
              },
          }
      );

      const hiddenHandler = () => {
        if (modalRef.content.onHidden) {
          modalRef.content.onHidden();
        }
       };

      this.modalService.onHidden.pipe(take(1)).subscribe(hiddenHandler);
  }
}
