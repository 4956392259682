import { Component, OnInit, Input, SimpleChange } from '@angular/core';

import * as Highcharts from 'highcharts';
import { Subscription } from 'rxjs';
import { DeploymentService, DeploymentAnalysis, Job } from "../services/deployment.service";

@Component({
  selector: 'deployment-detail',
  templateUrl: './deployment-detail.component.html',
  styleUrls: ['./deployment-detail.component.css']
})
export class DeploymentDetailComponent implements OnInit {

  static timeToOffsetString(time) {
    var days = Math.floor(time / (24*60*60*1000));
    var ms_remaining = time - days * (24*60*60*1000);
    var hours = Math.floor(ms_remaining / (60*60*1000))
    ms_remaining -= hours * (60*60*1000);
    var minutes = Math.floor(ms_remaining / (60*1000))
    ms_remaining -= minutes * (60*1000);
    var seconds = Math.floor(ms_remaining / (1000))
    return ""
         + days + "d "
         + hours + "h "
         + minutes + "m "
         + seconds + "s"
         ;
  }

  @Input() deploymentID: Number;
  progress: String;

  progressSubscription: Subscription = null;
  analysisDetailsSubscription: Subscription = null;
  analysisDetails: DeploymentAnalysis;

  chartOptions = {
    credits: {
        enabled: false
    },
    title: "Analysis",
    chart: {
        events: {
          redraw: function(chart) {
            setTimeout(function() {
              window.dispatchEvent(new Event('resize'));
            }, 1);
          }
        },
        spacing: [5,5,5,5]
    },

    xAxis: {
      type: 'datetime',
      labels: {
        align: "left"
      },
      allowDecimals: false,
    } as Highcharts.XAxisOptions,

    tooltip: {
      shared: true,
    //   style: {
    //     width: '250px'
    //   }
    },

    yAxis: [{
      min: 0,
      title: {
        text: 'Messages per day',
        margin: 3
      },
      labels: {
        padding: 1
      }
    }, {
      min: 0,
      title: {
        text: 'Battery (mV)',
        margin: 3
      },
      labels: {
        padding: 1
      }
    }, {
      min: 5,
      max: 12,
      title: {
        text: 'SPF',
        margin: 3
      },
      labels: {
        padding: 1
      }
    }, {
      max: 0,
      title: {
        text: 'RSSI',
        margin: 3
      },
      labels: {
        padding: 1
      }
    }, {
      title: {
        text: 'SNR',
        margin: 3
      },
      labels: {
        padding: 1
      }
    }, {
      max: 325,
      min: 250,
      title: {
        text: 'Temperature (K)',
        margin: 3
      },
      labels: {
        padding: 1
      }
    }, {
      visible: false,
      title: {
        text: 'Time Drift (s)',
        margin: 3
      },
      labels: {
        padding: 1
      }
    }] as Highcharts.YAxisOptions,

    plotBands: [],

    plotOptions: {
      spline: {
        marker: {
          enabled: true
        }
      },
      series: {
        marker: {
          enabled: true,
          symbol: 'circle',
          radius: 2
        },
        fillOpacity: 0.5
      },
      flags: {
        tooltip: {
          xDateFormat: '%B %e, %Y'
        }
      }
    } as Highcharts.PlotOptions,

    series: []
  } as Highcharts.Options;

  public chart;

  constructor(private deploymentService: DeploymentService) {
  }

  ngOnInit() {
    this.chart = Highcharts.chart('analysischart',this.chartOptions);
  }

  ngOnDestroy() {
    if(this.analysisDetailsSubscription) this.analysisDetailsSubscription.unsubscribe();
    if(this.progressSubscription) this.progressSubscription.unsubscribe();
  }

  clearChart() {
    if (this.chart) {
      let series = this.chart.series;
      while(series.length > 0) {
        series[0].remove(false);
      }
    }
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {

    if(changes.deploymentID && this.deploymentID > 0) {
      this.analysisDetails = null;
      if(this.analysisDetailsSubscription) this.analysisDetailsSubscription.unsubscribe();
      if(this.progressSubscription) this.progressSubscription.unsubscribe();
      this.analysisDetailsSubscription = this.deploymentService.getAnalysis(this.deploymentID, true).subscribe((d: DeploymentAnalysis) => {
        this.analysisDetails = d;
        this.update();
      });
      this.progressSubscription = this.deploymentService.getProgress(this.deploymentID).subscribe((d: Job) => {
        if(d.queuePosition) {
          this.progress = "Q'd (" + d.queuePosition + ")";
        } else {
          var procent = Math.floor(d.progress * 100)
          if(procent == 100) {
            this.progress = null;
          } else {
            this.progress = procent.toFixed(0) + "%";
          }
        }
      }
      , () => { console.log("got error...");}
      , () => {
      });
    } else {
      this.clearChart();
    }
  }

  update() {
    if(this.analysisDetails) {

      this.clearChart();
      if(this.analysisDetails && this.analysisDetails.hasOwnProperty("subReports") && this.chart) {

        var dataReceived = [];
        var dataRetransmitted = [];
        var dataMissing = [];
        var dataExtra = [];
        var dataVoltageMax = [];
        var dataVoltageMin = [];
        var dataVoltageAvg = [];
        var dataTemperatureMax = [];
        var dataTemperatureMin = [];
        var dataTimeDriftMax = [];
        var dataSPFMax = [];
        var dataRSSIMin = [];
        var dataSNRMin = [];

        var dataEvents = [];

        var idlocal = 1;
        var prevEpoch = 0;

        var resetLines = [];
        var resetLinesID = 'resetLines';

        this.analysisDetails.events.forEach(function (e) {
          if(e.reset) {
            resetLines.push({id: resetLinesID, color: '#FFFFFF', width:1, value: e.fromTime*1000, zIndex: -100});
          } else {
            dataEvents.push({x: e.fromTime*1000, text: e.description, title: e.description, shape: 'squarepin'});
          }
        });

        this.analysisDetails.subReports.forEach(function (m) {
            if(m.messagesReceived > 0 || m.messagesMissed > 0) {
              dataReceived.push({x: m.toTime*1000, y: m.messagesReceived});
              dataRetransmitted.push({x: m.toTime*1000, y: m.messagesRetransmitted});
              dataMissing.push({x: m.toTime*1000, y: m.messagesMissed});
              dataExtra.push({x: m.toTime*1000, y: m.messagesExtra});
              dataSPFMax.push({x: m.toTime*1000, y: m.spfMax});
              dataRSSIMin.push({x: m.toTime*1000, y: m.rssiMin});
              dataSNRMin.push({x: m.toTime*1000, y: m.snrMin});
              dataVoltageMax.push({x: m.toTime*1000, y: m.batteryVMax});
              dataVoltageMin.push({x: m.toTime*1000, y: m.batteryVMin});
              //dataVoltageAvg.push({x: m.toTime*1000, y: m.batteryVAvg});
              dataTemperatureMax.push({x: m.toTime*1000, y: m.temperatureKMax});
              dataTemperatureMin.push({x: m.toTime*1000, y: m.temperatureKMin});
              dataTimeDriftMax.push({x: m.toTime*1000, y: m.timeDriftMax*1000});
            }
        })

        var configRegions = [];
        var currentColor = 0;

        this.analysisDetails.configs.forEach(function (c) {

          // Don't draw bands for configs that lasted less than an hour
          if(c.toTime < c.fromTime + 3600) {
            return;
          }

          var color = ((currentColor % 2) == 0) ? '#404048' : '#303034';
          var band = { from: c.from_time*1000
                    , to: c.to_time*1000+1000*60*60*24-1
                    , color: color
                    , label: { text: "Interval: " + c.interval + ((c.interval == 1) ? " minute" : " minutes")
                              , style: { color: '#F0F0F0' }
                              }
                    , zIndex: -200
                    }
          configRegions.push(band);
          currentColor++;
        })

        this.chart.xAxis[0].update({plotBands: configRegions, plotLines: resetLines}, false);

        this.chart.addSeries({
          // Series that mimics the plot line
          color: '#FF0000',
          name: 'Reset markers',
          marker: {
              enabled: false
          },
          events: {
              // Event for showing/hiding plot line
              legendItemClick: function(e) {
                  if(this.visible) {
                      this.chart.xAxis[0].update({plotLines: []}, false);
                  }
                  else {
                      this.chart.xAxis[0].update({plotLines: resetLines}, false);
                  }
                  return true;
              }
          }
        }, false);

        this.chart.addSeries({
          type: 'spline',
          color: '#20ff2080',
          id: 'received',
          name: 'Expected messages',
          data: dataReceived,
          zIndex: 8,
          marker: {
              symbol: "circle"
          },
          tooltip: {
            xDateFormat: '%B %e, %Y'
          }
        }, false);

        this.chart.addSeries({
          type: 'spline',
          color: '#ffff2080',
          id: 'retrans',
          name: 'Retransmitted messages',
          data: dataRetransmitted,
          zIndex: 8,
          marker: {
              symbol: "circle"
          },
          tooltip: {
            xDateFormat: '%B %e, %Y'
          }
        }, false);

        this.chart.addSeries({
          type: 'spline',
          color: '#ff202080',
          name: 'Missed messages',
          data: dataMissing,
          zIndex: 8,
          marker: {
              symbol: "diamond"
          },
          tooltip: {
            xDateFormat: '%B %e, %Y'
          }
        }, false);

        this.chart.addSeries({
          type: 'spline',
          color: '#2020ff80',
          name: 'Extra messages',
          data: dataExtra,
          zIndex: 8,
          marker: {
              symbol: "square"
          },
          tooltip: {
            xDateFormat: '%B %e, %Y'
          }
        }, false);

        this.chart.addSeries({
          yAxis: 1,
          type: 'spline',
          color: '#ff1020',
          name: 'Minimum voltage',
          data: dataVoltageMin,
          marker: {
              enabled: false
          },
          dashStyle: "Dash",
          tooltip: {
            xDateFormat: '%B %e, %Y',
            valueSuffix: 'mV'
          }
        }, false);

        this.chart.addSeries({
          yAxis: 2,
          type: 'spline',
          color: '#10c0ff',
          name: 'SPF Max',
          data: dataSPFMax,
          marker: {
              enabled: false
          },
          dashStyle: "Dash",
          tooltip: {
            xDateFormat: '%B %e, %Y',
            valueSuffix: ''
          }
        }, false);

        this.chart.addSeries({
          yAxis: 3,
          type: 'spline',
          color: '#4040ff',
          name: 'RSSI Min',
          data: dataRSSIMin,
          marker: {
              enabled: false
          },
          dashStyle: "Dash",
          tooltip: {
            xDateFormat: '%B %e, %Y',
            valueSuffix: 'dBm'
          }
        }, false);

        this.chart.addSeries({
          yAxis: 4,
          type: 'spline',
          color: '#8080ff',
          name: 'SNR Min',
          data: dataSNRMin,
          marker: {
              enabled: false
          },
          dashStyle: "Dash",
          tooltip: {
            xDateFormat: '%B %e, %Y',
            valueSuffix: ''
          }
        }, false);

        this.chart.addSeries({
          yAxis: 5,
          type: 'spline',
          color: '#20ffff',
          name: 'Maximum temperature',
          data: dataTemperatureMax,
          marker: {
              enabled: false
          },
          dashStyle: "Dash",
          tooltip: {
            xDateFormat: '%B %e, %Y',
            valueSuffix: 'K'
          }
        }, false);

        this.chart.addSeries({
          yAxis: 6,
          type: 'spline',
          color: '#ff00ff',
          name: 'Maximum Time Drift',
          data: dataTimeDriftMax,
          marker: {
              enabled: false
          },
          dashStyle: "Dash",
          tooltip: {
            pointFormatter: function() {
                return '<span style="color:' + this.color + '">\u25CF</span> Maximum Time Drift: <b>'
                // + moment(this.y).from(0, true)
                + DeploymentDetailComponent.timeToOffsetString(this.y)
                + '</b><br/>'
                ;
            }
          }
        }, false);

        this.chart.addSeries({
          yAxis: 5,
          type: 'spline',
          color: '#20ffff',
          name: 'Minimum temperature',
          data: dataTemperatureMin,
          marker: {
              enabled: false
          },
          dashStyle: "Dash",
          tooltip: {
            xDateFormat: '%B %e, %Y',
            valueSuffix: 'K'
          }
        }, false);

        this.chart.addSeries({
          type: 'flags',
          name: 'Cloud',
          color: '#333333',
          shape: 'squarepin',
          y: 20,
          data: dataEvents,
          showInLegend: false
        }, false);

        this.chart.redraw(false);
      }
    }
  }
}
