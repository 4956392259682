import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { map } from "rxjs/operators";

import { DASHBOARD_LOCATION_GROUP_DETAILS } from "app/services/permission-definitions";
import { UserService } from "app/services/user.service";


@Injectable()
export class LocationGroupDetailGuard implements CanActivate {

  constructor(
    private user: UserService,
    private router: Router
  ) { }

  canActivate() {
    return this.user.userHasPermission(DASHBOARD_LOCATION_GROUP_DETAILS)
      .pipe(map(p => {
        if (!p) {
          this.router.navigate(['/']);
        }

        return p;
      }));
  }
}
