<h1>Locatie-instellingen</h1>
<h2>Notificatie-instellingen</h2>
<div *ngIf="_settings as settings" class="row">
  <div class="col-xs-12">
  <form (ngSubmit)="saveSettings()">
    <div class="row">
      <div class="form-group col-sm-6 col-md-2 col-lg-2 order-md-1 order-xs-1">
        <label for="bla">Observeer</label>
        <select id="bla" class="form-control" name="bla" [ngModel]=helperObserve (change)="applyWatchList($event.target.value)">
          <option [value]="'NONE'">Geen metingen</option>
          <option [value]="'VALIDONLY'">Gevalideerde metingen</option>
          <option [value]="'ALL'">Alle metingen</option>
        </select>
      </div>
      <div class="form-group col-xs-12 col-sm-5 col-md-5 col-lg-5 order-md-2 order-xs-3">
        <label for="lowNotificationThresholdMeters">Notificatie bovengrens (meters)</label>
        <input type="number" id="highNotificationThresholdMeters" [(ngModel)]=settings.highNotificationThresholdMeters class="form-control" name="highNotificationThresholdMeters"/>
      </div>
      <div class="form-group col-xs-12 col-sm-5 col-md-5 col-lg-5 order-md-3 order-xs-4">
        <label for="highNotificationThresholdMinutes">Notificatievertraging boven (minuten)</label>
        <input type="number" id="highNotificationThresholdMinutes" [(ngModel)]=settings.highNotificationThresholdMinutes class="form-control" name="highNotificationThresholdMinutes"/>
      </div>
      <div class="form-group col-xs-12 col-sm-2 col-md-2 col-lg-2 order-md-4 order-xs-2">
        <label for="notificationThresholdReference">Referentievlak</label>
        <select id="notificationThresholdReference" [(ngModel)]=settings.notificationThresholdReference class="form-control" name="notificationThresholdReference">
          <option [value]="'NAP'">NAP</option>
          <option [value]="'GROUND'">Maaiveld</option>
        </select>
      </div>
      <div class="form-group col-xs-12 col-sm-5 col-md-5 col-lg-5 order-md-5 order-xs-5">
        <label for="lowNotificationThresholdMeters">Notificatie ondergrens (meters)</label>
        <input type="number" id="lowNotificationThresholdMeters" [(ngModel)]=settings.lowNotificationThresholdMeters class="form-control" name="lowNotificationThresholdMeters"/>
      </div>
      <div class="form-group col-xs-12 col-sm-5 col-md-5 col-lg-5 order-md-6 order-xs-6">
        <label for="lowNotificationThresholdMinutes">Notificatievertraging onder (minuten)</label>
        <input type="number" id="lowNotificationThresholdMinutes" [(ngModel)]=settings.lowNotificationThresholdMinutes class="form-control" name="lowNotificationThresholdMinutes"/>
      </div>
    </div>
    <button type="submit" class="btn btn-primary">Instellingen opslaan</button>
  </form>
  </div>
</div>
