import { Injectable } from '@angular/core';
import { BlikLocation } from 'app/services/location.service';
import { HttpClient } from '@angular/common/http';
import { environment } from "environments/environment";
import * as moment from 'moment';
import { Moment } from 'moment';
import { Job } from 'app/services/job.service';
import { Observable } from 'rxjs';


export enum MeasurementExportType {
    CSV = "csv",
    XLSX = "xlsx"
}

export class MeasurementExportOptions {
    exportType: MeasurementExportType = MeasurementExportType.CSV;
    periodType: 'all' | 'select' = 'all';
    limit?: number = null;
    after?: Moment = moment().subtract(7, 'days');
    before?: Moment = moment();
    includeInvalidated: boolean = false;
    onlyValidated: boolean = false;
    includeWaterLevel: boolean = true;
    includeAirWater: boolean = false;
    includeFlow: boolean = false;
    includeMisc: boolean = false;
    includeReferences: boolean = false;
}

export enum UploadTargetProtocol {
    download = "download",
    FTP = "FTP",
    SFTP = "SFTP",
    FTPS = "FTPS",
}

export class UploadTargetOptions {
    protocol: UploadTargetProtocol = UploadTargetProtocol.download;
    host?: string;
    port?: number;
    remoteDirectory?: string;
    username?: string;
    password?: string;
    emailNotification: boolean = true;
}


@Injectable()
export class MeasurementExportService {
    constructor(
        private http: HttpClient
    ) {

    }

    getZipExport(format: string, locationIds: Array<number>, startDate: moment.Moment, endDate: moment.Moment, includeInvalidated: boolean, includeReferences: boolean, includeAirWater: boolean) {
        const apiUrl = environment.api_base_url + '/export/locations/' + locationIds.join(",") + '/measurements/' + format;

        var params: any = {
            includeInvalidated: includeInvalidated,
            includeReferences: includeReferences,
            includeAirWater: includeAirWater,
        };

        if (endDate != null) {
            params.before = moment(endDate).toISOString();
        }

        if (startDate != null) {
            params.after = moment(startDate).toISOString();
        }

        return this.http.get(apiUrl, {responseType: 'blob', params: params});
    }

    getCsvZipExport(locationIds: Array<number>, startDate: moment.Moment, endDate: moment.Moment, includeInvalidated: boolean, includeReferences: boolean, includeAirWater: boolean) {
        return this.getZipExport('csv', locationIds, startDate, endDate, includeInvalidated, includeReferences, includeAirWater);
    }

    getXlsxZipExport(locationIds: Array<number>, startDate: moment.Moment, endDate: moment.Moment, includeInvalidated: boolean, includeReferences: boolean, includeAirWater: boolean) {
        return this.getZipExport('xlsx', locationIds, startDate, endDate, includeInvalidated, includeReferences, includeAirWater);
    }

    postExportZipJob(
        filename: string,
        locations: BlikLocation[],
        exportOptions: MeasurementExportOptions,
        uploadOptions?: UploadTargetOptions
    ): Observable<Job> {
        const locationIds = locations.map(loc => loc.id);
        const format = exportOptions.exportType;
        const apiUrl = environment.api_base_url + '/export/locations/' + locationIds.join(",") + '/measurements/' + format + '/' + filename + '.zip';
        
        const params = this.getExportRequestParams(exportOptions);

        return this.http.post<Job>(apiUrl, uploadOptions, {params: params});
    }
    
    getExportRequestParams(o: MeasurementExportOptions): any {
        var result: any = {
            includeInvalidated: o.includeInvalidated,
            onlyValidated: o.onlyValidated,
            includeWaterLevel: o.includeWaterLevel,
            includeAirWater: o.includeAirWater,
            includeFlow: o.includeFlow,
            includeMisc: o.includeMisc,
            includeReferences: o.includeReferences
        };
        if (o.limit) result.limit = o.limit;
        if (o.periodType === 'select') {
            if (o.after) result.after = o.after.unix();
            if (o.before) result.before = o.before.unix();
        }
        return result;
    }
}
