import { environment } from './../../environments/environment';

const getHost = () => {
  const protocol = window.location.protocol;
  const host = window.location.host;
  return `${protocol}//${host}`;
};

interface AuthConfigAuth0 {
  CLIENT_ID: string;
  CLIENT_DOMAIN: string;
  REDIRECT: string;
  AUDIENCE: string;
  SCOPE: string;
  BASE_URI: string;
}

export const AUTH_CONFIG: AuthConfigAuth0 = {
  CLIENT_ID: 'FRrjryHqqTB74XetRCOHywxXJLPa07D3',
  CLIENT_DOMAIN: 'blik.eu.auth0.com',
  REDIRECT: `${getHost()}${environment.auth0_callback_path}`,
  AUDIENCE: 'https://water.bliksensing.nl',
  SCOPE: 'openid profile email',
  BASE_URI: getHost()
};
