<ng-template #sentRequest>
    <p *ngIf="isDone()">Uw verzoek is verstuurd, één moment alstublieft.</p>

    <div *ngIf="success === true" class="panel panel-primary">
        <div class="panel-heading">
            <i class="fa fa-check"></i> Wachtwoord succesvol aangevraagd
        </div>
        <div class="panel-body">
            Er is een bericht verstuurd naar <strong>{{(auth.userProfile | async)?.email}}</strong>.
        </div>
    </div>

    <div *ngIf="success === false" class="panel panel-warning">
        <div class="panel-heading">
            <i class="fa fa-warning"></i> Wachtwoord aanvragen mislukt
        </div>
        <div class="panel-body">
            Er ging iets mis bij uw verzoek, probeer het later opnieuw.
        </div>
    </div>
</ng-template>

<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>Profiel: {{(user.me | async)?.name}}</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/']">Home</a>
      </li>
      <li class="breadcrumb-item active">
        <strong>Profiel</strong>
      </li>
    </ol>
  </div>
</div>

<div *ngIf="auth.userProfile | async as userProfile">

    <div class="wrapper wrapper-content animated fadeIn">
        <div class="row">
            <div class="col-lg-4">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5>Mijn gegevens</h5>
                    </div>

                    <div class="ibox-content">
                        <p>
                            Als u op onderstaande knop drukt, wordt er een bericht naar uw e-mailadres gestuurd waarmee u uw wachtwoord opnieuw kunt instellen.
                        </p>
                        <p>Uw e-mailadres dat bij ons geregistreerd staat is: <strong>{{userProfile.email}}</strong></p>
                        <button *ngIf="!sent; else sentRequest" class="btn btn-default" (click)="resetPassword()">
                          <i class="fa fa-key"></i> Vraag nieuw wachtwoord aan
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<user-settings>
</user-settings>
