<div class="row">
  <div id="waterleveldisplaychart" class="waterlevel-display"></div>
</div>
<div class="row noprint">
  <div class="col-xs-2" id="waterlevel-reference-line-controls noprint">
    <div *ngIf="this.showReferenceControls()">
      <label for="lineReference">Referentielijn (in meters NAP)</label>
      <input [(ngModel)]="lineReferenceAboveReference" type="number" step="0.01" id="lineReference" class="form-control" name="lineReference" #lineReference (change)="updateLineReference()"/>
    </div>
  </div>
  <div class="col-xs-10 text-right">
    <a type="button" class="btn btn-default" id="toggleReferenceLevelButton" (click)="toggleReferenceLevel()">{{referenceLevelButtonText}}</a>
  </div>
</div>
<div class="row" *ngIf="allowValidation && this.showValidationControls()">
  <div class="form-group col-md-2">
    <label for="validationActiveToggle">Validatie-modus aan</label>
    <input class="form-control ng-valid" id="validationActiveToggle" type="checkbox" (change)=toggleValidationMode()>
  </div>

  <div class="form-group col-md-2">
    <label for="validationComment">Validatie-opmerkingen</label>
    <input class="form-control ng-valid" id="validationComment" type="text" [(ngModel)]="validationComment" [disabled]=validationInputsDisabled()>
  </div>

  <div class="form-group col-md-2">
    <label for="validation-submit-valid-btn">Markeer metingen als</label>
    <button class="form-control btn-primary noprint" type="submit" (click)=markSelectionValid() id="validation-submit-valid-btn" [disabled]=validationInputsDisabled()>Valide</button>
  </div>

  <div class="form-group col-md-2">
    <label for="validation-submit-invalid-btn">Markeer metingen als</label>
    <button class="form-control btn-danger noprint" type="submit" (click)=markSelectionInvalid() id="validation-submit-invalid-btn" [disabled]=validationInputsDisabled()>Invalide</button>
  </div>

  <div class="form-group col-md-2">
    <label for="validation-submit-invalid-btn">Markeer metingen als</label>
    <button class="form-control btn-default noprint" type="submit" (click)=markSelectionNotValidated() id="validation-submit-notvalidated-btn" [disabled]=validationInputsDisabled()>Ongevalideerd</button>
  </div>
</div>
