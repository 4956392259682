export abstract class Defaults {
  public static TIME_WINDOW_DAYS: number = 21; // 3 weeks

  // https://momentjs.com/docs/#/displaying/format/
  public static FORMAT_MOMENT_DATE: string = 'DD MM YYYY';
  public static FORMAT_MOMENT_DATETIME: string = 'DD MM YYYY HH:mm';
  public static FORMAT_MOMENT_MONTHDAY: string = 'DD MM';
  public static FORMAT_MOMENT_MONTH: string = 'MM';

  // https://api.highcharts.com/highcharts/xAxis.dateTimeLabelFormats
  // http://www.php.net/manual/en/function.strftime.php
  public static FORMAT_HIGHCHARTS_DATE: string = '%d-%m-%Y';
  public static FORMAT_HIGHCHARTS_DATETIME: string = '%d-%m-%Y %H:%M';
  public static FORMAT_HIGHCHARTS_MONTHDAY: string = '%b %e';
  public static FORMAT_HIGHCHARTS_MONTHYEAR: string = '%b %y';
  public static FORMAT_HIGHCHARTS_SERIES_NO_DECIMAL: string = '<span style="color:{series.color}">\u25CF</span> {series.name}: <b>{point.y:,.0f}</b><br/>';
  public static FORMAT_HIGHCHARTS_SERIES_ONE_DECIMAL: string = '<span style="color:{series.color}">\u25CF</span> {series.name}: <b>{point.y:,.1f}</b><br/>';
  public static FORMAT_HIGHCHARTS_SERIES_TWO_DECIMALS: string = '<span style="color:{series.color}">\u25CF</span> {series.name}: <b>{point.y:,.2f}</b><br/>';
  public static FORMAT_HIGHCHARTS_VALUE: string = '<b>{value:,.2f}</b>';
}
