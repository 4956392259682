import { Injectable } from '@angular/core';
import { map, filter, startWith, pairwise } from 'rxjs/operators';
import { UserService, User } from './user.service';
import { combineLatest, Observable, Subject } from 'rxjs';


@Injectable({providedIn: 'root'})

export class LocalStorageService {
    private me: User | null = null;
    private keyValueChangeSubject: Subject<[string, string]> = new Subject();

    constructor(private userService: UserService) {
        userService.me.subscribe(newMe => {
            this.me = newMe;
        });
    }

    public getUserCookieName(me: User | null, identifier: string): string {
        if (me === null || me === undefined || !('id' in me)) {
            return "public_" + identifier;
        }

        return "user" + me.id + "_" + identifier;
    }

    public observeUserValue(key: string): Observable<string> {
        return combineLatest([this.userService.me, this.keyValueChangeSubject.pipe(startWith([null, null] as [string, string]))])
            .pipe(startWith([null, [null, null]] as [User | null, [string, string]]))
            .pipe(pairwise())
            .pipe(filter(([[oldUser, [_oldChangedKey, _oldValue]], [newUser, [newChangedKey, _newValue]]]) => {
                let cookieName = this.getUserCookieName(newUser, key);
                return (cookieName == newChangedKey) || (oldUser != newUser);
            }))
            .pipe(map(([[_oldUser, [_oldChangedKey, _oldValue]], [newUser, [_newChangedKey, _newValue]]]) => {
                return localStorage.getItem(this.getUserCookieName(newUser, key));
            }));
    }

    public saveUserValue(key: string, value: string) {
        const fullKey = this.getUserCookieName(this.me, key);
        localStorage.setItem(fullKey, value);
        this.keyValueChangeSubject.next([fullKey, value]);
    }
}
