
import {never as observableNever, timer as observableTimer,  Observable, Subject, Subscription, ReplaySubject } from 'rxjs';

import {take,  concatMap, switchMap, map, tap, takeWhile } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import * as moment from 'moment';
import { async } from 'rxjs/internal/scheduler/async';

interface JobCreatedForThisResult<Data> {
  jobCreatedForThisResult?: Data;
  jobID: number;
}

export interface AnalysisReport {
  fromTime: number;
  toTime: number;
  messagesReceived?: number;
  messagesMissed?: number;
  messagesRetransmitted?: number;
  messagesExtra?: number;
  batteryVMax?: number;
  batteryVMin?: number;
  spfMax?: number;
  rssiMin?: number;
  snrMin?: number;
  temperatureKMax?: number;
  temperatureKMin?: number;
  resets?: number;
  timeDriftMax?: number;
}

export interface DeploymentAnalysis {
  id: number;
  nodeID: number;
  locationID: number;
  distanceAboveGroundLevel: number;
  distanceAboveNAP: number;
  distanceAboveSeaLevel: number;
  fromTime: number;
  toTime?: number;
  report?: AnalysisReport;
  subReports?: AnalysisReport[];
  configs?: any[];
  events?: any[];
  batteryDeadTime?: number;
  lastMessageReceivedAt?: number;
  waterSensorAboveTopOfWell?: number;
  flowMeterCoverageM3?: number;
  flowMeterGreenIsIn?: number;
  flowMeterPulseAmountM3?: number;
  airPressureCalibratedOffsetPa?: number;
}

export interface DeploymentStatus {
  id: number;
  batteryDeadTime?: number;
  lastMessage?: number;
  messagesReceived: number;
  messagesMissed: number;
  messagesRetransmitted: number;
  messagesExtra: number;
  messagesMissedWhileOperational: number;
  BatteryVMax?: number;
  BatteryVMin?: number;
  messagesReceived_ratio: number;
  messagesReceivedWhileOperational_ratio: number;
  messagesRetransmitted_ratio: number;
  messagesGainDueToRetransmissions_ratio: number;
}

export interface Job {
  jobID: number;
  state: number;
  progress: number;
  queuePosition?: number;
}

export interface Cached {
  analysisRequested: boolean;
  analysis: ReplaySubject<DeploymentAnalysis>;
  status: ReplaySubject<DeploymentStatus>;
  progress: ReplaySubject<Job>;
  progressSubscription: Subscription;
  completed?: boolean;
  jobID?: number;
}

@Injectable({
  providedIn: 'root'
})
export class DeploymentService {

  constructor(private http:HttpClient) { }

  cache: { [id: number] : Cached} = {};

  getStatus(deploymentID, doAnalysis): Observable<DeploymentStatus> {
    this.getX(deploymentID, doAnalysis, "status");
    return this.cache[deploymentID].status.asObservable();
  }

  get(deploymentID, doAnalysis): Observable<DeploymentAnalysis> {
    this.getX(deploymentID, doAnalysis, "analysis");
    return this.cache[deploymentID].analysis.asObservable();
  }

  getAnalysis(deploymentID, doAnalysis): Observable<DeploymentAnalysis> {
    this.getX(deploymentID, doAnalysis, "analysis");
    return this.cache[deploymentID].analysis.asObservable();
  }

  getX(deploymentID, doAnalysis, what: String): void {
    var updateCache = false;

    if(!this.cache[deploymentID]) {
      updateCache = true;
    } else {
      if(doAnalysis && (!this.cache[deploymentID].completed)) {
        updateCache = true;
      }

      if(!this.cache[deploymentID].status && what == "status") {
        updateCache = true;
      }
      if(!this.cache[deploymentID].analysis && what == "analysis") {
        updateCache = true;
      }
    }

    if(updateCache) {
      this.updateCache(deploymentID, doAnalysis, [what]);
    }

  }

  private updateCache(deploymentID, doAnalysis, what: String[]): void {
    if(!this.cache[deploymentID]) {
      this.cache[deploymentID] =
        { analysis: null
        , status: null
        , progress: new ReplaySubject(1)
        , analysisRequested: false
        , progressSubscription: null
        , completed: false
        };
      }
    if(what.includes("analysis")) {
      const apiUrl = environment.api_base_url + '/deployments/' + deploymentID + '/analysis';
      this.cache[deploymentID].analysisRequested = this.cache[deploymentID].analysisRequested || doAnalysis;

      if(this.cache[deploymentID].analysis == null) {
        this.cache[deploymentID].analysis = new ReplaySubject(1, undefined, async);
      }

      this.http.get(apiUrl, {params: {"gatheronly": ""+!doAnalysis}}).pipe(
      take(1)).subscribe(
      (data: JobCreatedForThisResult<DeploymentAnalysis>|DeploymentAnalysis) => {
        if(data.hasOwnProperty("jobCreatedForThisResult")) {
          let data_ = <JobCreatedForThisResult<DeploymentAnalysis>>data;
          this.cache[data_.jobCreatedForThisResult.id].jobID = data_.jobID;
          this.addIncomingDeployment(data_.jobCreatedForThisResult);
        } else {
          let data_ = <DeploymentAnalysis>data;
          this.cache[data_.id].completed = true;
          this.addIncomingDeployment(data_);
        }
    },
      error => {
          console.log('Error retrieving data from API on URL ' + apiUrl);
      }
      );
    }
    if(what.includes("status")) {
      const apiUrlStatus = environment.api_base_url + '/deployments/' + deploymentID + '/status';

      if(this.cache[deploymentID].status == null) {
        this.cache[deploymentID].status = new ReplaySubject(1, undefined, async);
      }

      this.http.get(apiUrlStatus)
      .subscribe(
      (data: JobCreatedForThisResult<DeploymentStatus>|DeploymentStatus) => {
        if(data.hasOwnProperty("jobCreatedForThisResult")) {
          let data_ = <JobCreatedForThisResult<DeploymentStatus>>data;
          this.cache[data_.jobCreatedForThisResult.id].jobID = (<JobCreatedForThisResult<DeploymentStatus>>data).jobID;
          this.addIncomingDeploymentStatus(data_.jobCreatedForThisResult);
        } else {
          let data_ = <DeploymentStatus>data;
          this.addIncomingDeploymentStatus(data_);
        }
      },
      error => {
          console.log('Error retrieving data from API on URL ' + apiUrlStatus);
      }
      );
    }

    // remove && doAnalysis when the backend manages jobs
    if(!this.cache[deploymentID].progressSubscription && doAnalysis) {
      this.subscribeToProgress(deploymentID);
    }
  }

  private unsubscribeToProgress(deploymentID): void {
    if(this.cache[deploymentID].progressSubscription != null) {
      this.cache[deploymentID].progressSubscription.unsubscribe();
      this.cache[deploymentID].progressSubscription = null;
    }
  }

  /**
   * Subscribes to the progress of the obtaining the deployment analysis by polling every @c timeout_ms milliseconds.
   */
  private subscribeToProgress(deploymentID, timeout_ms = 2000): void {
    const apiUrlProgress = environment.api_base_url + '/jobs/';

    this.updateCache(deploymentID, false, []);

    if(this.cache[deploymentID].jobID) {
      if(deploymentID in this.cache && this.cache[deploymentID].progressSubscription != null) {
        this.cache[deploymentID].progressSubscription.unsubscribe();
      }
      this.cache[deploymentID].progressSubscription = observableTimer(0,timeout_ms).pipe(switchMap(_ => {
        return this.http.get(apiUrlProgress + this.cache[deploymentID].jobID)
      })).subscribe((result: any) => {
        if(result.state == "RUNNING") {
        } else if(result.state == "QUEUED") {
          // this doesn't work: needs an initial delay
          // this.unsubscribeToProgress(deploymentID);
          // this.subscribeToProgress(deploymentID, 10000);
        } else {
          if(result.state == "UNKNOWN_JOB_OR_DONE" || result.state == "DONE") {
            this.updateCache(deploymentID, false, ["analysis", "status"]);
          }
          this.cache[deploymentID].jobID = null;
          this.unsubscribeToProgress(deploymentID);
        }
        this.cache[deploymentID].progress.next(result);
      }, error => {
        console.log("ERROR RECEIVING progress for ", deploymentID)
        this.unsubscribeToProgress(deploymentID);
      })
    } else {
      this.unsubscribeToProgress(deploymentID);
    }
  }

  getProgress(deploymentID): Observable<Job> {
    if(deploymentID in this.cache && this.cache[deploymentID].jobID) {
      this.subscribeToProgress(deploymentID);
    }
    return this.cache[deploymentID].progress;
  }

  private addIncomingDeployment(d) {
    d = this.processDeploymentData(d);
    this.cache[d.id].analysis.next(d);
  }

  private addIncomingDeploymentStatus(status) {
    this.cache[status.id].status.next(status);
  }

  private processDeploymentData(d) {
      var from = d.fromTime;
      var to = d.toTime;
      d.toTime_readable = to ? moment(to).format('YYYY-MM-DD HH:mm') : "now";
      if(from) {
        d.fromTime_readable = moment(from).format('YYYY-MM-DD HH:mm');
        d.duration_readable = to ? moment(to).from(moment(from), true) : moment(from).toNow(true)
      }
      return d;
  }

  submit(deployments) {
      const apiUrl = environment.api_base_url + '/deployments/';
      this.http.put(apiUrl, deployments);
  }

}
