
import {combineLatest as observableCombineLatest,  Observable, Subject, Subscription } from 'rxjs';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChange } from '@angular/core';
import { DeploymentService, DeploymentStatus, DeploymentAnalysis } from "../services/deployment.service";
import * as moment from 'moment';

interface ShownDeployment extends DeploymentAnalysis {
  showInList: boolean;
}

@Component({
  selector: 'deployment-list',
  templateUrl: './deployment-list.component.html',
  styleUrls: ['./deployment-list.component.css']
})
export class DeploymentListComponent implements OnInit {

  @Input() deploymentIDs;

  @Output() deploymentIndexSet: EventEmitter<any> = new EventEmitter();

  deployments: ShownDeployment[];
  deploymentIndex = 0;
  deploymentsStatus: Observable<DeploymentStatus>[] = [];
  subscription: Subscription = null;
  subscriptionStatus: Subscription = null;
  hideTemporaryDeployments: boolean = true;

  columns= [ { header: "ID", "parameter": "id"}
           , { header: "From", "parameter": "fromTime_readable", inputtype: "datetime"}
           , { header: "To", "parameter": "toTime_readable", inputtype: "datetime"}
           , { header: "Duration", "parameter": "duration_readable"}
           , { header: "Above ground (mm)", "parameter": "distanceAboveGroundLevel"}
           , { header: "Above NAP (mm)", "parameter": "distanceAboveNAP"}
           , { header: "Above Sea (mm)", "parameter": "distanceAboveSeaLevel"}
           , { header: "Node ID", "parameter": "nodeID"}
           , { header: "Node Serial", "parameter": "node_serial"}
           ];

  constructor(private deploymentService: DeploymentService) { }

  ngOnInit() {

  }

  ngOnDestroy() {
    if(this.subscription) this.subscription.unsubscribe();
    if(this.subscriptionStatus) this.subscriptionStatus.unsubscribe();
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    // If the deployments is cleared, emit selecting 0
    if(changes.deploymentIDs) {
      this.download(changes.deploymentIDs.currentValue);
    }
  }

  onSelectDeployment(index) {
    this.deploymentIndex = index;
    this.deploymentIndexSet.emit(this.deploymentIDs[index]);
  }

  updateDeployments(deployments) {
    if(!deployments) return;
    this.deployments = deployments;
    this.deployments.forEach(d => {
      d.showInList = true;
      if(!d.id) return;
      if(d.showInList && this.hideTemporaryDeployments) {
        d.showInList = !d.toTime || (d.toTime - d.fromTime >= 60);
      }
    })
  }

  setHideTemporaryDeployments(hide: boolean) {
    this.hideTemporaryDeployments = hide;
    this.updateDeployments(this.deployments);
  }

  download(deploymentIDs: JSON[]) {
      this.deploymentIndex = 0;

      if(deploymentIDs.length == 0) {
        this.deploymentIndexSet.emit(0);
        this.subscription = null;
      } else {
        this.deploymentIndexSet.emit(this.deploymentIDs[this.deploymentIndex]);
        var nodeFetches: Observable<JSON>[] = this.deploymentIDs.map(id => this.deploymentService.getAnalysis(id, false));
        var deploymentsStatus$ = this.deploymentIDs.map(id => this.deploymentService.getStatus(id, false));
        this.subscription = observableCombineLatest(nodeFetches).subscribe((results: any) => {
          this.updateDeployments(results);
        });
        this.subscriptionStatus = observableCombineLatest(deploymentsStatus$).subscribe((results: any) => {
          this.deploymentsStatus = results;
        });
      }
  }

}
