import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import * as moment from 'moment';
import { Observable, of } from "rxjs";
import {map, take} from "rxjs/operators";
import { Position } from 'app/shared/position';

export interface KNMIStation {
  id: number;
  name: string;
  position: Position;
}

export interface KNMIStationAnnotated extends KNMIStation {
  distance: number
}

export interface Temperature {
  celsius: number;
  kelvin: number;
  milliKelvin: number;
}

export interface KNMIStationDataEntry {
  time: Date;
  rain?: number;
  airTemperature_K?: Temperature; // Yes this is called *_K but actually contains a Temperature object. Oops?
  airPressure_Pa?: number;
}

export type KNMIStationDropdownItem = KNMIStationAnnotated & { _desc: String };
export type KNMIStationDropdownPlaceholder = { id: 0, _desc: String };

export function isKNMIPlaceholder(station: KNMIStationDropdownItem | KNMIStationDropdownPlaceholder): station is KNMIStationDropdownPlaceholder {
  return !('position' in station);
}

const KNMI_NUM_ENTRIES = 3;

@Injectable({
  providedIn: 'root'
})
export class KNMIService {

  constructor(private http:HttpClient) { }

  getKNMIStations(): Observable<KNMIStation[]> {
      return this.http.get(environment.api_base_url + "/knmistations") as Observable<KNMIStation[]>;
  }

  getDistance(station: KNMIStation, position: Position) {
    if (!position) {
      return NaN;
    } else {
      return Position.distanceToInMeter(position, station.position);
    }
  }

  getKNMIStationsSortedByDistanceTo(positions: Position[]): Observable<KNMIStationAnnotated[]> {
    const stations: Observable<KNMIStationAnnotated[]> = this.getKNMIStations().pipe(take(1)).pipe(map(
      data => {
        const results = [];

        for (const position of positions) {
          const annotated = data
            .map(s => Object.assign({}, s, {
              distance: Position.distanceToInMeter(position, s.position),
            }))
            .sort((a, b) => a.distance - b.distance)
            .slice(0, KNMI_NUM_ENTRIES);

          for (const item of annotated) {
            if (!results.some(r => r.id === item.id)) {
              results.push(item);
            }
          }
        }

        return results;
      }));
    return stations;
  }

  getKNMIDataAtStation(stationID: Number, start: moment.Moment | null, end: moment.Moment | null): Observable<KNMIStationDataEntry[]> {
    if (start === undefined || end === undefined) {
      return of([]);
    }

    const params: Record<string, string> = {};

    if (start) {
      params.after = start.toISOString();
    }

    if (end) {
      params.before = end.toISOString();
    }

    return this.http.get(environment.api_base_url + '/knmistations/' + stationID + "/data", {
      params,
    }) as Observable<KNMIStationDataEntry[]>;
  }
}
