<h1>Configuratie</h1>
<div class="row">
    <div class="col-lg-4" id="measurement-interval-manager">
        <ng-container *ngIf="haveActiveDeployment; else noActiveDeployment">
            <form (ngSubmit)="onSubmit()" #measurementIntervalManager="ngForm">
                <div class="form-group">
                    <label for="measurementInterval">Stel nieuw meetinterval in (minuten):</label>
                    <input type="number" id="measurementInterval" required [(ngModel)]=measurementInterval class="form-control" name="measurementInterval"
                        #measurementIntervalModel="ngModel" min="1" #spy/>
                    <div [hidden]="measurementIntervalModel.valid || measurementIntervalModel.pristine" class="alert alert-danger">
                        Interval is vereist
                    </div>
                    <div [hidden]="!measurementIntervalOutOfBounds()" class="alert alert-danger">
                        Interval is kleiner dan 1 minuut of groter dan 1440 minuten (24 uur)
                    </div>
                </div>
                <button type="submit" class="btn btn-primary" [disabled]="!measurementIntervalModel.valid || measurementIntervalOutOfBounds()">Bevestigen</button>
            </form>
        </ng-container>
    </div>
</div>

<ng-template #noActiveDeployment>
    <div class="alert alert-info">
        <h3>Geen configuratie mogelijk.</h3>
        Op deze locatie is momenteel geen node actief.
    </div>
</ng-template>
