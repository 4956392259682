<ng-template #defaultLogo>
  <a style="margin: 0; padding: 0; background: none;" href="https://www.blik-sensing.nl/">
    <img alt="logo" class="company-logo" src="assets/images/logoklein.png" />
  </a>
</ng-template>

<nav class="navbar-default navbar-static-side" role="navigation">
  <div class="sidebar-collapse">
    <ul class="nav metismenu">
      <li class="nav-header">
        <div class="logo-element">
          <a style="margin: 0; padding: 0; background: none;" href="https://www.blik-sensing.nl/">
            <img alt="logo" class="company-logo-letter" src="assets/images/logoletter.png" />
          </a>
        </div>
        <div class="profile-element" dropdown>
            <div class="company-logo">
              <ng-container *ngIf="(companyLogo | async) as logo; else defaultLogo">
                <img alt="logo" class="company-logo" src="assets/images/{{logo}}" />
                <a style="margin: 0; padding: 0; background: none;" href="https://www.blik-sensing.nl/">
                  <img alt="logo" class="powered-by-logo" src="assets/images/poweredby.png" />
                </a>
              </ng-container>
            </div>
            <span class="text-muted text-xs block" *ngIf="!((companyLogo | async) && !auth.authenticated)">
              <ng-container *ngIf="auth.authenticated">
                <strong class="font-bold" *ngIf="impersonator.isImpersonating">
                  Geïmpersoneerd:<br />
                </strong>
                <strong class="font-bold" *ngIf="!(user.haveUser | async)">
                  {{(auth.userProfile | async)?.email}}
                </strong>
                <strong class="font-bold" *ngIf="(user.haveUser | async) && !impersonator.isImpersonating">
                  <a [routerLink]="['/profiel']"><i class="fa fa-user"></i> {{(user.me | async)?.name}}</a>
                  
                  <div class="settingsicon"><a [routerLink]="['/profiel']"><i class="fa fa-cog"></i></a></div>
                </strong>
                <strong class="font-bold" *ngIf="(user.haveUser | async) && impersonator.isImpersonating">
                  <i class="fa fa-user"></i> {{(user.me | async)?.name}}
                </strong>
              </ng-container>

              <ng-container *ngIf="!auth.authenticated">
                <strong class="font-bold" *ngIf="user.haveUser | async">
                  {{(user.me | async)?.name}}
                </strong>
                <strong class="font-bold" *ngIf="!(user.haveUser | async)">
                  Niet ingelogd
                </strong>
              </ng-container>
            </span>
        </div>
      </li>
    </ul>
    <ul class="nav metismenu" id="side-menu">
      <ng-container *ngIf="(user.haveUser | async)">
        <li [ngClass]="{active: activeRoute('kaart')}">
          <a [routerLink]="['/kaart']"><i class="fa fa-map"></i> <span class="nav-label">Kaart</span></a>
        </li>
        <li [ngClass]="{active: activeRoute('overzicht')}" *ngIf="locationOverviewPermission | async">
          <a [routerLink]="['/overzicht']"><i class="fa fa-th-large"></i> <span class="nav-label">Overzicht</span></a>
        </li>
        <li [ngClass]="{active: activeRoute('vergelijk')}" *ngIf="compareLocationsPermission | async">
          <a [routerLink]="['/vergelijk']">
            <i class="fa fa-area-chart"></i>
            <span class="nav-label">Vergelijken</span>
            <span class="badge pull-right" *ngIf="comparedLocations && comparedLocations.length > 0">{{comparedLocations.length}}</span>
          </a>
        </li>
      </ng-container>
      <li *ngIf="user.canManageUsers | async" [ngClass]="{active: activeRoute('gebruikersbeheer')}">
        <a [routerLink]="['/gebruikersbeheer']"><i class="fa fa-users"></i> <span class="nav-label">Gebruikersbeheer</span></a>
      </li>
      <li *ngIf="auth.authenticated && !impersonator.isImpersonating" [ngClass]="{active: activeRoute('profiel')}">
        <a [routerLink]="['/profiel']"><i class="fa fa-user"></i> <span class="nav-label">Mijn Profiel</span></a>
      </li>
      <li *ngIf="auth.authenticated && impersonator.isImpersonating">
        <a (click)="impersonator.stopImpersonation()"><i class="fa fa-sign-out"></i> <span class="nav-label">Stop impersonatie</span></a>
      </li>
      <li *ngIf="auth.authenticated && !impersonator.isImpersonating">
        <a (click)="this.logOut()"><i class="fa fa-sign-out"></i> <span class="nav-label">Log uit</span></a>
      </li>
      <li *ngIf="!auth.authenticated">
        <a (click)="auth.login()"><i class="fa fa-sign-in"></i> <span class="nav-label">Log in</span></a>
      </li>
    </ul>
  </div>
</nav>

