<div class="user-image">
    <div class="noprint user-image__buttons">
        <div *ngIf=pendingUpload class="user-image__pending label label-info">
            <i class="glyphicon glyphicon-time"></i>
        </div>
        <button *ngIf="editable" [ngClass]="{'btn-danger': pendingDelete}" class="btn" (click)="_deleteClick()"><i
                class="glyphicon glyphicon-trash"></i></button>
    </div>

    <ng-container *ngIf=url>
        <img class="user-image__img" [src]=url (click)="_imageClick()" />
    </ng-container>

    <div class="user-image__caption" (click)="_imageClick()">{{_image.description.caption || _image.description.filename}}</div>
</div>
